.toolbar {
    display: flex;
    align-items: center;
    margin-bottom: $double-spacing;
    padding: 0;
    border-top: 1px solid $silver-lt;
    border-bottom: 1px solid $silver-lt;

    @include media-query(min, $lap-start) {
        margin-left: 0;
        margin-right: 0;
        padding-bottom: $base-spacing;
        border-top: none;
    }

    .toolbar-filter-trigger {
        width: 50%;
        position: relative;
        padding-right: $double-spacing;
        padding-left: 0;
        color: $charcoal;
        background: transparent;
        background: url('#{$svg-path}icon_right.svg') no-repeat right
            ($base-spacing + 5px) center $white;
        background-size: $half-spacing;
        font-weight: 700;
        border-color: $white;
        border-right-color: $silver-lt;
        text-align: left;

        @include hover {
            background-color: unset;
        }

        @include media-query(min, $lap-start) {
            display: none;
        }
    }

    .pager {
    }

    .modes {
        display: none;
        width: 100%;
        max-width: 80px;
        border: 1px solid $silver-lt;
        height: $button-height;

        @include media-query(min, $lap-start) {
            display: flex;
            align-items: center;
            margin-right: $base-spacing;
        }
    }

    .modes-label {
        display: none;
    }

    .modes-mode {
        position: relative;
        display: block;
        width: 50%;
        height: 100%;

        &.active {
            opacity: 0.2;
            cursor: not-allowed;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    a.modes-mode {
        @include hover {
            background-color: $mercury;
        }
    }

    .mode-grid {
        order: 2;

        &:before {
            background: url('../svg/icon_grid.svg') center no-repeat;
            background-size: 55%;
        }

        span {
            @include visuallyhidden();
        }
    }

    .mode-list {
        order: 1;

        &:after {
            content: '';
            position: absolute;
            background-color: $silver-lt;
            top: 11px;
            right: 0;
            width: 1px;
            height: 22px;
        }

        &:before {
            background: url('../svg/icon_list.svg') center no-repeat;
            background-size: 55%;
        }

        span {
            @include visuallyhidden();
        }
    }

    .toolbar-amount {
        display: none;
    }

    .toolbar-number {
    }

    .pages {
        display: none;
        grid-column: span 2;
        justify-content: center;
        order: 1;

        @include media-query(min, $desk-start) {
            display: flex;
            margin-left: auto;
        }
    }

    .pages-label {
        display: none;
    }

    .pages-items {
        list-style: none;
        display: flex;
        align-items: center;
        margin-bottom: 0;

        .item {
            padding: $half-spacing;

            .page {
                text-decoration: none;
                color: $grey;
                font-weight: 300;
            }

            .label {
                display: none;
            }

            .action {
                display: block;
            }
        }

        .current {
            border: 1px solid $silver-lt;
            padding: $half-spacing $base-spacing;

            .page {
                font-weight: 700;
            }

            .label {
                display: none;
            }
        }

        .pages-item-next {
            .next {
                display: block;
                content: '';
                background: url('../svg/icon_right-large.svg') center no-repeat;
                width: 13px;
                height: 20px;
                background-size: 70%;

                span {
                    @include visuallyhidden();
                }
            }
        }

        .pages-item-previous {
            .previous {
                display: block;
                content: '';
                background: url('../svg/icon_right-large.svg') center no-repeat;
                transform: rotate(180deg);
                width: 13px;
                height: 20px;
                background-size: 70%;
                left: 0;

                span {
                    @include visuallyhidden();
                }
            }
        }
    }

    .items {
    }

    .limiter {
        display: none;
        margin-bottom: 0;
        justify-content: flex-start;

        @include media-query(min, $lap-start) {
            display: flex;
            margin-right: $base-spacing;
        }

        .label {
            display: none;
        }

        .control {
        }

        .limiter-options {
            @include font-size(14px);
            font-weight: 700;
        }
    }

    .limiter-label,
    .limiter-text {
        display: none;
    }

    .toolbar-sorter {
        width: 50%;
        @include media-query(min, $desk-start) {
            width: unset;
        }
    }

    .sorter-label {
        display: none;
    }

    .sorter-options {
        width: 100%;
        @include font-size(14px);
        font-weight: 700;
        color: $charcoal;
        border-color: $white;
        cursor: pointer;

        @include media-query(max, $lap-start) {
            background-position: right 0 center;
        }

        @include media-query(min, $lap-start) {
            border-color: $silver-lt;
        }
    }

    .sorter-action {
        display: none;
    }
}

//Toolbar after product list

.products-list ~ .toolbar,
.products-grid ~ .toolbar {
    @include media-query(max, $desk-start) {
        border-top: 1px solid $silver-lt;
        padding-top: $base-spacing;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: $base-spacing;
        margin-left: 0;
        margin-right: 0;
        padding-bottom: $base-spacing;

        .modes {
            display: flex;
            justify-self: end;
        }

        .pages {
            display: flex;
        }

        .limiter {
            display: flex;
        }

        .toolbar-sorter {
            display: none;
        }

        .toolbar-filter-trigger {
            display: none;
        }
    }
}

.order-products-toolbar {
    padding: $base-spacing 0;

    .limiter {
        display: block;
    }
}

.cart-products-toolbar {
    padding-bottom: $base-spacing;
    @include media-query(max, $lap-start) {
        padding-top: $base-spacing;
    }
    .pages {
        display: block;
    }
}
