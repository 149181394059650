.membership-title {
    display: flex;
    align-items: end;
    justify-content: center;
    .proclub-logo,
    h2 {
        display: inline-block;
        vertical-align: sub;
    }
    .proclub-logo {
        @include media-query(min, $lap-start) {
            padding-right: 9px;
            width: 169px
        }
        @include media-query(max, $lap-start) {
            width: 121px;
            padding: 0;
        }
    }
    h2 {
        font-weight: 400;
        margin: 0;
        padding: 0;
        @include media-query(min, $lap-start) {
            font-size: 30px;
            line-height: 38px;
        }
        @include media-query(max, $lap-start) {
            font-size: 22px;
            line-height: 28px;
            position: relative;
        }
    }
}
.proclub-title {
    font-weight: 400;
    @include media-query(min, $lap-start) {
        font-size: 20px;
        padding-top: 40px;
        margin: 0 0 30px 0;
    }
    @include media-query(max, $lap-start) {
        font-size: 18px;
        line-height: 26px;
        padding-top: 30px;
        margin: 0 0 20px 0;
    }
}
.membership-section {
    border-radius: 8px;
    border: 2px solid $gold;
    padding: 20px;
}
.savings-breakdown {
    display: grid;
    background: $light-gold;
    position: relative;
    .image {
        position: absolute;
        right: 10px;
        top: 0;
    }
    .savings-breakdown-content {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}
.overall-savings {
    position: relative;
    z-index: 1;
    .title {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: black;
        padding-bottom: 4px;
    }
    .savings {
        display: block;
        color: $gold;
        font-size: 48.889px;
        font-weight: 600;
        line-height: 63.556px;
    }
}
.benefit-content {
    padding-left: 20px;
}
.proclub-summary {
    @include media-query(min, $lap-start) {
        display: grid;
        grid-gap: 0 40px;
        grid-template-columns: 1fr 1fr;
        .proclub-title {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }
    @include media-query(max, $lap-start) {
        .proclub-title {
            padding-top: 20px;
        }
    }
}
.proclub-benefits {
    .membership-section {
        display: grid;
        @include media-query(min, $lap-start) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px 40px;
        }
        @include media-query(max, $lap-start) {
            grid-gap: 20px 0;
        }
    }
    .benefit {
        display: grid;
        grid-template-columns: 95px 1fr;
        align-items: center;
    }
}
.annual-cost {
    @include media-query(max, $lap-start) {
        margin-bottom: 20px;
    }
    .proclub-logo,
    span {
        display: inline-block;
        vertical-align: bottom;
    }
    .proclub-logo {
        width: 101px;
    }
    > span {
        color: black;
    }
    span {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
    }
    h4 {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 5px;
    }
}
.annual-cost-summary {
    border-top: 2px solid $silver-lt;
    border-bottom: 2px solid $silver-lt;
    margin: 15px 0 20px 0;
    padding-bottom: 20px;
}
span.membership-status {
    background: $primary;
    border-radius: 8px;
    color: #000;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    padding: 3px 12px;
    margin-left: 5px;
}
.renewal-text span {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    a {
        color: $blue;
        font-weight: 600;
    }
}
.savings-breakdown-content {
    padding-top: 20px;
    border-top: 1px solid $silver-lt;
    > span {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 10px;
        color: $secondary;
    }
    .points {
        line-height: 22px;
        &:first-of-type {
            margin: 10px 0 2px 0;
        }
    }
}
.savings-title,
.savings-value {
    font-size: 16px;
    line-height: 22px;
}
.savings-title {
    color: $secondary;
    font-weight: 400;
}
.savings-value {
    color: $gold;
    font-weight: 600;
}
.benefit-content {
    h4,
    .points {
        display: inline-block;
    }
    h4 {
        color: $gold;
        font-weight: 600;
        padding: 0;
        margin: 0;
        @include media-query(min, $lap-start) {
            font-size: 32px;
            line-height: 40px;
        }
        @include media-query(max, $lap-start) {
            font-size: 28px;
            line-height: 34px;
        }
    }
    h3 {
        font-weight: 600;
        color: $secondary;
        margin: 0;
        padding-top: 0;
        @include media-query(min, $lap-start) {
            font-size: 22px;
            line-height: 30px;
        }
        @include media-query(max, $lap-start) {
            font-size: 20px;
            line-height: 28px;
        }
    }
    span {
        font-weight: 300;
        line-height: 22px;
        color: $secondary;
        @include media-query(min, $lap-start) {
            font-size: 16px;
            line-height: 22px;
        }
        @include media-query(max, $lap-start) {
            font-size: 14px;
            line-height: 20px;
        }
    }
    .price {
        font-weight: 600;
    }
}
