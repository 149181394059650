.block.related {
    background: $mercury;

    .block-title {
        max-width: $page-width;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: $base-and-half-spacing;
        padding-left: $base-spacing;
        padding-right: $base-spacing;
        padding-top: $base-and-half-spacing;
        text-align: center;

        strong {
            @include font-size(18px);
            font-weight: 700;
            color: $charcoal;
        }
    }

    .block-content {
        max-width: $page-width;
        margin: 0 auto;
        padding: 0 $base-spacing;
        margin-bottom: $double-spacing;
    }

    .block-actions {
        margin-bottom: $base-spacing;
    }

    .product-items {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .product-item {
        background: $white;
        display: none;
        &:nth-child(-n + 2) {
            display: block;
        }
        @include media-query(min, 880px) {
            &:nth-child(-n + 3) {
                display: block;
            }
        }
        @include media-query(min, $wide-start) {
            &:nth-child(-n + 4) {
                display: block;
            }
        }
    }

    .related-available {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $base-spacing;
        @include media-query(min, $lap-start) {
            padding: $base-and-half-spacing $double-spacing;
        }
        .product-item-details {
        }

        .product-item-name {
            padding-bottom: 0;
        }

        .product-item-link {
            @include font-size(15px);
            height: 40px;
        }

        .field.choice.related {
        }

        .checkbox.related {
        }

        .label {
        }

        .product-item-actions {
            display: none;
        }
    }
}
