.opc-summary-wrapper {
    .step-title {
        &:before {
            display: none;
            background: url("#{$image-path}icon_tick_black.svg") no-repeat center $primary;
            background-size: 48%;
        }
    }

    .place-order {
        width: 100%;
        margin-bottom: $base-spacing;

        button {
            width: 100%;
        }
    }
    .place-order-edit {
        display: inline-block;
        background: none;
        padding: 0;
        border: none;
        width: auto!important;
        text-decoration: underline;
    }
}

.opc-block-summary {
    display: flex;
    flex-direction: column;
    background-color: $smoke;
    padding: $base-spacing;
    margin-bottom: $base-spacing;
    margin-top: $base-spacing;

    .items-in-cart {
        order: -1;
    }

    .minicart-items {
        margin: 0;
        list-style: none;
        display: block!important;
    }

    .product-item {
        margin-bottom: $half-spacing;
        padding-bottom: $half-spacing;
        border-bottom: 1px solid $silver-lt;

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }

    }

    .product-image-container {
        display: none;
    }

    .product-item-details {
        .product-item-lead-time {
            color: $red;
        }
    }
    
    .product-item-inner {
        @include font-size(14px);

        .product-item-name-block {
            flex-grow: 1;
            flex-shrink: 1;
            padding-right: $half-spacing;
        }

        .product-item-name {
            font-weight: 100;
        }

        .details-qty {
            padding-top: ($half-spacing/2);
            display: flex;

            .label {
                @include font-size($micro-font-size);
                margin-right: $half-spacing;
            }
        }

        .subtotal {
            font-weight: 700;
            color: $charcoal;
        }
    }

    .product.options {
        display: block;
        @include font-size($micro-font-size);
        font-weight: 700;
        color: $charcoal;
        margin-bottom: $half-spacing;

        .toggle {
            display: block;
            width: 100%;
            cursor: pointer;

            &:after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                background: url('#{$image-path}chevron-down.svg') no-repeat
                    center;
                background-size: contain;
                margin-left: $half-spacing;
            }
        }

        .content {
            .subtitle {
                display: none;
            }
            .item-options {
                .label {
                    display: inline-block;
                    @include font-size($micro-font-size);
                    &:after {
                        content: ':';
                    }
                }
                .values {
                    display: inline-block;
                    font-weight: 100;
                    margin-right: $half-spacing;
                    @include font-size($micro-font-size);
                }
            }
        }
    }

    .item__error {
        @include font-size($small-font-size);
        color: #df280a;
        font-weight: 700;
    }

    .product-item-lead-time {

    }

    .table-totals {
        margin: 0;
        border-top: 1px solid $silver-lt;
        @include font-size(14px);

        th,
        td {
            padding-top: 0;
            padding-bottom: $half-spacing;
        }

        tr {
            &:first-child {
                th,
                td {
                    padding-top: $half-spacing;
                }
            }
            &:last-child {
                th,
                td {
                    padding-bottom: 0;
                }
            }
        }

        .label {
            font-weight: 700;
            margin: 0;
        }
    }

    .message {
        margin: $half-spacing 0;
    }
}

.shipping-information {
    margin-bottom: $base-spacing;

    .ship-to {
        margin-bottom: $base-spacing;
    }

    .shipping-information-title {
        display: flex;
        font-weight: 700;
        color: $charcoal;

        .action-edit {
            padding: 0;
            background: none;
            border: none;
            margin-left: auto;
            font-weight: 100;
            color: $grey;
            display: none;

            @include hover {
                text-decoration: underline;
            }
        }
    }

    .shipping-information-content {
        @include font-size(14px);
    }
}
