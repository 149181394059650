/*================================================================================
GROUPING
================================================================================*/

/*
 * Common styles aka THE LEAGUE OF EXTRAORDINARY TAGS
 */
hgroup,
blockquote,
address,
ul,
ol,
dl,
table,
fieldset,
figure,
figcaption,
details,
pre {
    margin-bottom: $half-spacing;
}
