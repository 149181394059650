.category-nav {
    @include media-query(min, $lap-start) {
        grid-row-start: 1;
    }
}

.category-nav__header {
    @include font-size(15px);
    font-weight: 700;
    padding-bottom: $base-spacing;
    position: relative;
    color: $charcoal;
    border-bottom: 1px solid $silver-lt;
    @include hover {
        outline: none;
        cursor: pointer;
    }
    @include media-query(max, $lap-start) {
        @include toggle(($base-spacing + 5px));
    }

    @include media-query(min, $lap-start) {
        padding: ($base-spacing - 5px) (0);
    }
}

.category-nav__list {
    list-style: none;
    padding-bottom: $base-spacing;
    margin-bottom: $double-spacing;
    border-bottom: 1px solid $silver-lt;
}

.category-nav__link {
    @include font-size(14px);
    text-decoration: none;
    color: $charcoal;
    @include hover {
        text-decoration: underline;
        color: $charcoal;
    }
}

.category-tiles__link {
    display: block;
    text-decoration: none;
    text-align: center;
    @include font-size(14px);
    font-weight: 700;
    color: $charcoal;
    padding: 5px 0;

    @include hover {
        text-decoration: underline;
        color: $charcoal;
    }
}

.category-tiles__image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    background-color: $lightgrey;
}

.category-tiles {
    display: flex;
    flex-wrap: wrap;
}

.category-tiles__item {
    width: calc(50% - #{$half-spacing});
    margin-right: $base-spacing;
    margin-bottom: $base-spacing;

    @include media-query(max, $lap-start) {
        &:nth-child(2n + 2) {
            margin-right: 0;
        }
    }

    @include media-query-bracket($lap-start, $desk-start) {
        width: calc(33.333% - ((#{$base-spacing} * 2) / 3));
        margin-right: $base-spacing;

        &:nth-child(3n + 3) {
            margin-right: 0;
        }
    }

    @include media-query(min, $desk-start) {
        width: calc(25% - ((#{$double-spacing} * 3) / 4));
        margin-right: $double-spacing;

        &:nth-child(4n + 4) {
            margin-right: 0;
        }
    }
}

.category-sidebar {
    .category-nav__list {
        display: none;

        @include media-query(min, $lap-start) {
            display: block;
        }
    }

    .category-free-catalog {
        display: none;
        width: 100%;
        @include media-query(min, $lap-start) {
            display: block;
        }
    }
    &.active {
        .category-nav__list.active {
            display: block;
        }

        .category-free-catalog {
            display: block;
            margin-bottom: $base-spacing;
        }
    }
}

.category-landing-tile {
    position: relative;

    h1.with-banner {
        position: absolute;
        top: 0;
        left: 0;
    }

    .banner {
        position: relative;
        width: 100%;
        z-index: 0;
        margin-bottom: $double-spacing;
    }
}
