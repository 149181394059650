.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus {
    outline: none;
}

.flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
    position: absolute;
    color: #333;
    border-radius: 100%;
    border: 1px solid $silver-lt;
}

.flickity-button:hover {
    background: transparent;
    cursor: pointer;
}

.flickity-button:focus {
    outline: none;
}

.flickity-button:active {
}

.flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    pointer-events: none;
}

.flickity-button-icon {
    display: none;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
    top: 50%;
    display: block;
    width: $carousel-button-size;
    height: $carousel-button-size;
    padding: 0;
    @include media-query (max, $lap-start) {
        margin-top: -$carousel-button-size;
    }
}

.flickity-prev-next-button.previous {
    margin-right: -10px;
    transform: translate(-50%, -50%);
    background: url('#{$image-path}chevron-left.svg') no-repeat center $white;
    background-size: 10px;
    @include media-query (min, $lap-start) {
        left: 0;
    }
    @include media-query (max, $lap-start) {
        right: 100%;
    }
}

.flickity-prev-next-button.next {
    margin-left: -10px;
    transform: translate(50%, -50%);
    background: url('#{$image-path}chevron-right.svg') no-repeat center $white;
    background-size: 10px;
    @include media-query (min, $lap-start) {
        right: 0;
    }
    @include media-query (max, $lap-start) {
        left: 100%;
    }
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -$base-spacing;
    left: 0;
    right: 0;
    transform: translateY(100%);
    margin: 0;

    @include media-query (min, $lap-start) {
        bottom: -($base-spacing + 5px);
        transform: translateY(100%);
    }
}

.flickity-rtl .flickity-page-dots {
    direction: rtl;
}

.flickity-page-dots .dot {
    display: block;
    height: $carousel-dot-size;
    width: $carousel-dot-size;
    margin: 0 5px;
    background: $silver-lt;
    border-radius: 100%;
    cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
    background-color: $primary;
}
