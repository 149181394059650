//Account sidebar nav
.account-nav {
    display: block;

    @include media-query(min, $desk-start) {
        padding-top: $base-spacing * 2;
    }

    .account-nav-title {
        display: flex;
        align-items: center;
        margin-left: -$base-spacing;
        margin-right: -$base-spacing;
        border-bottom: 1px solid $silver-lt;
        position: relative;
        cursor: pointer;

        strong {
            display: block;
            padding: $half-spacing $base-spacing;
        }

        @include media-query(min, $desk-start) {
            display: none;
        }

        &:after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            background: url('#{$image-path}chevron-down.svg') no-repeat center;
            background-size: contain;
            margin-left: auto;
            margin-right: $base-spacing;
            transition: all ease 0.3s;
        }

        &.active {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    .account-nav-content {
        display: none;
        padding: $half-spacing 0;
        border-bottom: 1px solid $silver-lt;

        &.active {
            display: block;
        }

        @include media-query(min, $desk-start) {
            display: block;
            border-bottom: none;
            padding: 0;
        }

        //menu list
        .nav.items {
            list-style: none;
            margin: 0;
        }

        //Current nav title
        .nav.item.current,
        .nav.item.current strong {
            background-color: $primary;
        }

        //Nav link
        .nav.item a,
        .nav.item strong {
            color: $charcoal;
            text-decoration: none;
            display: block;
            width: 100%;
            padding: $half-spacing;
            @include font-size(14px);
        }
    }
}

//account sidebar
.account {
    .sidebar.sidebar-main {
        @include media-query(min, $desk-start) {
            border-right: solid 1px $silver-lt;
        }
        @include media-query(max, $desk-start) {
            width: 100%;
        }
    }
}
