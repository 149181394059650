.checkout-agreement {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: $base-spacing;
    @include font-size(14px);

    &.required {
        input[type='checkbox'] {
            border-radius: 50%;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    label {
        flex-grow: 1;
        flex-shrink: 1;
        margin-bottom: 0;
    }

    div.mage-error {
        width: 100%;
    }
}
