/*================================================================================
SECTIONING
================================================================================*/

.page-main {
}

.container {
    @include container;
}

hr {
    clear: both;
    margin-bottom: $base-spacing;
    border: none;
    border-bottom: 1px solid $silver-lt;
    padding-bottom: $half-spacing;
    height: 1px;
}
