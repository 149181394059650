//Free Gift icon
.ampromo-add-message {
    display: flex;
    align-items: center;
    justify-content: center;
    .ampromo-add-icon {
        margin-right: 0.5rem;
        width: 32px;
        height: 32px;
        background: url("#{$image-path}icon_freegift.jpg") no-repeat center;
    }
    [data-role="ampromo-popup-show"] {
        margin-left: 0.5rem;
    }
}
