.button, button {
    color: $black;
}

.custom-swatches__button {
    color: $black;

    @include hover() {
        color: $black;
    }
}
