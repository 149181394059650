#proclub {
    @include media-query(min, $desk-start) {
        order: -1;
    }
    @include media-query(max, $desk-start) {
        order: 1
    }
    img {
        display: inline-block;
    }
    .step-title img {
        margin-top: -7px; // alignment for crown symbol
        margin-right: 7px;
        width: 91px;
    }
    .control {
        display: flex;
    }
    @include media-query(max, $desk-start) {
        &.non-member:not(.complete) .step-title:before,
        &.member ~ .checkout-shipping-method:not(.complete) .step-title:before {
            content: '2'
        }
        &.non-member ~ .checkout-shipping-method:not(.complete) .step-title:before,
          &.member ~ .checkout-payment-method:not(.complete) .payment-step-title:before {
              content: '3'
          }
    }
    @include media-query(min, $desk-start) {
        &.non-member .step-title:before,
        &.member ~ .checkout-shipping-method .step-title:before {
            content: '2'
        }
        &.non-member ~ .checkout-shipping-method .step-title:before,
        &.member ~ .checkout-payment-method .payment-step-title:before {
            content: '3'
        }
    }
    &.non-member ~ .checkout-payment-method:not(.complete) .payment-step-title:before {
        content: '4'
    }
    .actions-toolbar {
        margin-top: 20px
    }
    &.member {
        display: none;
    }
}
@include media-query(max, $lap-start) {
    .checkout-shipping-method:not(.visible) .proclub-cms {
        display: none;
    }
}
.proclub-step-content,
.proclub-cms,
.proclub-cms + .step-content .table-checkout-shipping-method {
    font-size: 12px;
    background: $light-gold;
    border: 1px solid $gold;
}
.proclub-step-cms,
.proclub-cms {
    p:first-of-type {
        margin-bottom: 5px;
    }
    p:empty {
        display: none;
    }
    .trustpilot-widget {
        width: 200px;
        margin-left: -4px
    }
}
.proclub-step-content {
    border-radius: 8px;
    padding: 15px;
    margin-top: 20px;
    .admin__field-label {
        display: inline-block;
        vertical-align: top;
    }
    .actions-toolbar p {
        margin-bottom: 5px;
    }
}
.proclub-step-cms + .fieldset,
.proclub-cms + .step-content .table-checkout-shipping-method tbody {
    padding-top: 15px;
    border-top: 1px solid $border-color
}
.proclub-step-cms + .fieldset {
    margin-top: 10px;
}
.checkout-shipping-method .proclub-cms {
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    padding: 15px 15px 10px 15px;
    margin-top: 15px;
    img {
        display: inline;
        width: 5em;
        margin-bottom: -3px;
        vertical-align: sub;
    }
    + .step-content {
        .table-checkout-shipping-method {
            border-radius: 0 0 8px 8px;
            padding: 0 15px 15px;
            border-top: none;
        }
    }
}
.checkout-index-index .proclub-cms + #checkout-step-shipping_method.step-content {
    padding-top: 0;
}
.checkout-payment-method {
    .membership-heading,
    .worth {
        font-size: 12px;
    }
    .worth {
        font-weight: $font-weight__light;
    }
}
.yotpo-point-balance-text {
    @extend .membership-heading;
    color: $gold !important; // override widget
}
.opc-block-summary .product-item-inner {
    display: grid;
    grid-template-columns: auto auto;
    .product-item-name-block {
        grid-row: span 2
    }
    .subtotal,
    .summary-item-points {
        text-align: right;
    }
}
.summary-item-points {
    white-space: nowrap;
}
.admin__field-error {
    color: $color-red10
}
.checkout-index-index .page-wrapper > .trustpilot-widget {
    display: none;
}
