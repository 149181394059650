//body class for product pages
.catalog-product-view {
    .column.main {
        @include media-query(min, $lap-start) {
            @include clearfix;
            margin-left: -$double-spacing;
        }
    }
}

.product.media {
    position: relative;

    @include media-query(min, $lap-start) {
        float: left;
        width: 50%;
        padding-left: $double-spacing;
    }
}

.product-info-main {
    position: relative;
    display: flex;
    flex-direction: column;

    @include media-query(min, $lap-start) {
        float: right;
        width: 50%;
        padding-left: $double-spacing;
    }

    .page-title-wrapper {
        margin: 0;
        padding: 0;

        @include media-query(min, $lap-start) {
            order: 1;
        }
    }

    .page-title {
        @include font-size($h4-font-size);
        margin-bottom: $half-spacing;
        padding-top: 0;
    }

    .trustpilot-widget {
        @include media-query(min, $lap-start) {
            order: 3;
        }
        @include media-query(max, $lap-start) {
            order: 1;
        }
    }
}

.product.info.detailed {
    @include media-query(min, $lap-start) {
        float: left;
        width: 50%;
        padding-left: $double-spacing;
        clear: left;
    }
}

.emarsys-wrapper {
    display: none;
}

.product.attribute.sku {
    display: flex;
    @include font-size(13px);
    font-weight: 300;
    margin-bottom: $half-spacing;
    @include media-query(min, $lap-start) {
        width: 100%;
        order: 2;
    }

    .value {
        margin-left: ($half-spacing / 2);
    }

    // Hide the sku via css to prevent CLS instead of JS
    // on configurable product pages the sku is hidden by default
    // until user selects an option where the simple product sku gets shown
    // see Salons_ProductCode write-sku-on-change.js
    .page-product-configurable & {
        display: none;
    }
}

.product-info-price {
    margin-bottom: $base-spacing;
    @include media-query(max, $lap-start) {
        border-bottom: 1px solid $silver-lt;
        padding-bottom: $base-spacing;
    }

    @include media-query(min, $lap-start) {
        margin-bottom: 0;
        order: 3;
    }
}
klarna-placement[data-key="credit-promotion-badge"] {
    @include media-query(min, $lap-start) {
        order: 4;
    }
}
::part(osm-container) {
    border: none;
    padding-left: 0;
}

.ukonly-warning {
    width: 100%;
    margin-bottom: 0;
    font-size: $small-font-size;
}

.imm-widget-anchor {
    order: 3;
    margin: 0 !important;
}

.product-info-stock-sku {
    padding-bottom: $half-spacing;
    margin-bottom: 0;
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-wrap: wrap;

    @include media-query(min, $lap-start) {
        border-bottom: 1px solid $silver-lt;
        padding: $base-spacing 0 $base-spacing 0;
        margin: $base-spacing 0 $base-spacing 0;
        border-top: 1px solid $silver-lt;
        order: 6;
        width: 100%;
        min-height: 82px;

        > div {
            padding: {
                right: 1em;
            }
        }
    }
}

.product-info-offers {
    display: flex;
    flex-direction: column;
    @include font-size(16px);

    @include media-query(min, $lap-start) {
        order: 5;
        padding-bottom: 0;
        margin-bottom: 0;
        padding-top: $half-spacing;
    }
    @include media-query(max, $lap-start) {
        order: 1;
    }
    .product.attribute {
        order: 1;
    }
    .action-braintree-paypal-message {
        order: 2;
        @include media-query(max, $lap-start) {
            margin-top: 15px;
        }
    }
}

.stock {
    > span {
        display: block;
        @include font-size(11px);
        font-weight: 700;
        text-transform: capitalize;
        position: relative;
        color: $charcoal;

        &:before {
            content: "";
            display: inline-block;
            width: ($half-spacing + 5px);
            height: ($half-spacing + 5px);
            position: relative;
            top: 4px;
            background: url(../img/icon_tick_green.svg) center no-repeat;
            background-size: contain;
            margin-right: $half-spacing;

            @include media-query(min, $lap-start) {
                top: 2px;
            }
        }

        @include media-query(min, $lap-start) {
            @include font-size($base-font-size);
        }
    }

    &.unavailable span:before {
        background: url("../img/icon_cross_red.svg") center no-repeat;
        background-size: contain;
    }
}

.product.attribute.overview {
    display: none;

    @include media-query(min, $lap-start) {
        display: block;
        order: 7;
        @include font-size(16px);
    }

    .value {
        li {
            margin-left: $base-spacing;
        }
    }
}

.more-options-container {
    margin-top: $base-spacing;
    margin-bottom: $base-spacing;

    .button {
        width: 100%;
    }

    @include media-query(min, $lap-start) {
        order: 8;
    }

    @include media-query(min, $desk-start) {
        padding-top: ($base-spacing + 5px);
        border-top: 1px solid $silver-lt;
    }

    @include media-query(max, $lap-start) {
        order: 5;
    }

    + .product-add-form {
        padding-top: 0;
        border-top: none;
    }
}

.product-add-form {
    margin-bottom: $half-spacing;
    margin-top: $half-spacing;

    @include media-query(min, $lap-start) {
        margin-bottom: $base-spacing;
        margin-top: $base-spacing;
        order: 8;
    }

    @include media-query(min, $desk-start) {
        padding-top: ($base-spacing + 5px);
        border-top: 1px solid $silver-lt;
        margin-bottom: $base-and-half-spacing;
    }

    @include media-query(max, $lap-start) {
        order: 6;
    }

    form {
        @include media-query(min, $desk-start) {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        [type=submit] {
            @include hover {
                outline: none;
                color: $black;
            }
        }
    }

    .product-options-wrapper {
        width: 100%;
        margin-bottom: $base-spacing;

        @include media-query(min, $desk-start) {
            border-bottom: 1px solid $silver-lt;
        }

        .field {
            margin-bottom: $base-spacing;
            width: 100%;
            max-width: 100%;


            @include media-query(min, $desk-start) {
                margin-bottom: $base-spacing;
            }

            .label {
                display: block;

                @include media-query(min, $lap-start) {
                    max-width: 160px;
                    width: 100%;
                }
            }

            .control {
                position: relative;

                @include media-query(min, $lap-start) {
                    flex: 1;
                    margin-left: 15px;
                }

                .super-attribute-select {
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    z-index: -1;

                    &:disabled {
                        + .custom-select .custom-select-trigger {
                            cursor: not-allowed;
                            opacity: 0.5;
                        }
                    }

                    &.mage-error + .custom-select .custom-select-trigger {
                        border-color: $red;
                    }
                }
            }

        }

        // Configurable options only
        .field.configurable {
            @include media-query(min, $desk-start) {
                flex: 1;
            }

            .control {
                @include media-query(min, $lap-start) {
                    margin-left: 0;
                }
            }
        }
    }

    .product-options-bottom {
        @include media-query(min, $desk-start) {
            width: 100%;
        }

        .box-tocart {
            @include media-query(min, $desk-start) {
                flex-grow: 1;
                flex-shrink: 1;
            }

            .fieldset {
                display: flex;
            }
        }

        .field.qty {
            margin-bottom: 0;
            flex-grow: 0;
            flex-shrink: 0;
            width: 120px;

            .label {
                display: none;

                @include media-query(min, $desk-start) {
                    display: block;
                    margin-right: $base-spacing;
                    margin-bottom: 0;
                }
            }

            @include media-query(min, $desk-start) {
                display: flex;
                align-items: center;
                position: relative;
                margin-right: $base-spacing;
                width: auto;
            }

            .product-qty-errors {
                > * {
                    margin-bottom: $base-spacing;
                }

                @include media-query(min, $lap-start) {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                }
            }
        }

        .actions {
            padding-left: $base-spacing;
            flex-grow: 1;
            flex-shrink: 1;
            width: auto;

            .action.primary.tocart {
                width: 100%;
                min-width: 134px;

                &.disabled {
                    opacity: 1;
                }
            }
        }
    }

    .paypal {
        display: none;
    }

    .guest-price-button {
        width: 100%;

        @include media-query(min, $desk-start) {
            width: auto;
            margin-right: $base-spacing;
        }
    }

    .product.alert.stock {
        margin-bottom: $half-spacing;
        text-align: center;
        width: 100%;

        @include media-query(min, $desk-start) {
            text-align: left;
            margin-bottom: $base-spacing;
        }
    }

    .custom-swatches {
        order: 2;
        width: 100%;

        @include media-query(min, $desk-start) {
            order: 3;
        }
    }
}

.catalog-product-view.product-pro-club-membership .pro-club-pdp {
    display: none
}

.pro-club-pdp {
    order: 3;
    display: flex;
    align-items: center;
    margin-top: $base-spacing;
    margin-bottom: $base-spacing;
    img {
        background: rgba($beige, 0.3);
        padding: 6px 10px;
        border-radius: 6px;
    }
    div {
        margin-left: 5px;
        font-size: 16px;
        line-height: 18px;
        span {
            color: $beige;
            font-weight: $hn-font-weight;
        }
    }
    @include media-query(max, $lap-start) {
        order: 0;
    }
}

.product-add-form-klarna {
    order: 6;
    margin-bottom: 0;
}

.product-addto-links {
    padding-bottom: $base-spacing;
    margin-bottom: 0;
    margin-top: $base-spacing;
    border-bottom: 1px solid $silver-lt;

    @include media-query(min, $desk-start) {
        width: auto;
        border-bottom: none;
        padding: 0;
        z-index: 1;
        margin-top: 0;
    }
}

.action.towishlist {
    text-decoration: none;
    text-align: center;
    justify-content: center;

    // hide site-wide
    display: none;

    // show only on the product page
    .product-addto-links & {
        display: flex;
        align-items: center;

        @include media-query(min, $desk-start) {
            display: block;
        }
    }

    @include hover {
        text-decoration: none;
    }

    &:before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background-size: 15px;
        background: url(../svg/wishlist.svg) center no-repeat;
        margin-right: 5px;

        @include media-query(min, $desk-start) {
            margin-right: 0;
            width: 35px;
            height: 35px;
            background-size: 25px;
        }
    }

    span {
        @include font-size(13px);
        color: $charcoal;
        font-weight: 700;

        @include media-query(min, $desk-start) {
            display: none;
        }
    }
}

.product-info-timer {
    order: 9;
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {
        margin-bottom: $base-and-half-spacing;
    }

    .delivery-timer {
        padding: ($half-spacing + 5px) 0;
        background-color: $white;
        border-bottom: 1px solid $silver-lt;

        @include media-query(min, $lap-start) {
            padding: ($half-spacing + 5px);
            background-color: $mercury;
            border-bottom: none;
        }
    }

    .delivery-timer__text {
        text-align: left;
    }

    .delivery-timer__heading,
    .delivery-timer__count {
        display: inline;
    }
}

.product-info-customers {
    order: 8;

    @include media-query(min, $lap-start) {
        float: right;
        width: 50%;
        clear: right;
        padding-left: $double-spacing;
        margin-bottom: $double-spacing;
    }
}

.product-info-customers-container {
    padding: $base-spacing 0 $base-spacing;
    border: 1px solid $silver-lt;
}

.customer-opinions__title {
    text-align: center;
    padding-top: 0;
    margin-bottom: $base-spacing;
    @include font-size(18px);
    @include media-query(min, $desk-start) {
        @include font-size(22px);
    }
}

.customer-opinions {
    @include flicky-edge-to-edge;
}

.customer-opinions__item {
    display: inline-block;
    width: 50%;
    text-align: center;
    margin-bottom: $base-spacing;
    padding: 0 $half-spacing;
    @include font-size(13px);
    @include media-query(min, $desk-start) {
        @include font-size(16px);
    }
}

.customer-opinions__img {
    max-width: 100px;
    margin: 0 auto;
    text-align: center;
}

.customer-opinions-item__title {
    padding-top: 0;
    @include font-size(16px);
}

.delivery-tab {
    @include font-size(13px);

    h4 {
        @include font-size(13px);
    }

    p {
        @include font-size(13px);
    }

    @include media-query(min, $lap-start) {
    }
}

.catalog-product-view {
    .product-info-price {
        .button {
            @include media-query(max, $desk-start) {
                width: 100%;
            }
        }
    }
}

.actions-container {
    .product.alert.stock {
        display: block;

        .action.alert.button {
            width: 100%;
            margin-bottom: $base-spacing;
        }

        & ~ .product-addto-links {
            @include media-query(min, $lap-start) {
                bottom: 24px;
                align-self: center;
            }
        }
    }
}

.offer-heading-one h1,
.offer-heading-one h2,
.offer-heading-one h3,
.offer-heading-two h1,
.offer-heading-two h2,
.offer-heading-two h3,
.offer-heading-three h1,
.offer-heading-three h2,
.offer-heading-three h3,
ul li h3 {
    font-size: inherit;
    font-weight: inherit;
    display: inline;
    color: inherit;
}
