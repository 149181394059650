.items.addresses {
    list-style: none;
    margin: 0;

    @include media-query(min, $lap-start) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: ($base-spacing * 2);
    }

    > .item {
        position: relative;
        margin-bottom: $base-spacing;

        @include media-query(min, $lap-start) {
            margin-bottom: 0;
        }
    }

    .item.actions {
        .action {
            @include button;
            line-height: 1;
            padding: $half-spacing;
            background-color: $silver-lt;
            border-color: $silver-lt;
            color: $charcoal;
            margin-bottom: $half-spacing;

            @include hover {
                color: $charcoal;
                border-color: darken($silver-lt, 5%);
                background-color: darken($silver-lt, 5%);
            }
        }
    }
}
