@mixin nav-link() {
    display: block;
    padding: $half-spacing $base-spacing;
    color: $charcoal;
    font-weight: 700;
    text-decoration: none;
    position: relative;
    z-index: 1;

    @include hover {
        color: $charcoal;
        text-decoration: none;
    }
}

.fix-body {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}

.site-nav-overlay,
.site-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.2);
    visibility: hidden;
    opacity: 0;
    transition: opacity ease 0.3s;

    &.active {
        visibility: visible;
        opacity: 1;
    }
}
.site-overlay {
    z-index: 2;
}

.site-nav-overlay {
    z-index: 2;
}

.site-nav {
    width: 100%;
    -webkit-overflow-scrolling: touch;

    @include media-query(max, $lap-start) {
        visibility: hidden;
        opacity: 0;
        position: fixed;
        top: $topbar-height;
        left: 0;
        height: calc(100% - #{$control-height + $topbar-height});
        z-index: 999;
        background-color: $white;
        overflow: auto;
        transition: opacity ease 0.3s;

        &.active {
            visibility: visible;
            opacity: 1;
        }
    }
}

.site-nav__container {
    padding: 0;

    @include media-query(min, $lap-start) {
        padding: 0 $base-spacing;
    }
}

.site-nav__list {
    list-style: none;
    margin: 0;

    @include media-query(min, $lap-start) {
        display: flex;
        position: relative;
    }

    [href*='clearance'] span {
        color: $red;
        font-weight: 700;
    }
}

.level0,
.site-nav__item {
    border-bottom: 1px solid $grey-lt;

    @include media-query(max, $lap-start) {
        position: relative;
        border-bottom-color: $silver-lt;

        @include hover {
            background-color: rgba($charcoal, 0.03);
        }
    }

    &.active {
        z-index: 2;
    }

    &.parent {
        @include media-query(max, $lap-start) {
            &:after {
                content: '';
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;
                width: 47px;
                height: 47px;
                border-left: 1px solid $silver-lt;
                background: url('../img/icon_plus.svg') center no-repeat;
                background-size: 10px;
                cursor: pointer;
            }
            &.active {
                &:after {
                    background: url('../img/icon_minus.svg') center no-repeat;
                    background-size: 12px;
                }
            }
        }
    }

    @include media-query(min, $lap-start) {
        flex-grow: 1;
        border-bottom: none;
    }
}

.level0 > a,
.site-nav__link,
.site-nav__product-az-link {
    @include nav-link;

    @include media-query(min, $lap-start) {
        position: relative;
        padding: ($base-spacing - 5px) 0;
        width: 100%;
        font-weight: 300;
        font-size: 12px;
        text-align: center;
        height: 100%;

        &:after {
            visibility: hidden;
            opacity: 0;
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            width: 0;
            height: 0;
            border-left: $half-spacing solid transparent;
            border-right: $half-spacing solid transparent;
            border-bottom: $half-spacing solid $white;
        }

        &.active {
            &:after {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    @include media-query(min, $desk-start) {
        margin-left: 0;
        font-size: $small-font-size;
    }
}

.site-nav__link--clearance {
    color: $red;
    font-weight: 700;
}

.site-nav-account {
    @include nav-link;

    @include media-query(min, $lap-start) {
        display: none;
    }
}
.logout-link-mobile {
    order: 1;
    @include media-query(min, $lap-start) {
        display: none;
    }
}
