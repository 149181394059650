.level0.submenu,
.sub-nav {
    display: none;
    background-color: $mercury;
    list-style: none;
    margin: 0;

    &.active {
        display: block;
    }

    @include media-query(min, $lap-start) {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: $base-and-half-spacing;
        z-index: 1;
        background-color: $white;
        max-height: 500px;
        overflow: auto;
        margin: 0;
        flex-wrap: wrap;

        &.active {
            display: flex;
        }

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 10px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.5);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
    }
}

.level-top.active {
}

.level0.active {
}

.level1 {
    @include media-query(min, $lap-start) {
        background: $white;
    }
}

li.level1,
.sub-nav__col {
    position: relative;

    &.parent {
        @include media-query(max, $lap-start) {
            &:after {
                content: '';
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;
                width: 47px;
                height: 47px;
                border-left: 1px solid $silver-lt;
                background: url('../img/icon_plus.svg') center no-repeat;
                background-size: 10px;
                cursor: pointer;
            }
            &.active {
                &:after {
                    background: url('../img/icon_minus.svg') center no-repeat;
                    background-size: 12px;
                }
            }
        }
    }

    @include media-query(min, $lap-start) {
        width: calc(20% - #{$base-spacing});
        margin-right: $base-spacing;
        margin-bottom: $base-spacing;

        &:nth-child(5n + 5) {
            margin-right: 0;
        }
    }
}

li.level1 > a,
.sub-nav__heading {
    border-top: 1px solid $silver-lt;
    @include nav-link;

    @include media-query(min, $lap-start) {
        padding: 0;
        text-transform: uppercase;
        @include font-size($micro-font-size);
        border-bottom: 1px solid $silver-lt;
        border-top: none;
        margin-bottom: $half-spacing;
        padding-bottom: 3px;
    }

    @include media-query(min, $desk-start) {
        @include font-size($small-font-size);
    }
}

.level1.submenu,
.sub-nav__list {
    padding: $half-spacing 0;
    border-top: 1px solid $silver-lt;
    list-style: none;
    margin: 0;
    display: none;

    &.active {
        display: block;
    }

    @include media-query(min, $lap-start) {
        display: block;
        padding: 0;
        margin-left: -$half-spacing;
        border-top: none;
    }
}

.level2,
.sub-nav__item {
}

.level2 > a,
.sub-nav__link {
    display: block;
    line-height: 1.8;
    color: $base-color;
    text-decoration: none;

    @include media-query(max, $lap-start) {
        transition: none;
        padding: 2px $base-spacing;

        &:hover {
            color: $charcoal;
            text-decoration: none;
        }

        &.active,
        &:focus {
            background-color: $primary;
        }
    }

    @include media-query(min, $lap-start) {
        @include font-size(12px);
        line-height: 2.3;
        padding: 0 $half-spacing;

        @include hover {
            color: $charcoal;
            text-decoration: none;
            background-color: $primary;
        }
    }

    @include media-query(min, $desk-start) {
        @include font-size($small-font-size);
        line-height: 1.8;
    }
}
