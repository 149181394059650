
.site-search {
    @include media-query(max, $lap-start) {
        position: fixed;
        top: 0;
        left: 0;
        height: calc(100% - 52px);
        width: 100%;
        z-index: 999;
        background-color: $white;
        overflow: auto;
        transition: opacity ease 0.3s;
        visibility: hidden;
        opacity: 0;

        &.active {
            visibility: visible;
            opacity: 1;
        }
    }

    @include media-query(min, $lap-start)  {
        flex-grow: 1;
        margin-right: $base-spacing;

        &.active {
            position: relative;
            z-index: 2;
        }
    }

    @include media-query(min, $desk-start) {
        margin-right: $base-and-half-spacing;
    }
}

.site-search__field {
    position: relative;

    .label {
        display: none;
    }

    .control  {
        background: $white;
        @include media-query(max, $lap-start) {
            padding: $base-spacing;
            z-index: 1;
            position: sticky;
            top: -1px;
            position: -webkit-sticky;
        }
    }
}


.search-autocomplete {
    width: 100% !important;
    padding: 0 $base-spacing;
    background-color: $white;

    @include media-query(max, $lap-start)  {
        position: static!important;
    }

    @include media-query (min, $lap-start) {
        z-index: 2;
        top: 100%;
        left: 0;
        padding: 0;
        max-height: 520px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 6px
        }

        &::-webkit-scrollbar-track {
            background: $white;
        }

        &::-webkit-scrollbar-thumb {
            background: $silver;
        }

        &::-webkit-scrollbar-thumb:window-inactive {
            background: #919191;
        }
    }
}

.smile-elasticsuite-autocomplete-result {
    @include media-query (max, $lap-start) {
        display: flex;
        flex-direction: column;
    }

    @include media-query (min, $lap-start) {
        @include clearfix;
        position: relative;

        &:before {
            content: '';
            width: 30%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: $mercury;
        }
    }

    .autocomplete-list {
        margin-bottom: ($half-spacing * 1.5);
        text-align: left;

        @include media-query (min, $lap-start) {
            float: left;
            width: 30%;
            background: $mercury;
            margin: 0;
            clear: left;
            padding: $base-spacing;
            position: relative;
            z-index: 1;
            max-height: 550px;
            overflow: auto;
        }


        dd {
            width: 100%;
            padding: $half-spacing 0;
            @include font-size(16px);
            color: $charcoal;

            @include media-query (min, $lap-start) {
                @include font-size($small-font-size);
            }

            &.selected {
                cursor: pointer;
            }
        }
    }

    .autocomplete-list-title {
        color: $charcoal;
        text-align: left;
        @include font-size(16px);
        line-height: 1;
        padding-bottom: $half-spacing;
        border-bottom: 1px solid $silver-lt;

        @include media-query (min, $lap-start) {
            @include font-size($small-font-size);
        }

    }


    .autocomplete-list-product {
        order: 999;

        @include media-query (min, $lap-start) {
            float: right;
            width: 70%;
            background: $white;
            clear: right;
        }
        dd {
            display: flex;
            border-bottom: 1px solid $silver-lt;

            &:last-child {
                border-bottom: none;
            }

            @include media-query (min, $lap-start) {
                border-bottom: none;
            }

            .product-image-box {
                width: 70px;
                flex-grow: 0;
                flex-shrink: 0;

                img {
                    width: 100% !important;
                    height: auto !important;
                }
            }

            .product-item {
                padding-left: $half-spacing;
            }

            .product-name {
                color: $charcoal;
                margin-bottom: $half-spacing;
            }

            .price-container {
                @include font-size(16px);
                .price-including-tax {
                    display: none;
                }
            }
        }
    }

    .autocomplete-list-category {
        dd {
            font-weight: 100;

            .qs-option-name {
                font-weight: bold;
            }
        }
    }

    .autocomplete-list-term {
        dd {
            padding-left: 30px;
            background: url('#{$svg-path}icon_clock.svg') no-repeat left center;
            background-size: 17px;
        }
    }
}
