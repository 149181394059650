.site-legal {
    padding: $base-spacing 0;

    @include media-query(min, $desk-start) {
        padding: ($double-spacing + $half-spacing) 0 $base-spacing;
    }
}

.site-legal,
.site-legal__copyright {
    background: $footer-background-color;
}
.site-legal__column {
    max-width: 450px;
}

@include media-query(max, $lap-start) {
    .site-legal__copyright {
        text-align: left;
    }
}

.site-legal__container {
    display: flex;
    flex-direction: column;
    align-content: center;

    @include media-query(min, $lap-start) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.site-legal__column {
    flex: 1;
    line-height: 1;

    &:first-child {
        order: 2;
        margin-bottom: $base-spacing;

        @include media-query(min, $lap-start) {
            order: 1;
        }
    }

    &:nth-child(2) {
        order: 1;

        @include media-query(min, $lap-start) {
            order: 2;
            padding: 0 $base-spacing;
        }
        @include media-query(min, $desk-start) {
            padding: 0 ($base-spacing * 2);
        }
    }

    &:last-child {
        order: 3;
        display: none;
        justify-content: center;

        @include media-query(min, $lap-start) {
            justify-content: flex-end;
        }
    }
}

.site-legal__content {
    text-align: center;
    margin-bottom: $base-spacing;
    padding-bottom: $base-spacing;
    border-bottom: 1px solid $silver-lt;

    @include media-query(min, $palm-start) {
        display: grid;
        grid-column-gap: $double-spacing;
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-query(min, $desk-start) {
        grid-template-columns: repeat(3, 1fr);
        padding-bottom: $double-spacing;
        margin-bottom: $base-spacing;
    }
}

.site-legal__delivery-info {
    font-size: $micro-font-size;
    text-align: center;
    margin-bottom: $base-spacing;

    @include media-query(min, $palm-start) {
        margin-bottom: $base-spacing;
        grid-column: span 2;
    }

    @include media-query(min, $desk-start) {
        grid-column-start: 2;
        grid-row-start: 1;
        margin-bottom: 0;
        grid-column: span 1;
    }
}

.site-legal__secure-info {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: $base-spacing;

    @include media-query(min, $palm-start) {
        margin-bottom: 0;
    }

    @include media-query(min, $desk-start) {
        grid-column-start: 3;
        grid-row-start: 1;
        margin-bottom: 0;
    }
}

.site-legal__secure-info-item {
    margin-left: $base-spacing;

    &:first-child {
        margin-left: 0;
    }

    @include media-query(min, $lap-start) {
        margin-bottom: 0;
    }
}

.site-legal__payment-info {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin: 0;

    @include media-query(min, $desk-start) {
        grid-column-start: 1;
        grid-row-start: 1;
    }
}

.site-legal__payment-info-item {
    margin-bottom: 0;
    margin-left: $half-spacing;

    &:first-child {
        margin-left: 0;
    }

    @include media-query(min, $lap-start) {
        flex-basis: 65px;
        margin-bottom: 0;
    }
}

.site-legal__copyright {
    font-size: $micro-font-size;
    text-align: center;

    span {
        display: block;
        @include container;
        padding: $base-spacing $base-spacing 20px $base-spacing;

        @include media-query(min, $lap-start) {
            padding: $base-spacing 0;
        }
    }
}
