/*========================================
TABLES
========================================*/

.table-wrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

table {
    width: 100%;
}

caption {
    display: none;
}

th,
td {
    text-align: left;
    vertical-align: top;

    @include media-query(min, $lap-start) {
        &:last-child {
            text-align: right;
        }
    }
}

th {
    color: $charcoal;
    font-weight: 700;
}

td {
    padding: $half-spacing 0;
}

thead {
    th {
    }
}

tfoot td {
    padding: 0;
}

tfoot th {
}
