.new-in {
    overflow: hidden;
}

.new-in__container {
    padding-bottom: $base-spacing;
    margin-bottom: $base-spacing;
    border-bottom: 1px solid $lightgrey;

    &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.new-in__banner {
    margin-bottom: $base-spacing;
}

.new-in__section-title {
    text-align: center;
    @include font-size(22px);
    margin-bottom: $base-spacing;
}

.new-in__slides {
    @include flicky-edge-to-edge;
}

.new-in__slide {
    width: 100%;

    @include media-query(min, $lap-start) {
        width: 50%;
    }

    @include media-query(min, $wide-start) {
        width: 33.3333%;
    }
}

.new-in__slide-img-holder {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    margin-bottom: $half-spacing;
    display: block;
    background-color: $lightgrey;
    position: relative;
}

.new-in__slide-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.new-in__desc {
    display: block;
    display: -webkit-box;
    @include font-size(14px);
    font-weight: 500;
    color: $charcoal;
    text-decoration: none;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: $half-spacing;
    max-height: 44px;
    

    @include hover {
        color: $charcoal;
    }
}
