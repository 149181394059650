.filter {
    @include media-query(max, $lap-start) {
        visibility: hidden;
        opacity: 0;
        width: 100%;
        position: fixed;
        top: $topbar-height;
        left: 0;
        height: calc(100% - #{$control-height + $topbar-height});
        z-index: 1;
        background-color: $white;
        overflow: auto;
        transition: opacity ease 0.3s;
        margin-top: 0;
        margin-bottom: 0;

        &.active {
            visibility: visible;
            opacity: 1;
        }
    }
    margin-bottom: $half-spacing;
    background: $mercury;
}

.filter-title {
    position: relative;
    @include font-size(14px);
    font-weight: 700;
    color: $charcoal;
    padding: ($half-spacing + 5px) ($base-spacing + 5px);
    border-bottom: 1px solid $silver-lt;

    strong {
        font-weight: 700;
    }

    &:after {
        content: '';
        position: absolute;
        right: ($base-spacing + $half-spacing);
        top: 50%;
        transform: translateY(-50%);
        background: url('#{$svg-path}icon_cross.svg') no-repeat center;
        background-size: ($base-spacing - $half-spacing);
        width: ($base-spacing - 5px);
        height: ($base-spacing - 5px);

        @include media-query(min, $lap-start) {
            display: none;
        }
    }

    @include hover {
        cursor: pointer;
    }
}

.filter-content {
}

.filter-subtitle {
    display: none;
}

.filter-options {
}

.filter-options-item {
    border-top: solid 1px $silver-lt;

    &:first-child {
        border-top: none;
    }

    &.active {
        .filter-options-title {
            &:before {
                background: url('../svg/icon_minus.svg') center no-repeat;
            }
        }
        .filter-options-content {
            display: block;
        }
    }
}

.filter-options-title {
    @include font-size(14px);
    font-weight: 700;
    padding: ($half-spacing + 5px) $base-spacing;
    position: relative;
    color: $charcoal;

    @include hover {
        outline: none;
        cursor: pointer;
    }

    @include toggle($base-spacing);
}

.filter-options-content {
    padding: ($half-spacing + 5px);
    border-top: solid 1px $silver-lt;
    display: none;

    @include media-query(min, $lap-start) {
        padding: $base-spacing;
    }

    .search {
        margin-bottom: 0;
        .control {
            margin-bottom: $base-spacing;
        }
    }


    .items {
        margin-bottom: 0;
        list-style: none;
    }

    .item {
        @include font-size(14px);
        margin-bottom: $half-spacing;

        a {
            display: flex;
            align-items: flex-start;
            position: relative;
            text-decoration: none;
            color: $charcoal;

            label {
                font-weight: normal;
            }

            input {
                flex-grow: 0;
                flex-shrink: 0;
                top: 1px;
                position: relative;
            }
        }

        .count {
            flex-grow: 0;
            flex-shrink: 0;
            margin-left: $half-spacing/ 2;
            &:before {
                content: '(';
            }
            &:after {
                content: ')';
            }
        }

        .filter-count-label {
            display: none;
        }
    }

    .actions {
        a {
            @include font-size(14px);
            font-weight: 700;
        }
    }
}

.smile-es-range-slider {
    color: $charcoal;
    @include font-size(14px);

    [data-role=from-label] {
        display: block;
        float: left;
        padding: 0 0 5px;
    }

    [data-role=to-label] {
        display: block;
        float: right;
        padding: 0 0 5px;
    }

    [data-role=message-box] {
        flex: 1;
        &.empty {
            padding: 0;
        }
    }

    .actions-toolbar {
        display: flex;
        align-items: center;
        margin: 15px 0;
    }

    .ui-slider {
        height: 15px;
        clear: both;
        position: relative;

        &:before {
            content: '';
            width: 100%;
            height: 6px;
            background: $silver-lt;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -3px;

            @include media-query (min, $lap-start) {
                background: $white;
            }
        }
    }

    .ui-slider-range {
        width: 100%;
        height: 6px;
        background: $silver;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -3px;
    }


    .ui-slider-handle {
        display: block;
        position: absolute;
        padding: 0;
        border-radius: 100%;
        width: 15px;
        height: 15px;
        background: $secondary;

        &:last-of-type {
            margin-left: -8px;
        }
    }
}


.swatch-attribute {
    padding-left: $base-spacing;
    padding-right: $base-spacing;
}

.swatch-attribute-options {
}

.swatch-option-link-layered {
    .swatch-option.text {
        padding: 0 8px;
    }
}
