.category-header__toggle {
    background: none;
    padding: $base-spacing 0 0 0;
    outline: none;
    border: none;

    @include hover {
        background: none;
        color: $black;
    }
}
.slick-slider .all-brands {
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: $font-weight__bold;
    font-family: $sans;
}
