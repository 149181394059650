//
//  Default appearance styles
//  _____________________________________________

[data-content-type='buttons'] {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 10px;
}
