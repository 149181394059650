//Wishlist body class
.wishlist-index-index {
    //remove
    .comment-box,
    .qty {
        display: none;
    }

    //Wishlist item actions
    .product-item-actions {
        display: flex;
        justify-content: center;
        margin-bottom: 3px;

        .action.edit,
        .action.delete {
            display: block;
            width: $base-spacing;
            height: $base-spacing;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0.2;
            transition: opacity ease 0.3s;
            margin: 5px;

            @include hover {
                opacity: 0.8;
            }

            //hides action text
            &.edit span,
            &.delete span {
                display: none;
            }

            // edit item
            &.edit {
                background: url('../svg/edit.svg');
                background-size: contain;
            }

            // delete item
            &.delete {
                background: url('../svg/delete.svg');
                background-size: contain;
            }
        }
    }
}
