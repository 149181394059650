//Account Sales order viewpage
.sales-order-view,
.sales-order-invoice,
.sales-order-shipment {
    .order-links {
        list-style: none;
        margin-bottom: $base-spacing;

        @include media-query(min, $lap-start) {
            display: flex;
            margin-bottom: $double-spacing;
        }

        .item {
            @include media-query(min, $lap-start) {
                margin-right: $base-spacing;
            }

            a,
            strong {
                @include button;
                width: 100%;
                margin-bottom: $half-spacing;

                @include media-query(min, $lap-start) {
                    width: auto;
                    margin-bottom: 0;
                }
            }

            strong {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }

    .order-details-items {
        margin-bottom: ($base-spacing * 2);

        .order-title {
            display: flex;
            align-items: baseline;
            margin-bottom: ($base-spacing + $half-spacing);
            border-bottom: solid 1px $silver-lt;

            strong {
                display: block;
                color: $charcoal;
                @include font-size(20px);
                padding-bottom: $base-spacing/2;
            }

            a {
                @include font-size(14px);
                color: $grey;
                margin-left: auto;
            }
        }
    }
}
