.delivery-timer {
    padding: $half-spacing 0;
    @include media-query(min, $lap-start) {
        padding: 0;
    }
}

.delivery-timer__text {
    margin: 0;
    @include font-size($micro-font-size);
    line-height: 1;

    @include media-query(min, $lap-start) {
        text-align: center;
        @include font-size(12px);
        line-height: $base-line-height;
    }

    @include media-query(min, $desk-start) {
        @include font-size($small-font-size);
    }
}

.delivery-timer__heading {
    color: $charcoal;
    font-weight: 700;

    @include media-query(min, $lap-start) {
        display: block;
    }

    .svg-icon {
        position: relative;
        top: -2px;
        margin-right: 5px;
        width: 1.5em;
        height: 1.5em;

        @include media-query(min, $lap-start) {
            width: 1.2em;
            height: 1.2em;
        }
    }
}

.delivery-timer__count {
    color: $tertiary;
    font-weight: 700;

    @include media-query(min, $lap-start) {
        @include font-size(16px);
    }
}
