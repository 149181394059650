@import "_icomoon";

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-crown {
    &:before {
        content: $icon-crown;
    }
}
.icon-klarna7 {
    &:before {
        content: $icon-klarna7;
    }
}
.icon-plus {
    &:before {
        content: $icon-plus;
    }
}
.icon-minus {
    &:before {
        content: $icon-minus;
    }
}
.icon-arrow-right {
    &:before {
        content: $icon-arrow-right;
    }
}
.icon-arrow-left {
    &:before {
        content: $icon-arrow-left;
    }
}
.icon-search {
    &:before {
        content: $icon-search;
    }
}
.icon-price-match {
    &:before {
        content: $icon-price-match;
    }
}
.icon-minicart {
    &:before {
        content: $icon-minicart;
    }
}
.icon-delivery {
    &:before {
        content: $icon-delivery;
    }
}
.icon-account {
    &:before {
        content: $icon-account;
    }
}
