@font-face {
    font-family: 'museosans';
    src: url('#{$font-path}/MuseoSans_100-webfont.woff2') format('woff2'),
        url('#{$font-path}/MuseoSans_100-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'museosans';
    src: url('#{$font-path}/MuseoSans_300-webfont.woff2') format('woff2'),
        url('#{$font-path}/MuseoSans_300-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'museosans';
    src: url('#{$font-path}/MuseoSans_500-webfont.woff2') format('woff2'),
        url('#{$font-path}/MuseoSans_500-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'museosans';
    src: url('#{$font-path}/MuseoSans_700-webfont.woff2') format('woff2'),
        url('#{$font-path}/MuseoSans_700-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Pacifico';
    src: url('#{$font-path}/Pacifico-Regular.woff2') format('woff2'),
        url('#{$font-path}/Pacifico-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
