.ordercatalogue-index-index {
}

.catalogues {
    padding-top: $base-and-half-spacing;
}

.catalogues__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$base-spacing;

    @include media-query(min, $lap-start) {
        margin-left: -$base-and-half-spacing;
    }

    @include media-query(min, $wide-start) {
        margin-left: -$double-spacing;
    }
}

.catalogues__item {
    width: 50%;
    padding-left: $base-spacing;
    margin-bottom: $base-spacing;
    text-align: center;

    @include media-query(min, $lap-start) {
        width: 33.333%;
        padding-left: $base-and-half-spacing;
        margin-bottom: $base-and-half-spacing;
    }

    @include media-query(min, $desk-start) {
        width: 25%;
    }

    @include media-query(min, $wide-start) {
        width: 20%;
        margin-bottom: $double-spacing;
        padding-left: $double-spacing;
    }

    .field.choice {
        justify-content: center;
    }

    a:hover {
        text-decoration: none;
    }
}

.catalogues__image {
    display: block;
    width: 100%;
    margin-bottom: $base-spacing;
}

.catalogues__name {
    display: block;
    margin-bottom: ($half-spacing - 3px);
    line-height: 1.3;
    color: $charcoal;
    font-weight: 700;
    height: 38px;
    width: 100%;
    @include font-size(15px);
    overflow: hidden;
}
