/*========================================
BUTTONS
========================================*/
button {
    @include button;

    @include hover {
        outline: none;
    }
}

.button {
    @include button;
}

.button--small {
    line-height: 1.4;
    padding: $half-spacing;
    @include font-size(10px);

    @include media-query(min, $palm-start) {
        line-height: 1;
        @include font-size($small-font-size);
    }
}

.button--large {
    line-height: 2.2;
    padding: ($half-spacing + 3) $base-and-half-spacing ($half-spacing + 1);
}

.button--full {
    width: 100%;
}

/**
 * Button Styles
 */

.button--bold {
    background-color: $charcoal;
    color: $white;
    border-color: transparent;

    @include hover {
        background-color: darken($charcoal, 5%);
        border-color: darken($charcoal, 5%);
        color: $white;
    }
}

.button--subtle {
    background-color: $silver-lt;
    border-color: $silver-lt;
    color: $charcoal;

    @include hover {
        color: $charcoal;
        border-color: darken($silver-lt, 5%);
        background-color: darken($silver-lt, 5%);
    }
}

.button--outline {
    background-color: transparent;
    border-color: $silver;
    color: $charcoal;

    @include hover {
        color: $charcoal;
        border-color: $charcoal;
        background-color: transparent;
    }
}

.button--icon-right {
    display: flex;
    align-items: center;

    .svg-icon {
        margin-left: $base-spacing;
    }
}

.button--icon-left {
    display: flex;
    align-items: center;

    .svg-icon {
        margin-right: $base-spacing;
    }
}

button.disabled,
.button.disabled {
    opacity: 0.2;
    cursor: not-allowed;
}
