.added-to-basket {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: auto;
    padding: $base-spacing $base-spacing 0;
    text-align: center;
    background: $white;

    @include media-query(min, $lap-start) {
        padding: $double-spacing $base-spacing 0;
    }

    .svg-icon {
        width: $double-spacing;
        height: $double-spacing;
    }
}

.added-to-basket__top {
    margin-bottom: $base-spacing;
    flex-grow: 0;
    flex-shrink: 0;

    @include media-query(min, $lap-start) {
        margin-bottom: $double-spacing;
    }
}

.added-to-basket__bottom {
    padding-top: $base-spacing;
    border-top: 1px solid $silver-lt;
    flex-grow: 0;
    flex-shrink: 0;

    @include media-query(min, $lap-start) {
        padding-top: $base-and-half-spacing;
    }
}

.added-to-basket__header {
    margin-bottom: $base-spacing;
    @include font-size(18px);
    color: $charcoal;
    font-weight: 700;
}

.button--add-to-basket {
    width: 15em;
}

.added-to-basket__buttons {
    @include media-query(min, $palm-start) {
        display: flex;
        justify-content: space-around;
    }

    button {
        @include media-query(min, $palm-start) {
            width: 15em;
            margin-bottom: $base-spacing;
            margin-bottom: 0;
        }
    }

    a,
    button {
        margin-bottom: $base-spacing;

        @include media-query(min, $lap-start) {
            margin-bottom: $base-and-half-spacing;
        }
    }
}

.added-to-basket-popup {
    // background
    &.mfp-bg {
        background-color: rgba($black, 0.2);
    }

    .mfp-close {
        top: $double-spacing;

        @include hover {
            background: none;
            border: none;
        }
    }
    //content
    .mfp-content {
        padding: $base-and-half-spacing 0;
        max-width: 850px;

        @include media-query(min, 1150px) {
            max-width: 800px;
        }
    }
}
