/*========================================
PARAGRAPHS
========================================*/

p {
    margin-bottom: $base-spacing;
}

.lede {
    @include font-size($lede-font-size);
    font-weight: 500;
    color: $charcoal;
}

small,
.small {
    @include font-size($small-font-size);
}

.micro {
    @include font-size($micro-font-size);
}

caption,
.caption {
    padding-top: ($half-spacing / 2);
    margin-bottom: $half-spacing;
    @include font-size($small-font-size);
}

address {
    font-style: normal;
}

blockquote {
    margin-left: $base-spacing;
    margin-right: $base-spacing;
}

dt {
    font-weight: 500;
}
