.carousel {
    padding-bottom: ($base-spacing * 2.5);
    border-bottom: 1px solid $silver-lt;

    @include media-query (min, $lap-start) {
        padding-bottom: ($base-spacing * 4);
    }
}

.carousel__container {
    position: relative;
}

.carousel__carousel-spacer {
    padding-top: calc(468 / 355 * 100%);
    box-sizing: content-box;
    background-color: $silver-lt;
    border: 0.5px solid $silver;

    @include media-query(min, $lap-start) {
        padding-top: calc(35 / 117 * 100%);
    }
}

.carousel__slides {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: opacity ease 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .flickity-viewport {
        width: 100%;
        order: 1;
        height: 100% !important;
    }

    &:not(.flickity-enabled) {
        overflow: hidden;
        white-space: nowrap;
        opacity: 0;
    }
}

.carousel__slide {
    display: inline-block;
    width: 100%;
}

.carousel__image {
    img {
        display: block;
        width: 100%;
    }
}

.carousel__content {
}
