.cms-index-index {
    .columns {
        max-width: unset;
        padding: 0;
    }
    .discover-products,
    .product-deals .row-full-width-inner,
    .discover-range,
    .our-brands,
    .product-trending,
    .trending-blog .row-full-width-inner {
        max-width: $page-width;
        padding: 0 20px;
        margin: 0 auto;
    }
    .tile.tile--square .tile__link.button {
        background-color: $white;
        color: $charcoal;
        border-color: $silver-lt;
        &:hover {
            border-color: $secondary;
            outline: none;
        }
    }
    .hp-banner {
        .pagebuilder-column-line:not(.slick-initialized) {
            .pagebuilder-column {
                width: 100% !important;
                &:nth-of-type(n+2) {
                    display: none !important;
                }
            }
        }
        .slick-dots {
            display: block !important;
            position: absolute;
            bottom: 0;
        }
        figure {
            margin-bottom: 0;
        }
        img {
            width: 100%;
        }
    }
    .product-deals,
    .product-trending {
        .product-item-info {
            background-color: $color-white;
        }
        .product-item-details {
            padding: 20px 10px;
        }
        .slick-slide > div,
        .product-item, .product-item-info {
            height: 100%;
        }
        .product-item {
            margin-bottom: 0;
        }
    }
    .discover-products {
        .pagebuilder-column-line {
            grid-gap: 30px 40px;
        }
        .tile__title {
            padding-top: 0;
        }
        .tile__desc p {
            margin-bottom: 10px;
        }
    }
    .discover-range figure {
        margin-bottom: 0;
    }
    .product-deals,
    .product-trending,
    .our-brands,
    .trending-blog {
        .slick-arrow {
            border: 1px solid $light-grey;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            &:before {
                font-size: 23px;
                display: inline-block;
                font-family: $icomoon-font-family;
                font-weight: bold;
                padding: 0;
            }
        }
        .slick-prev:before {
            content: $icon-arrow-left;
        }
        .slick-next:before {
            content: $icon-arrow-right;
        }
    }
    .our-brands {
        position: relative;
        .pagebuilder-column-line {
            position: unset;
            width: unset !important;
        }
        .pagebuilder-column-line,
        .slick-track {
            align-items: center;
        }
        .slick-prev {
            left: 20px;
        }
        .slick-next {
            right: 20px;
        }
        figure {
            margin-bottom: 0;
        }
        .pagebuilder-button-primary {
            max-width: 250px !important;
            background-color: $black;
            color: white;
        }
        .all-brands {
            white-space: nowrap;
            font-weight: $font-weight__bold;
            text-transform: uppercase;
        }
    }
    .product-deals,
    .product-trending,
    .trending-blog {
        .slick-dots {
            margin-top: 30px;
        }
        .slick-dots li {
            flex-grow: 1;
            margin: 0;
        }
        .slick-dots li:not(.slick-active) button {
            background-color: $light-grey;
        }
        .slick-dots li,
        .slick-dots li button {
            width: 100%;
            height: 5px;
        }
        .slick-dots li button {
            border: none;
        }
    }
    .btns[data-content-type="buttons"] {
        justify-content: left;
    }
    .pagebuilder-transparent .pagebuilder-button-primary {
        padding: 0;
        margin: 0;
        background-color: transparent;
        font-size: 14px;
    }
    .pagebuilder-transparent .pagebuilder-button-primary:after {
        content: $icon-arrow-right;
        display: inline-block;
        vertical-align: text-top;
        margin-left: 15px;
        font-family: $icomoon-font-family;
        font-weight: bold;
        font-size: 15px;
    }
}

@include media-query(min, $desk-start) {
    .our-brands div.slick-track {
        grid-gap: 50px;
    }
}

@include media-query(min, $lap-start) {
    .hp-banner .slick-dots {
        text-align: left;
        padding-left: 13.5%;
    }
    .product-deals,
    .product-trending {
        position: relative;
        .text {
            max-width: 60%;
        }
        .slick-slider {
            position: unset;
        }
        .slick-arrow {
            top: 65px;
        }
        .slick-dots {
            margin-left: 40px;
        }
    }
    .our-brands {
        .pagebuilder-column-line {
            margin: 0 70px;
        }
        .slick-track {
            grid-gap: 20px;
        }
    }
    .product-deals,
    .product-trending,
    .trending-blog {
        padding-top: 30px;
        padding-bottom: 50px;
        .row-full-width-inner {
            position: relative;
        }
        .pagebuilder-column-line {
            margin-top: 40px;
            flex-direction: column;
            position: unset;
        }
        .slick-prev {
            right: 80px;
            left: unset;
        }
        .slick-next {
            right: 20px;
        }
    }
    .trending-blog {
        .slick-arrow {
            top: 27px;
        }
        .slick-slide {
            margin-right: 40px;
        }
    }
}

@include media-query(max, $lap-start) {
    .btns [data-content-type=button-item] {
        max-width: unset;
    }
    .hp-banner .slick-dots {
        padding-bottom: 15px;
    }
    .product-deals,
    .trending-blog {
        padding: 20px 0 30px;
    }
    .product-deals,
    .product-trending {
        .slick-arrow {
            display: none !important;
        }
        .slick-dots {
            margin-left: 20px;
        }
    }
    .our-brands {
        .slick-track {
            grid-gap: 20px;
        }
    }
    .discover-products .pagebuilder-column-line {
        display: flex;
        flex-wrap: wrap;
    }
}
