// Page class
.checkout-cart-index {
    .page-title-wrapper {
        padding: 0;

        @include media-query(min, $lap-start) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .page-title {
        font-size: $h4-font-size;
        @include media-query(min, $lap-start) {
            padding-top: $double-spacing;
            margin-bottom: $double-spacing;
        }
    }

    .site-footer {
        margin-top: 0;
    }

    .mage-error[generated] {
        position: absolute;
        top: 35px;
        background: $white;
    }

    //Free Gift icon
    .ampromo-items-add {
        float: none!important;
        margin-bottom: $base-spacing!important;
    }

    .ampromo-add-message {
        display: flex;
        align-items: center;
        justify-content: center;
        .ampromo-add-icon {
            margin-right: 0.5rem;
            width: 32px;
            height: 32px;
            background: url("#{$image-path}icon_freegift.jpg") no-repeat center;
        }
        [data-role="ampromo-popup-show"] {
            margin-left: 0.5rem;
        }
    }
}

.form-cart .pro-club-cart {
    position: absolute;
    right: 0;
    @include media-query(max, $lap-start) {
        bottom: 20px;
    }
}

.cart-summary .pro-club-cart {
    border-left: 1px solid $silver-lt;
    border-right: 1px solid $silver-lt;
    margin: -20px 0 0;
    padding: 0 30px 20px 0;
    .points {
        justify-content: right;
    }
}

.cart-container {
    position: relative;
    margin-bottom: $base-spacing;

    @include media-query(min, $desk-start) {
        margin-bottom: ($base-spacing * 2);
        @include clearfix;
    }
    .pro-club-cart {
        font-size: 12px;
        text-align: right;
        .points {
            font-weight: 600;
            color: $gold;
            display: flex;
            &:before {
                content: '';
                margin-right: 5px;
                height: 11px;
                width: 14px;
                display: block;
                margin-top: 2px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11' fill='none'%3E%3Cpath fill='%23CCAB82' d='m0 .407 4.965 5.704L6.875 0l1.91 6.111L13.75.407V11H0V.407Z'/%3E%3C/svg%3E");
            }
        }
    }
}

.cart-action-continue {
    width: 100%;
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {
        width: auto;
        margin-bottom: 0;
    }

    @include media-query (min, $desk-start) {
        width: calc(35% - 40px);
    }
}

.cart-summary {
    width: 100%;
    @include media-query(min, $desk-start) {
        width: 35%;
        float: right;
        padding-left: $double-spacing;
    }

    .block.shipping {
        display: none;
    }
}

.form-cart {
    @include media-query(min, $desk-start) {
        width: 65%;
        float: left;
    }

    table {
        display: block;
        margin-bottom: $base-spacing;

        @include media-query(min, $lap-start) {
            padding: 0 $base-and-half-spacing $base-spacing;
            border: 1px solid $silver-lt;
        }

        caption {
            display: none;
        }

        thead,
        tbody,
        tfoot,
        tr,
        th,
        td {
            display: block;
        }

        tr {
            display: block;
            position: relative;

            @include media-query(min, $lap-start) {
                display: flex;
                align-items: center;
            }
        }

        thead {
            display: none;

            @include media-query(min, $lap-start) {
                display: block;
                border-bottom: 1px solid $silver-lt;
            }
        }

        thead th {
            background: unset;
            color: $charcoal;
            font-weight: 700;
        }

        tbody {
            border-bottom: 1px solid $silver-lt;

            &:last-child {
                border-bottom: none;

                td {
                    padding-bottom: 0;
                }
            }
        }

        td,
        th {
            @include media-query(min, $lap-start) {
                padding: $base-spacing 0;
            }
        }
    }

    .item-info {
        padding: $base-spacing 0;
        @include clearfix;
        min-height: (($button-height * 4) + ($base-spacing * 3));

        @include media-query(min, $lap-start) {
            padding: 0;
            min-height: unset;
        }
    }

    th.col.image {
        span {
            display: none;
        }
    }

    .col.image {
        float: left;

        @include media-query(min, $lap-start) {
            width: 10%;
        }

        .product-item-photo {
            display: block;
            width: ($button-height * 3);
            height: auto;
            flex-shrink: 0;

            @include media-query(min, $lap-start) {
                width: 100%;
            }
        }

        .product-image-photo {
            width: 100%;
            height: auto;
        }
    }

    .col.item {
        padding-left: $base-spacing;

        @include media-query(max, $lap-start) {
            float: right;
            clear: right;
            padding-bottom: 0;
            width: calc(100% - #{$button-height * 3});
        }

        @include media-query(min, $lap-start) {
            padding-right: $base-spacing;
            width: 35%;
        }

        .product-item-details {
            .item-options {
                margin: 0;
                @include font-size($small-font-size);
            }

            .item-option {
                @include font-size(12px);

                &.lead-time {
                    color: $red;
                }

                &.uk-only {
                    color: $red;
                }

                dt {
                    display: inline;
                    font-weight: 100;

                    &:after {
                        content: ':';
                    }
                }

                dd {
                    display: inline;
                    font-weight: 700;
                    color: $charcoal;
                }
            }
        }

        .product-item-name {
            display: block;
            margin-bottom: $half-spacing;

            a {
                display: block;
                @include font-size(16px);
                line-height: 1.5;
                font-weight: 500;
                text-decoration: none;
                color: $charcoal;
            }
        }
    }

    .col.price {
        @include media-query(max, $lap-start) {
            float: right;
            clear: right;
            padding-bottom: 0;
            width: calc(100% - #{$button-height * 3});
            padding-left: $base-spacing;
            @include font-size($small-font-size);
            font-weight: 500;
            color: $charcoal;

            &:before {
                content: attr(data-th);
                font-weight: 100;
            }
        }

        @include media-query(min, $lap-start) {
            width: 15%;
        }
    }

    .col.qty {
        @include media-query(max, $lap-start) {
            float: right;
            clear: right;
            padding-bottom: 0;
            width: calc(100% - #{$button-height * 3});
            padding-left: $base-spacing;
        }

        @include media-query(min, $lap-start) {
            width: 20%;
            text-align: center;
        }

        .field {
            margin-bottom: 0;
        }

        .product-add {
            margin: 0 auto;
        }
    }

    .col.subtotal {
        @include media-query(max, $lap-start) {
            float: right;
            clear: right;
            width: calc(100% - #{$button-height * 3});
            padding-left: $base-spacing;
            padding-top: $half-spacing;
            padding-bottom: 0;
            font-weight: 500;
            color: $charcoal;
        }

        @include media-query(min, $lap-start) {
            width: 15%;
            text-align: right;
        }
    }

    .col.actions {
        @include media-query(max, $lap-start) {
            position: absolute;
            top: $base-spacing;
            left: 0;
        }

        @include media-query(min, $lap-start) {
            width: 5%;
            text-align: right;
        }

        span {
            display: none;
        }

        .svg-icon {
            color: $charcoal;
            width: 1.5em;
            height: 1.5em;
            @include media-query(min, $lap-start) {
                width: 1em;
                height: 1em;
            }
        }
    }
}

.cart.actions {
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {
        display: flex;
        align-items: center;
    }

    .action.continue,
    .action.clear {
        display: none;

        @include media-query(min, $lap-start) {
            display: block;
        }
    }

    .action.update {
        width: 100%;

        @include media-query(min, $lap-start) {
            width: unset;
            margin-left: auto;
        }
    }

    .cart-action-divider {
        display: none;
        font-weight: 700;
        color: $charcoal;
        @include font-size($micro-font-size);
        margin: 0 $base-spacing;

        @include media-query(min, $lap-start) {
            display: block;
        }
    }
}

.free-shipping-notice {
    border: 1px solid $silver-lt;
    padding: $base-spacing $base-and-half-spacing;
    margin-bottom: $base-spacing;
    @include font-size($micro-font-size);

    @include media-query(min, $lap-start) {
        margin-bottom: $double-spacing;
    }
    @include media-query(min, $desk-start) {
        @include font-size($small-font-size);
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    &:empty {
        display: none;
    }
}

.free-shipping-notice__banner {
    background-color: $silver-lt;
    padding: $half-spacing;
    text-align: center;
    color: $charcoal;
    font-weight: bold;
    margin-bottom: $base-spacing;

    &:before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        position: relative;
        top: 2px;
        background: url(../img/icon_tick_green.svg) center no-repeat;
        background-size: contain;
        margin-right: 0.5em;
    }

    small {
        font-size: 0.9em;
    }
}

.free-shipping-notice__banner--incentive {
    @extend .free-shipping-notice__banner;
    background-color: $smoke;

    &:before {
        display: none;
    }
}


//Hacks to get the free gift to how the client wants it

.ampromo-item.selected {
    background: $white !important;
    border-color: $charcoal !important;
}

.ampromo-item:hover {
    background: $white !important;
    border-color: $charcoal !important;
}

.ampromo-item:focus {
    outline: none !important;
}

.ampromo-item form {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ampromo-item-qty-input {
    margin-top: auto;
}

.checkout-cart-index {
    .slick-track {
        display: flex !important;
    }
    .slick-slide {
        height: auto !important;
    }
    .slick-dots {
        display: none !important;
    }
}

.lead-time-notice {
    border: 1px solid #e6e6e6;
    padding: $half-spacing $base-spacing;
    margin-bottom: $base-spacing;
    @include font-size($micro-font-size);

    @include media-query(min, $lap-start) {
        margin-bottom: $double-spacing;
    }

    @include media-query(min, $desk-start) {
        @include font-size($small-font-size);
    }

    p {
        margin-bottom: 0;
        color: $red;
    }
}

.checkout-methods-items {
    .action.disabled {
        @include button;
        background-color: $white;
        border: 1px solid $black;
        cursor: not-allowed;
        margin-bottom: 0;

        &:hover {
            background-color: $white;
            border: 1px solid $black;
        }
    }
}
