.blog-page {
    .breadcrumbs,
    &:not(.blog-post-view) .page-title-wrapper,
    .post-image .post-category,
    .sidebar-additional .block-wishlist {
        display: none;
    }
    .post-info {
        text-align: center;
    }
    .blog-widget-recent:not(.featured-posts) {
        margin: 25px auto 0;
        max-width: 1220px;
        padding: 25px 0;
        width: 100%;
        border-top: 1px solid $grey;
        h3 {
            text-align: center;
            text-transform: uppercase;
            font-size: 16px;
        }
        .post-text-hld,
        .post-read-more,
        .post-posed-date .label,
        .post-holder .post-header .post-info .item::after,
        .post-holder .post-header .post-info .item.post-categories {
            display: none;
        }
        .post-holder .post-header {
            order: 1;
        }
        .post-holder {
            display: flex !important;
            flex-direction: column;
            padding: 0 15px;
        }
        .post-title-holder {
            text-align: center;
            display: block;
        }
        img {
            height: 170px;
        }
        .post-header .post-title a {
            font-size: 16px;
        }
        .post-header .post-info .value {
            font-size: 13px;
        }
    }
    .featured-posts .post-header {
        background: $lightgrey;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        @include media-query(min, $lap-start) {
            margin: 0 3%;
            height: 430px;
        }
        @include media-query(max, $lap-start) {
            height: 490px;
        }
        .featured-detail {
            background: rgba(0,0,0,.3) none repeat scroll 0 0;
            bottom: 0;
            margin: 0;
            padding: 110px 35px 15px;
            position: absolute;
            top: 0;
            width: 100%;
            text-align: center;
        }
        .post-title a {
            color: $color-white;
            font-weight: bold;
            @include media-query(min, $lap-start) {
                font-size: 36px;
                line-height: 46.8px;
            }
            @include media-query(max, $lap-start) {
                font-size: 30px;
                line-height: 39px;
            }
        }
        .post-bottom-info {
            border: none;
            margin-top: 0;
            margin-bottom: 30px;
        }
        .post-categories a,
        .post-date,
        .comments-link,
        .post-view {
            color: $color-white;
        }
        .post-categories a {
            font-size: 14.875px;
        }
        .post-date {
            line-height: 22.4px;
            font-size: 14px;
        }
        .post-view {
            display: inline-block;
            background: none;
            padding: 0;
            top: 0;
        }
        .post-view:after {
            display: none;
        }
        .post-title-holder {
            display: block;
        }
        .comments-link:before {
            position: relative;
            top: 3px;
            filter: invert(1);
            background: url("../svg/post-comment-link-featured.svg");
            height: 15px;
            width: 15px;
            vertical-align: inherit;
            margin-right: 10px;
        }
    }
    .toolbar .pages {
        display: block;
    }
    .c-comments {
        border-top: none;
        padding-top: 0;
        position: relative;
    }
    .c-post .publish-date {
        font-size: 14px;
        display: block;
    }
    .c-comments .p-text {
        color: $color-black;
        font-size: 16px;
    }
    .c-comments .p-actions {
        position: absolute;
        top: 0;
        right: 0;
    }
    .c-comments .p-actions a.reply-action {
        padding: 5px 15px;
        background: $color-white-smoke;
        text-decoration: none;
        color: $color-black;
        font-size: 14px;
        border-radius: 3px;
        &:hover {
            background: $light-yellow
        }
    }
    #reply-title + .small-text {
        margin-bottom: 15px;
        font-size: 16px;
        color: $color-black;
    }
    .c-replyform textarea {
        height: auto;
    }
    .c-replyform .c-btn-hld .lr-hld {
        flex-direction: column;
        gap: 20px;
    }
    .c-replyform .left-hld,
    .c-replyform .right-hld {
        width: 100%;
    }
    .c-comments .c-comment {
        padding-bottom: 20px;
    }
}
a.post-read-more.action.primary.button,
.post-read-more a.action.button.primary {
    font-weight: $font-weight__light;
    text-transform: none;
    padding: 14px 30px;
    border-radius: 3px;
}
.post-read-more a.action.button.primary {
    font-size: 15px;
}
.post-list .post-image .image-wrap {
    padding-bottom: 0;
}
.post-list .post-container .post-image,
.post-list.modern .post-container .post-info {
    flex: auto;
    @include media-query(min, $lap-start) {
        width: 50%;
    }
    @include media-query(max, $lap-start) {
        width: 100%;
        max-width: unset;
        padding-left: 0;
    }
}
.post-list .post-container {
    flex-wrap: wrap;
}
.post-list a.category-name:not(:last-of-type):after {
    content: ',';
    color: rgb(188, 190, 192);
}
.post-bottom-info {
    width: 100%;
    order: 3;
    margin-top: 40px;
    border-top: 1px solid $lightgrey;
    padding-top: 10px;
}
.post-category a {
    font-size: 14px;
    text-transform: uppercase;
    color: #bcbec0;
}
.post-list .post-container .post-info .post-title-wrap h3 a {
    font-size: 24px;
}
.post-list .post-info .post-data-wrap .post-author-name a {
    font-weight: $font-weight__light;
}
.post-list .post-info .post-data-wrap,
.post-list .post-info .post-data-wrap * {
    font-size: 15px;
    text-transform: capitalize;
    font-weight: $font-weight__light;
    font-family: $sans;
}
.post-list .post-container .post-description {
    text-align: left;
    font-size: 16px;
    color: $color-black;
}
.post-list .post-container .post-description * {
    font-size: 15px;
}
.post-read-more a.action.button.primary {
    border-radius: 3px;
    &:hover {
        color: $color-white;
        background-color: $color-black;
        border: 1px solid $color-black;
    }
}
.blog-page-list .post-holder {
    border-bottom: none !important;
}
.widget.block-categories ul.accordion li {
    margin: 0;
    border-bottom: 1px dashed $color-gray96;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
}
.widget.block.block-categories {
    border: 1px solid $color-gray96;
    padding-right: 15px;
    padding-left: 15px;
    .block-title {
        color: #000;
        font-size: 18px;
        position: static;
        margin-bottom: 20px;
        margin-top: 0;
        display: block;
        text-align: center;
        border-bottom: 1px solid $color-gray96;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    strong,
    li a {
        font-weight: $font-weight__bold;
    }
}
.sidebar .widget ul > li:last-child {
    border-bottom: none;
}
.post-list .post-container .post-view {
    background: none;
    color: $color-black;
    padding: 0;
    display: inline-block;
    font-size: 14px;
    top: -3px;
    font-weight: normal;
    margin-left: 10px;
    &:before {
        content: '';
        background: url("../svg/view-post-count.svg");
        display: inline-block;
        width: 15px;
        height: 15px;
        background-size: 15px;
        vertical-align: sub;
        margin-right: 5px;
    }
    &:after {
        display: none;
    }
}
.comments-link:before {
    content: '';
    background: url("../svg/post-comment-link.svg");
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    position: relative;
    top: 1px;
    vertical-align: bottom;
}
.post-list .post-image .image-container,
.post-list .post-image .image-wrap {
    width: 100%;
    height: 100%;
}
.blog-page .toolbar .pages-items .item {
    height: 50px;
    width: 50px;
    margin: 0 0 0 5px;
    padding: 0;
    text-align: center;
    border-radius: 3px;
    &.current,
    &:hover {
        border: none;
        padding: 0;
        background: $color-black;
    }
    .page {
        color: $charcoal;
    }
    &.current .page,
    &:hover .page {
        color: $color-white;
        font-weight: normal;
    }
}
.toolbar .pages-items .pages-item-next .next,
.toolbar .pages-items .pages-item-previous .previous {
    margin: 5px auto 0;
}
.blog-header {
    margin-top: 0;
    text-align: center;
    position: relative;
    p {
        font-size: .82353rem;
        font-weight: $font-weight__light;
    }
    h1 {
        color: $color-black;
        font-weight: $font-weight__bold;
        font-size: 36px;
        text-transform: capitalize;
    }
    @include media-query(min, $lap-start) {
        background: $color-white-smoke;
        margin-bottom: 55px;
        padding-top: 70px;
        h1 {
            padding-bottom: 70px;
        }
        &:before {
            content: '';
            display: block;
            background: $color-white-smoke;
            height: 100%;
            left: -100vw;
            width: 200vw;
            position: absolute;
            top: 0;
        }
        p,
        h1 {
            position: relative;
        }
    }
    @include media-query(max, $lap-start) {
        p {
            margin-top: 16px;
            margin-bottom: 0;
        }
    }
}
.blog-post-view ._post-view {
    .post-description p,
    span,
    p,
    span strong,
    p strong {
        font-size: 15px;
        line-height: 24px;
        color: $charcoal;
    }
    a {
        text-decoration: underline;
    }
    p:first-of-type::first-letter {
        float: left;
        color: $black;
        font-size: 78px;
        line-height: 60px;
        padding-top: 10px;
        padding-right: 10px;
        padding-left: 0;
        font-weight: $font-weight__regular;
        font-style: normal;
    }
    h2,
    h2 strong {
        color: $charcoal;
        font-weight: $font-weight__bold;
    }
    h2 a,
    h3 a,
    h1 strong a,
    {
        font-weight: $font-weight__light;
    }
    h2 a {
        font-size: 38px;
    }
    h3 a {
        font-size: 31px;
    }
    li {
        font-size: 14px;
        color: $charcoal;
    }
    .post-tag .post-tag-title,
    .post-tag .post-tags a {
        background: $lightgrey;
        color: $charcoal;
        padding: 10px 15px;
        font-size: 14px;
        font-weight: $font-weight__light;
        text-transform: none;
        text-decoration: none;
        transition: color 0.2s ease, background 0.6s ease, border-color 0.2s ease;
        border: 1px solid $lightgrey;
        margin-bottom: 10px;
        &:hover {
            background: $light-yellow;
            color: $charcoal;
            border: 1px solid $light-yellow;
        }
    }
    .post-category {
        display: none;
    }
    .post-nextprev {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        .post-next,
        .post-prev {
            background: #f5f5f5;
            padding: 40px 30px;
            box-sizing: border-box;
            width: 100%;
            max-width: unset;
            a {
                text-decoration: none;
                color: $black;
                font-weight: 300;
                font-size: 16px;
            }
            span {
                font-size: 14px;
                text-decoration: none !important;
                color: rgb(51, 51, 51);
            }
        }
        .post-prev a {
            text-align: left;
        }
        .post-next a {
            text-align: right;
        }
    }
    .block.comments button {
        font-weight: $font-weight__light;
        font-family: $sans;
        font-size: 14px;
        text-transform: none;
        width: 100%;
        background: $light-yellow;
        color: $black;
        padding: 19px 30px;
    }
}
.blog-post-view ._post-description {
    display: flex;
    flex-direction: column;
}
.blog-post-view .post-tag {
    order: 1;
}
.blog-page:not(.blog-post-view) .post-description p {
    text-align: center;
}
@media only screen and (min-width: 1015px) {
    .blog-page.page-layout-2columns-right .column.main {
        width: 75%;
    }
    .blog-page.page-layout-2columns-right .sidebar {
        width: 25%;
    }
    .blog-page .toolbar .pages {
        margin-left: 0;
    }
}

.blog-menu > div > ul {
    display: flex;
    list-style: none;
    justify-content: center;
    gap: 4%;
    border-top: 1px solid $silver-lt;
    padding-top: 10px;
    margin-top: 10px;
    margin-bottom: 0;
}
.blog-menu li > a:hover {
    border-color: $grey-lt;
    text-decoration: none;
}
.blog-menu a {
    font-weight: 500;
    color: $color-black;
    font-size: .82353rem;
    padding: 5px;
    display: inline-block;
    border-bottom: 1px solid transparent;
}
body:not(.blog-index-index) .blog-menu.container {
    margin-bottom: 0px;
}
@media only screen and (max-width: $lap-start) {
    #html-body .blog-menu.container {
        display: none;
    }
}

@media only screen and (min-width: 769px) {
    .blog-page .page-wrapper {
        overflow: hidden;
    }
    .mfpid .special-price .price-wrapper.price-excluding-tax span {
        font-size: 30px;
    }
}
@media only screen and (max-width: 768px) {
    .mfpid .special-price .price-wrapper.price-excluding-tax span {
        font-size: 24px;
    }
}
.blog-block,
.sidebar.sidebar-additional .block-popular-posts {
    border: 1px solid $color-gray96;
    padding: 15px;
    margin-top: 15px;
}
.blog-block [data-content-type="text"] {
    background: $blog__block__color;
    text-align: center;
    padding: 15px 15px 0;
}
.sidebar.sidebar-additional .block-popular-posts .block-title {
    text-align: center;
    border-bottom: 1px solid $color-gray96;
    padding-bottom: 15px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: $font-weight__bold;
}
.sidebar.sidebar-additional .block-popular-posts .post-item-date,
.blog-post-view .post-data-wrap {
    display: none;
}
.sidebar.sidebar-additional .block-popular-posts .post-image {
    flex-shrink: 0;
    max-height: fit-content;
}
.sidebar.sidebar-additional .block-popular-posts .item {
    display: flex;
    margin-bottom: 15px;
}
.sidebar.sidebar-additional .block-popular-posts .post-item-link {
    font-size: 16px;
    font-weight: $font-weight__bold;
}
.blog-post-view .blog-header h1 {
    max-width: 550px;
    margin: 0 auto;
    padding-bottom: 0;
}
.blog-post-view .blog-header {
    padding-bottom: 70px;
}
.blog-post-view .sidebar.sidebar-additional .block-popular-posts {
    margin-top: 0;
}
.blog-post-view ._post-view .block.related .post-category {
    display: block;
}
.post-bottom .block.related {
    background: none;
    border: none;
}
.blog-post-view .post-bottom .mfblog-related-posts-block.block.related .block-title {
    margin-bottom: 15px;
}
.blog-page.blog-post-view .post-bottom .mfblog-related-posts-block.block.related .block-title,
.blog-page.blog-post-view .post-bottom .block.related .block-title #block-related-posts-heading {
    border-bottom: none;
}
.blog-post-view .post-bottom .block.related .block-title #block-related-posts-heading {
    padding-bottom: 0;
    font-weight: 300;
    font-size: 16px;
    color: $charcoal;
}
.post-bottom .mfblog-related-posts-block.block.related .post-list {
    margin-left: 0;
    margin-right: 0;
    gap: 25px;
}
.block.related .block-content {
    padding: 0;
}
.post-bottom .mfblog-related-posts-block.block.related .post-list .post-item {
    width: auto;
    max-width: unset;
    flex: 1;
    padding: 0;
}
.blog-post-view .block-info .post-category a {
    background: none;
    color: $charcoal;
    margin: 0;
    padding: 0;
    font-size: 12px;
}
.mfblog-related-posts-block.block.related .post-list .post-item .block-info {
    text-align: center;
}
.mfblog-related-posts-block a.post-item-name {
    font-size: 16px;
    color: $color-black;
    font-weight: 400;
    text-decoration: none;
}
.blog-post-view ._post-view .page-title a span {
    font-size: 31px;
}
@media only screen and (max-width: $lap-start) {
    .post-bottom .mfblog-related-posts-block.block.related .post-list {
        flex-direction: column;
    }
    .post-bottom .mfblog-related-posts-block.block.related .post-list .post-item {
        width: 100% !important;
    }
}
.mfpid .price-box .price-including-tax {
    display: none;
}
.mfpid .special-price .price-wrapper.price-excluding-tax span {
    color: $red;
}
.blog-page.page-layout-2columns-right .mfpwb {
    float: unset;
}

#post-comments h3 {
    font-weight: $font-weight__bold;
}
#post-comments .c-post .p-name {
    font-weight: normal;
    color: $charcoal;
}
.post-list .post-container .post-description {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.blog-page blockquote {
    &:before {
        background: url(../img/Quote-Mark-01.svg) no-repeat;
    }
    &:after {
        background: url(../img/Quote-Mark-02.svg) no-repeat;
    }
    &:after,
    &:before {
        content: '';
        background-size: 50px;
        display: block;
        width: 50px;
        height: 50px;
    }
    p {
        display: inline;
    }
}
.blog-post-view .post-sharing-bottom .share-elements .icon-wrapper {
    background: transparent !important;
}
.blog-post-view .post-sharing-bottom .share-elements .icon-wrapper .label {
    display: none;
}
.blog-post-view .post-bottom {
    position: relative;
    border-top: 1px solid;
    padding-top: 60px;
}
.post-view-modern .post-sharing-bottom {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 8px;
    border-bottom: none;
    right: 0;
    border-top: none;
}
.blog-menu ul {
    list-style: none;
    padding: 0;
}
.blog-menu ul li > ul {
    display: none;
    background: #BBEBD5;
    position: absolute;
    z-index: 999;
    padding: 0;
}
.blog-menu ul li > ul li {
    margin-bottom: 0;
    border: 1px solid rgba(255, 255, 255, .2);
    padding: 5px 10px;
}
.blog-menu ul li:hover > ul {
    display: block;
}
.blog-brand-menu:after {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    content: '';
    transform: rotate(45deg);
    margin-left: 10px;
    margin-bottom: 2px;
}
@media only screen and (min-width: 1200px) {
    .blog-page .column.main .product-items:not(.slick-slider):not(.flickity-enabled) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
.blog-page.blog-post-view .products-grid .product-item {
    width: 100%;
}
.blog-page .block.related .product-items {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
@media only screen and (max-width: 599px) {
    .blog-page .blog-post-view .products-grid .product-item {
        margin-left: 0;
    }
}
.blog-post-view .post-bottom .block.related .block-title,
.blog-post-view .post-bottom .block.related .block-title strong {
    border-bottom: none;
}
.blog-post-view ._post-view a.product-item-link {
    text-decoration: none;
}
.blog-page .widget.blog-search {
    float: unset;
}
.blog-post-view .product-items .product-item-actions {
    pointer-events: unset !important;
}
.blog-post-view .post-bottom {
    padding-top: 100px;
}
.blog-block [data-content-type="text"] {
    padding: 10px;
}
.blog-block p {
    margin: 0;
    padding: 0 30px;
    line-height: 20px;
    color: $color-black;
}
.blog-block a {
    font-size: 13px;
    line-height: 13px;
    color: $color-black;
}
@media only screen and (max-width: 766px) {
    .blog-index-index .post-list-wrapper > h3 {
        display: none;
    }
    body.blog-index-index .usps-container {
        margin-bottom: 0;
    }
    .blog-index-index .featured-posts li {
        margin-top: 0 !important;
    }

    //to override inline css
    .blog-page .more-link.btn.alt {
        letter-spacing: unset !important;
        font-size: 12px;
        padding: 12px 7px !important;
    }
    .blog-page .toolbar {
        padding: 10px 0;
    }
}
.blog-page.blog-post-view .post-bottom .block.related .block-title,
.blog-page.blog-post-view .post-bottom .block.related .block-title strong {
    border-bottom: none;
}
.blog-category-view .category-content {
    margin: 0 0 30px;
    border-top: 2px solid $color-black;
    border-bottom: 2px solid $color-black;
    padding: 30px 0;
    color: $color-black;
    font-size: 16px;
}
.blog-category-view .category-content p {
    margin: 0;
}
.block-tagclaud {
    margin-top: 30px;
}
.toolbar .pages-items .pages-item-next:hover .next,
.toolbar .pages-items .pages-item-previous:hover .previous {
    background: url('../svg/icon_right-hover.svg') 50% no-repeat;
}
