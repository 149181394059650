.orders-history,
.orders-recent,
.order-items,
.table-order-items,
.order-items-shipment {
    .table-caption {
        display: none;
    }

    .table-order-items {
        @include font-size(16px);
        margin: 0;

        @include media-query(max, $lap-start) {
            display: block;

            tbody,
            tfoot,
            tr,
            td {
                display: block;
            }

            thead {
                display: none;
            }

            tbody {
                tr {
                    padding-bottom: $half-spacing;
                    margin-bottom: $base-spacing;
                    border-bottom: 1px solid $silver-lt;
                }

                td[data-th] {
                    @include order-label;
                }
            }

            tfoot {
                padding-bottom: $base-spacing;
                border-bottom: 1px solid $silver-lt;

                tr {
                    display: flex;
                }
                td {
                    padding-left: $half-spacing;
                }
            }
        }
    }

    .col.actions {
        .action + .action {
            margin-left: 5px;
        }
    }
}

.order-items {
    .item-options {
        padding-top: ($half-spacing / 2);

        @include font-size(12px);
        dt {
            color: $black;
            &:after {
                content: ':';
            }
        }
    }
}
