.customer-account-forgotpassword,
.customer-account-create {
    .page-title-wrapper {
        @include container;
        max-width: 618px;
    }

    .column.main {
        max-width: 578px;
        margin: 0 auto;
    }
}

.form-create-account {
    &.pca-waiting {
        .fieldset.create.address {
            .field.zip ~ .field,
            ~ .fieldset {
                display: none;
            }
        }

        .actions-toolbar {
            display: none;
        }
    }
    .membership-waiting ~ .fieldset,
    .membership-waiting ~ .actions-toolbar {
        display: none;
    }
}

.fieldset--membership {
    color: $black;
    background-color: $dark-cream;
    padding: 15px;
    .heading p {
        font-weight: $font-weight__semibold;
        margin-bottom: 15px;
    }
    .benefits {
        display: flex;
        &:before {
            content: '';
            margin-right: 10px;
            height: 11px;
            width: 14px;
            min-width: 14px;
            display: block;
            margin-top: 7px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11' fill='none'%3E%3Cpath fill='%23CCAB82' d='m0 .407 4.965 5.704L6.875 0l1.91 6.111L13.75.407V11H0V.407Z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: contain;
        }
        > * {
            max-width: 435px;
            display: block;
        }
    }
    .field {
        margin-bottom: 0;
    }
    .control span {
        vertical-align: text-bottom;
    }
    input {
        width: 16px;
        height: 16px;
    }
    label {
        border-top: 1px solid $gold;
        padding-top: 7px;
    }
    .price {
        position: absolute;
        right: 0;
    }
}

.account-create__manual {
    border: none;
    text-decoration: underline;
    background: none;
    padding-left: 0;
    font-weight: bold;

    @include hover {
        background: none;
        text-decoration: underline;
    }
}

.fieldset--marketing-preferences {
    display: none;
    max-width: 35em;
    margin-bottom: $double-spacing;

    .fieldset {
        clear: both;

        .field {
            margin-bottom: 0;
            margin-left: $base-spacing;
            float: left;

            &:first-child {
                margin-left: 0;
            }

            label {
                margin: 0;
            }

            span {
                margin-right: 5px;
            }
        }
    }
}

.marketing-preferences-content {
    @include media-query (min, 360px) {
        @include clearfix;
    }

    .image {
        margin-bottom: $base-spacing;

        @include media-query (min, 360px) {
            width: 35%;
            float: left;
        }
        @include media-query (min, 500px) {
            margin-bottom: 0;
            width: 40%;
        }

        img {
            width: 100%;
        }
    }
    .title {
        @include media-query (min, 360px) {
            width: 65%;
            padding-top: $base-spacing;
            padding-left: $half-spacing;
            float: right;
        }
        @include media-query (min, 500px) {
            width: 60%;
            padding-left: $base-spacing;
            padding-top: $half-spacing;
        }
    }

    .content {
        clear: both;

        @include media-query (min, 500px) {
            width: 60%;
            padding-left: $base-spacing;
            float: right;
            clear: right;
        }
    }

    h3 {
        position: relative;
        left: 10px;
        @include font-size(30px);
        background: $charcoal;
        color: $white;
        padding: 0;
        line-height: 1.1;
        display: inline;
        word-wrap: break-word;
        box-shadow: 10px 0 0 $charcoal, -10px 0 0 $charcoal;

        @include media-query (min, 360px) {
            @include font-size(37px);
        }

        @include media-query (min, 400px) {
            @include font-size(42px);
        }

        @include media-query (min, 500px) {
            @include font-size(45px);
        }

        br {
            @include media-query (max, 360px) {
                display: none;
            }
        }
    }

    p {
        @include font-size($small-font-size);
        margin-top: $base-spacing;
        margin-bottom: $half-spacing;
    }

    ul {
        margin-bottom: $base-spacing;
    }
}

.field-marketing_preference_email {
    label {
        display: flex;
        input {
            margin: 0;
        }
    }
}

.field-marketing_preference_phone {
    label {
        display: flex;
        input {
            margin: 0;
        }
    }
}

.field-marketing_preference_post {
    label {
        display: flex;
        input {
            margin: 0;
        }
    }
}

.fieldset.create.info {
    .text-error{
        color: $failure;
        @include font-size(14px);
        margin-top: $base-spacing;
    }

    label {
        input {
            float: right;
            margin-right: 0;
            margin-left: $base-spacing;
            margin-bottom: $base-spacing;
        }
    }
}

.privacy-policy__info {
    p {
        @include font-size(14px);
        font-weight: 500;
        color: $charcoal;
    }
}

.marketing-customer-manage {
    .fieldset.marketing {
        span {
            margin-right: $half-spacing;
        }
    }
}

@include media-query(max, $lap-start) {
    .fieldset--membership {
        .pagebuilder-column-group {
            display: grid !important;
            grid-template-columns: 3fr 1fr;
        }
        .pagebuilder-column {
            width: 100% !important;
        }
    }
}
