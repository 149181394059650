/*========================================
DEFAULT MAGENTO TOOLTIP
========================================*/

.field-tooltip {
    position: relative;

    &._active {
        .field-tooltip-content {
            display: block;
        }
        .field-tooltip-action {
            background-color: $accent;
        }
    }
}

.field-tooltip-action {
    display: block;
    width: $base-spacing;
    height: $base-spacing;
    border-radius: 100%;
    background-color: $grey-lt;
    text-align: center;
    font-weight: 500;
    @include font-size(16px);
    line-height: $base-spacing;
    color: $white;

    &:before {
        content: 'i';
    }

    span {
        display: none;
    }

    @include hover {
        outline: none;
        cursor: pointer;
    }
}

.field-tooltip-content {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    width: 325px;
    max-width: 80vw;
    padding: $half-spacing;
    background-color: $silver-lt;
    border: 1px solid $silver;
    margin-top: $half-spacing;
    z-index: 1;

    span {
        display: block;
        width: 100%;
        @include font-size($small-font-size);
        color: $black;
        font-weight: 700;
    }
}
