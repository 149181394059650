//page class
.customer-account-login {
    .site-footer {
        margin-top: 0;
    }
}

.login-container {
    @include media-query(min, $lap-start) {
        display: flex;
    }

    .block {
        padding: $base-and-half-spacing;
        display: flex;
        flex-direction: column;

        @include media-query(min, $lap-start) {
            padding: $base-and-half-spacing;
            width: 50%;
        }

        @include media-query(min, $desk-start) {
            padding: $double-spacing * 2.5;
        }
    }

    .block-title {
        margin: 0 auto $base-and-half-spacing;
        max-width: 340px;
        width: 100%;
        color: $charcoal;
        line-height: 1;
        @include font-size($h4-font-size);
    }

    .block-content {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 340px;
        width: 100%;
        flex-grow: 1;

        .button {
            margin-top: auto;
        }
    }
}

.block-customer-login {
    border-bottom: 1px solid $silver-lt;

    @include media-query(min, $lap-start) {
        border-right: 1px solid $silver-lt;
        border-bottom: none;
    }

    form {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .fieldset.login {
        &:before {
            content: attr(data-hasrequired);
            display: block;
            letter-spacing: normal;
            word-spacing: normal;
            color: $red;
            text-align: right;
            @include font-size($small-font-size);
        }
    }
}

.form-login {
}

.form-login__remind {
    display: block;
    margin-bottom: $double-spacing;
    font-weight: 500;
    color: $charcoal;
    @include font-size($small-font-size);
}

.block-new-customer {
}

.new-customer__desc {
    margin-bottom: $base-and-half-spacing;
    @include font-size($small-font-size);
}

.new-customer__desc.warning {
    color: #db2a43;
}

.new-customer__list {
    margin-bottom: $base-and-half-spacing;
    list-style: none;
}

.new-customer__item {
    display: flex;
    margin-bottom: $base-spacing;
    font-weight: 500;
    color: $charcoal;
    @include font-size(16px);

    .svg-icon {
        display: block;
        position: relative;
        top: 2px;
        color: $charcoal;
        width: 1.2em;
        height: 1.2em;
        margin-right: $base-spacing;
        flex-shrink: 0;
    }
}
