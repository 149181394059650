.page-main .columns {
    @include container;
}

.column.main {
    width: 100%;
}

.page-layout-1column {
    .breadcrumbs {
        .items {
            @include container;
        }
    }

    .page-title-wrapper {
        @include container;
    }
}

.page-layout-2columns-left {
    .columns {
        @include media-query (min, $lap-start) {
            @include clearfix;
        }
    }

    .column.main {
        @include media-query (min, $lap-start) {
            width: 66.666%;
            float: right;
        }
        @include media-query (min, $desk-start) {
            width: 75%;
        }
    }

    .sidebar {
        @include media-query (min, $lap-start) {
            width: 33.333%;
            float: left;
            padding-right: $base-spacing;
        }
        @include media-query (min, $desk-start) {
            width: 25%;
        }
    }
}

.page-layout-2columns-right {
    .columns {
        @include media-query (min, $lap-start) {
            @include clearfix;
        }
    }

    .column.main {
        @include media-query (min, $lap-start) {
            width: 50%;
            float: left;
            padding-right: $base-spacing;
        }
        @include media-query (min, $desk-start) {
            width: 60%;
        }
    }

    .sidebar {
        @include media-query (min, $lap-start) {
            width: 50%;
            float: right;
        }
        @include media-query (min, $desk-start) {
            width: 40%;
        }
    }
}

// mobile layout

.page-wrapper {
    @include media-query(min, $lap-start) {
        display: block;
    }

    .site-top {
    }

    .site-header {
    }

    .site-nav {
    }

    .page-main {
        @include media-query(min, $lap-start) {
            padding-top: 0;
        }
    }

    .breadcrumbs {
        margin-bottom: $half-spacing;
        @include media-query(min, $lap-start) {
            margin-bottom: $base-spacing;
        }

        .items {
            display: flex;
            flex-wrap: wrap;
            @include font-size(14px);
            padding: 0;
        }

        .item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding-bottom: 0;

            strong {
                padding: 0;
            }

            a {
                padding: 0;
            }
        }

        // Product page apply min-height as it's loaded in via JS
        // to prevent CLS
        .catalog-product-view & {
            min-height: 22px;
        }
    }
}

.page-main {
    z-index: 1;
    position: relative;
}
