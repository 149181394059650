.checkout-payment-method {
    .step-title {
        display: none;
    }

    .no-quotes-block {
        padding-top: $base-spacing;
        margin-bottom: $base-spacing;
    }

    .payment-methods {
    }

    .payment-method {
        border-top: 1px solid $silver-lt;

        &._active {
            .payment-method-content {
                display: block;
            }
        }
    }

    .payment-method-title {
        padding: $base-spacing 0;
        margin: 0;
        align-items: center;

        label {
            flex-grow: 1;
            flex-wrap: wrap;

            img {
                max-height: 25px;
                width: auto;
            }

            span:nth-of-type(1),
            span:nth-of-type(2) {
                margin-right: 5px;
            }

            a {
                margin-left: auto;
                flex-grow: 0;
                flex-shrink: 0;

                &:before {
                    content: 'i';
                    display: block;
                    width: $base-spacing;
                    height: $base-spacing;
                    margin-left: 5px;
                    border-radius: 100%;
                    background-color: $grey-lt;
                    text-align: center;
                    font-weight: 500;
                    @include font-size(16px);
                    line-height: $base-spacing;
                    color: $white;
                }

                span {
                    display: none;
                }
            }
        }
    }

    .payment-method-braintree .label:before {
        content: '';
        background: url(../img/payment-cards.png) center no-repeat;
        height: 30px;
        width: 150px;
        background-size: contain;
    }

    .payment-method-content {
        display: none;
        padding-bottom: $base-spacing;
    }

    .payment-method-note {
        margin-bottom: $base-spacing;
        @include font-size(14px);
    }

    .payment-method-advice {
        @include font-size(14px);
        margin-bottom: $half-spacing;
    }

    .checkout-billing-address {
        fieldset {
            margin-bottom: $base-spacing;
        }
    }

    .billing-address-same-as-shipping-block {
        margin-bottom: $base-spacing;
    }

    .billing-address-details {
        margin-bottom: $base-spacing;
        color: $charcoal;
        @include font-size(14px);
    }

    .field-select-billing {
        .actions-toolbar {
            margin-bottom: $base-spacing;
        }
    }

    .action-edit-address,
    .action-cancel,
    .action-update {
        display: inline-block;
        line-height: 1;
        padding: $half-spacing;
        background-color: $silver-lt;
        border-color: $silver-lt;
        color: $charcoal;
        height: 44px;

        @include hover {
            color: $charcoal;
            border-color: darken($silver-lt, 5%);
            background-color: darken($silver-lt, 5%);
        }
    }

    .action-edit-address {
        display: block;
        margin-top: $half-spacing;
        margin-bottom: $base-spacing;
    }

    .payment-option {
        margin-bottom: $base-spacing;

        &:last-child {
            margin-bottom: 0;
        }

        &._collapsible {
            .payment-option-title {
                position: relative;
                cursor: pointer;
                outline: none;
                display: flex;
                align-items: center;

                &::after {
                    content: ' ';
                    width: 1em;
                    height: 1em;
                    background-image: url('../svg/icon_down.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    margin-left: auto;
                }
            }
        }

        &._active {
            .payment-option-title {
                &::after {
                    transform: scaleY(-1);
                }
            }
        }
    }

    .payment-option-title {
        @include font-size(16px);
        font-weight: 700;
        color: $charcoal;
        margin-bottom: 0;
    }

    .payment-option-content {
        display: block;
    }

    .form-discount {
        display: flex;
        align-items: flex-end;

        + .messages {
            margin-top: $half-spacing;
        }

        .payment-option-inner {
            flex-grow: 1;
        }

        .field {
            margin: 0;

            input {
                @include hover {
                    border-color: $silver-lt;
                }
            }
        }

        .action-apply {
            width: 86px; // copy yotpo
            height: 45px;
            padding:0;
            margin-left: 13px;

            @include hover {
                color: $charcoal;
                border-color: $charcoal;
                background-color: transparent;
            }
        }
    }

    .ccard {
        .group-2 {
            display: flex;
        }

        .month {
            width: 225px;
            margin-bottom: 0;

            .select {
                @include font-size(14px);
            }
        }

        .year {
            margin-left: $half-spacing;
            margin-bottom: 0;
            flex-grow: 1;

            .select {
                @include font-size(14px);
            }
        }

        .cvv .input-text {
            width: 104px;
            flex-grow: 0;
        }
    }

    .actions-toolbar {
        .primary .checkout {
            width: 100%;
        }
    }
}
