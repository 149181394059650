.cart-summary {
    .discount {
        margin-bottom: $base-spacing;
        @include media-query(min, $lap-start) {
            margin-bottom: $double-spacing;
        }

        .title {
            display: flex !important;
            justify-content: space-between;
            align-items: center;
            padding-right: 5px;
            margin-bottom: 5px;

            &:after {
                content: '';
                display: inline-block;
                width: $half-spacing;
                height: $half-spacing;
                background: url('#{$image-path}chevron-down.svg') no-repeat center;
                background-size: contain;
                margin-left: $half-spacing;
            }
        }

        &.active {
            .title:after {
                transform: rotate(180deg)
            }
        }

        .title {
            position: relative;
            color: $charcoal;
            outline: none;

            @include media-query(min, $lap-start) {
                display: none;
            }
        }

        .content {
            padding-top: $half-spacing;

            @include media-query(min, $lap-start) {
                padding-top: 0;
            }
        }

        .label {
            display: none;
        }

        .fieldset {
            display: flex;
        }

        .field {
            margin-bottom: 0;
            flex-grow: 1;
        }

        .actions-toolbar {
            flex-shrink: 0;
        }
    }

    .title.enabled {
        font-weight: bold;
    }

    .total-rules {
        display: none;
    }

    .summary.title {
        display: none;
    }

    .cart-summary__additional {


        .delivery-timer {
            padding: 0;
            background-color: $white;
        }

        .delivery-timer__text {
            @include font-size($base-font-size);
        }
    }

    .cart-totals {
        border: 1px solid $silver-lt;
        border-bottom: 0;
        padding: $base-and-half-spacing $base-and-half-spacing 0;

        .table-caption {
            display: none;
        }

        tr {
            &:nth-last-child(2) {
                td,
                th {
                    padding-bottom: $base-spacing;
                }
            }
        }

        th,td {
            padding-bottom: 0 0 $half-spacing;
        }

        th {
            font-weight: 100;
            color: $grey;
            @include font-size(16px);
            padding: $half-spacing 0;
        }

        td {
            font-weight: 700;
            text-align: right;
            color: $charcoal;
            @include font-size(16px);
            padding: $half-spacing 0;
        }

        .grand.totals {
            th,
            td {
                @include font-size($h4-font-size);
                color: $charcoal;
                border-top: 1px solid $silver-lt;
                padding-top: $base-spacing;

                strong {
                    font-weight: 700;
                }
            }
        }
    }

    .checkout-methods-items {
        list-style: none;
        margin: 0;
        border: 1px solid $silver-lt;
        border-top: 0;
        padding: 0 $base-and-half-spacing $half-spacing;

        .item {
            margin-bottom: $base-spacing;

            &:last-child {
                margin-bottom: 0;
            }
        }

        button {
            width: 100%;
        }

        .paypal {
            display: none;
        }
    }
}
