.social-links {
    display: flex;
    list-style: none;
    flex: 1 1 25%;
    margin-bottom: $base-spacing;

    @media (min-width: $lap-start) {
        margin-bottom: $double-spacing;
    }
}

.social-links__item {
    flex: 1 1 14.28%;
}

.social-links__link {
    color: $charcoal;

    @include hover {
        color: $primary;
    }

    .svg-icon {
        width: 32px;
        height: 32px;
    }
}

.svg-icon--lrg {
    width: 60px;
    height: 35px;
}
