/*========================================
Checkout: Deafult modal styling, do not change
========================================*/

@mixin lib-modal() {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-width: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s ease;

    &._show {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s ease;
    }

    .modal-inner-wrap {
        opacity: 1;
        pointer-events: auto;
        background-color: $white;
    }

    &._inner-scroll {
        .modal-inner-wrap {
            overflow-y: visible;
            display: flex;
            flex-direction: column;
        }

        .modal-header,
        .modal-footer {
            flex-grow: 0;
            flex-shrink: 0;
        }

        .modal-content {
            overflow-y: auto;
        }

        .modal-footer {
            margin-top: auto;
        }
    }

    .modal-header {
        position: relative;
        padding: $base-and-half-spacing $base-and-half-spacing $base-spacing;

        .action-close {
            position: absolute;
            top: $base-and-half-spacing;
            right: $base-spacing;
            background-color: transparent;
            background: url('../svg/icon_cross.svg') no-repeat center;
            border: none;
            text-indent: 9999px;
            width: $base-spacing;
            height: $base-spacing;

            span {
                display: none;
            }
        }
    }

    .modal-title {
        @include font-size(20px);
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: $half-spacing;
        border-bottom: 1px solid $silver-lt;
    }

    .modal-content {
        padding: 0 $base-and-half-spacing;

        @include hover() {
            outline: none;
        }
    }

    .modal-footer {
        padding: $base-and-half-spacing;

        .action.primary {
            @include button;
            margin-right: $base-spacing;
        }
        .action.secondary {
            @include button;
            margin-right: $base-spacing;
        }
    }
}

@mixin lib-modal-slide() {
    left: ($base-spacing * 2);
    z-index: 2000;

    &._show {
        .modal-inner-wrap {
            transform: translateX(0);
        }
    }

    .modal-inner-wrap {
        height: 100%;
        overflow-y: auto;
        position: static;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        width: auto;
    }
}

@mixin lib-modal-popup() {
    z-index: 2000;
    left: 0;
    overflow-y: auto;

    .modal-inner-wrap {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        width: 90%;
        max-width: $modal-max-width;
        display: flex;
        flex-direction: column;
        height: auto;
    }
}

.modal-slide {
    @include media-query(max, $lap-start) {
        @include lib-modal();
        @include lib-modal-slide();
    }
    @include media-query(min, $lap-start) {
        &.sagepaysuite-modal {
            @include lib-modal();
            @include lib-modal-popup();
        }
    }
}

.modal-popup {
    @include lib-modal();
    @include lib-modal-popup();

    &._inner-scroll {
        overflow-y: visible;

        .modal-inner-wrap {
            max-height: 90%;
        }
        .modal-content {
            overflow-y: auto;
        }
    }

    .modal-header,
    .modal-footer {
        flex-grow: 0;
        flex-shrink: 0;
    }

    .modal-footer {
        margin-top: auto;
    }

    .modal-footer-actions {
        text-align: right;
    }

    &.modal-slide {
        @include media-query(max, $lap-start) {
            @include lib-modal-slide();
            .modal-inner-wrap {
                margin: 0;
                max-height: 100%;
            }
        }
    }
}

.modal-dropdown {
    @include media-query(min, $lap-start) {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $white;
        min-width: 0;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.3s, opacity 0.3s ease;
        z-index: 1 !important;

        &._show {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.3s ease;
        }

        .action-close {
            position: absolute;
            top: $half-spacing;
            right: $base-spacing;
            @include button;
        }
    }
}

//
//  Overlay
//  _____________________________________________

body {
    &._has-modal,
    &._has-modal-custom {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
}

.modals-overlay {
    z-index: 100;
}

.modal-custom-overlay,
.modals-overlay {
    background-color: rgba($black, 0.2);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

body {
    &._has-modal-custom {
        .modal-custom-overlay {
            height: 100vh;
            left: 0;
            position: fixed;
            top: 0;
            width: 100vw;
            z-index: 2000;
        }
    }
}

.dropdown-overlay {
    @include media-query(min, $lap-start) {
        display: none;
    }
}
