.checkout-cart-index {
    .page-main {
        > .messages {
            padding: 0 $half-spacing;
            margin: $half-spacing auto;

            @include media-query(min, $lap-start) {
                padding: 0 $base-spacing;
            }
        }
    }

    //Checkout Messages
    .messages {
        width: 100%;
        padding: 0;
        margin: 0 auto $half-spacing;
    }

    .message {
        border: none !important;
        margin-top: 0  !important;
        margin-bottom: $half-spacing !important;
        padding: 0 0 0 20px;
        line-height: 1.6;

        &:before {
            left: 0!important;
        }
    }

    //Checkout Header
    .page-header {
        text-align: center;
        padding: $base-spacing 0;
        border-bottom: 1px solid $silver-lt;

        @include media-query(min, $lap-start) {
            padding: $base-and-half-spacing 0;
        }

        .site-header__logo {
            margin-bottom: 0;
            order: 2;

            .svg-icon {
                @include media-query(max, $lap-start) {
                    width: 120px;
                    height: 28px;
                }
            }
        }
    }

    .header.content {
        @include container;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .checkout-header-secure {
        order: 1;
        display: none;

        @include media-query(min, $lap-start) {
            display: block;
            width: 155px;
        }
    }

    .checkout-header-contact {
        order: 3;
        margin: 0;
        @include font-size($small-font-size);
        text-align: right;
        line-height: 1;

        @include media-query(min, $lap-start) {
            width: 155px;
            line-height: 1.5;
            text-align: center;
            @include font-size(16px);
        }

        strong {
            display: block;

            @include media-query(min, $lap-start) {
                @include font-size(22px);
            }
        }
    }

    //Checkout Page Title
    .page-title-wrapper {
        @include container;
    }

    .page-title {
        @include font-size($h4-font-size);
        color: $charcoal;
        padding-top: $base-spacing;
        margin-bottom: $base-spacing;

        @include media-query(min, $lap-start) {
            @include font-size(30px);
            margin-bottom: $double-spacing;
            padding-top: $base-and-half-spacing;
        }
    }

    //Checkout Free Gift
    .ampromo-items-add {
        margin-bottom: $base-and-half-spacing!important;
        float: left;
        width: 100%;

        @include media-query(min, $lap-start) {
            margin-bottom: $double-spacing!important;
        }
    }

    //Checkout Container
    .checkout-container {
        @include media-query(min, $desk-start) {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            margin-left: -$base-spacing;
            margin-right: -$base-spacing;
            margin-bottom: $base-and-half-spacing;
        }
    }

    //Checkout Estimated
    .opc-estimated-wrapper {
        display: none;
        //display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $base-spacing;
        padding-bottom: $base-spacing;
        border-bottom: 1px solid $silver-lt;

        @include media-query (min, $lap-start) {
            display: none;
        }

        .minicart-wrapper {
            position: static;
            height: auto;
            width: auto;
            opacity: 1;
            visibility: visible;
            transform: none;
            padding: 0;
        }
    }

    .opc-wrapper {
        @include media-query(min, $desk-start) {
            width: 66.6666%;
            position: relative;
            border-right: 1px solid $silver-lt;

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 50%;
                width: 1px;
                height: 100%;
                background-color: $silver-lt;
            }
        }

        .field {
            max-width: 100%;
        }

        .actions-toolbar .continue.primary {
            width: 100%;
        }
    }

    .opc-summary-wrapper {
        outline: none;
        @include media-query(min, $desk-start) {
            width: 33.3333%;
            padding: 0 $base-spacing;
        }

        .modal-header {
            display: none;
        }
    }

    .opc {
        list-style: none;
        margin: 0;
        @include clearfix;

        > li {
            position: relative;
            padding-bottom: $base-spacing;
            margin-bottom: $base-spacing;
            border-bottom: 1px solid $silver-lt;

            @include media-query (max, $desk-start) {
                .step-content {
                    display: none;
                }

                &.visible {
                    .step-content {
                        display: block;
                    }
                    .payment-step-title,
                    .step-title {
                        &:before {
                            content: '';
                            background: url("#{$image-path}dots_black.svg") no-repeat center $silver-lt;
                            background-size: 15px;
                        }
                    }
                }

                &.complete {
                    .payment-step-title,
                    .step-title {
                        &:before {
                            content: '';
                            background: url("#{$image-path}icon_tick_black.svg") no-repeat center $primary;
                            background-size: 15px;
                        }
                    }
                }
            }

            @include media-query (min, $desk-start) {
                width: 50%;
                padding: 0 $base-spacing $base-spacing;
                border-bottom: none;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: $white;
                    opacity: 0.5;
                    z-index: 3;
                }

                &.visible {
                    &:after {
                        display: none;
                        visibility: hidden;
                    }
                }
            }
        }

        > .checkout-shipping-address {
            .step-title {
                &:before {
                    content: '1';
                }
            }
            @include media-query(min, $desk-start) {
                float: left;
            }
        }

        > .checkout-shipping-method {
            .step-title {
                &:before {
                    content: '2';
                }
            }
            @include media-query(min, $desk-start) {
                float: right;
                border-bottom: 1px solid $silver-lt;
            }
        }

        > .checkout-payment-method {
            .payment-step-title {
                &:before {
                    content: '3';
                }
            }
            @include media-query(min, $desk-start) {
                float: right;
                clear: right;
            }
        }
    }

    .payment-step-title,
    .step-title {
        display: flex;
        align-items: center;
        @include font-size(16px);
        font-weight: 700;
        color: $charcoal;

        &:before {
            content: '';
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: $half-spacing;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            background-color: $silver-lt;

            @include media-query (min, $desk-start) {
                background-color: $primary;
            }

            span {
                display: block;
            }
        }
    }

    .opc-edit {
        position: absolute;
        top: 0;
        right: 0;
        padding: 3px 7px;
        @include font-size($small-font-size);
        display: none;

        @include media-query (max, $desk-start) {
            &.complete {
                display: block;
            }
        }

        @include media-query (min, $desk-start) {
            display: none;
        }
    }

    .step-content {
        padding-top: $base-spacing;
    }

    .checkout-billing-address .action-update {
        background-color: $primary;
    }
}
