// This file is a compiled output of vendor/amasty/gdpr-cookie/view/frontend/web/css/source/_module.less
body .amgdprcookie-cookie-container {
    margin: 0 10px 20px 0;
    padding: 0 0 25px
}
body .amgdprcookie-cookie-container.-modal:first-child {
    padding-top: 25px
}
body .amgdprcookie-cookie-container.-modal .amgdprcookie-header, body .amgdprcookie-cookie-container.-modal .amgdprcookie-text {
    padding-left: 25px
}
body .amgdprcookie-cookie-container.-modal {
    box-shadow: none;
    direction: ltr;
    margin-right: 30px
}
body .amgdprcookie-cookie-container .amgdprcookie-header {
    display: flex;
    justify-content: space-between
}
body .amgdprcookie-cookie-container .amgdprcookie-caption {
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    margin: 0 0 15px
}
body .amgdprcookie-cookie-container .amgdprcookie-text {
    color: #363636;
    font-size: 12px;
    margin-bottom: 10px
}
body .amgdprcookie-cookie-container .amgdprcookie-options {
    color: #5384ec;
    cursor: pointer;
    font-size: 12px
}
.amgdprcookie-toggle-cookie {
    padding-left: 50px;
    position: relative
}
.amgdprcookie-toggle-cookie .amgdprcookie-input {
    height: 0;
    opacity: 0;
    width: 0
}
.amgdprcookie-toggle-cookie .amgdprcookie-label {
    background: #a5a5a5;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .05);
    cursor: pointer;
    height: 29px;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px
}
.amgdprcookie-toggle-cookie .amgdprcookie-label:after {
    background: #fff;
    border-radius: 40px;
    content: '';
    height: 25px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: all .4s ease;
    width: 25px
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:active + .amgdprcookie-label, .amgdprcookie-toggle-cookie .amgdprcookie-input:focus + .amgdprcookie-label {
    box-shadow: 0 0 3px 1px #00699d
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:checked:disabled + .amgdprcookie-label {
    background: #e8e8e8
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:checked:disabled + .amgdprcookie-label:after {
    left: 95%;
    transform: translateX(-100%)
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:checked:not([disabled]) + .amgdprcookie-label {
    background: #1979c3
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:checked:not([disabled]) + .amgdprcookie-label:after {
    left: 95%;
    transform: translateX(-100%)
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:not([checked]) + .amgdprcookie-label {
    background: #a5a5a5
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:disabled + .amgdprcookie-label {
    background: #e8e8e8
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:disabled + .amgdprcookie-label:after {
    left: 2px
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:focus + .amgdprcookie-label {
    box-shadow: 0 0 3px 1px #00699d
}
.amgdprcookie-main-wrapper {
    color: #363636
}
.amgdprcookie-main-wrapper .amgdprcookie-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px
}
.amgdprcookie-main-wrapper .amgdprcookie-head {
    font-size: 20px;
    line-height: 20px
}
.amgdprcookie-main-wrapper .amgdprcookie-btn-wrapper {
    display: flex
}
.amgdprcookie-main-wrapper .amgdprcookie-btn {
    background: #ef7c4a;
    border: 0;
    border-radius: 6px;
    box-shadow: 0 3px 5px rgba(62, 40, 13, .15);
    color: #fff;
    font-size: 14px;
    padding: 7px 27px;
    text-transform: uppercase
}
.amgdprcookie-main-wrapper .amgdprcookie-btn:first-child {
    margin: 0 15px
}
.amgdprcookie-main-wrapper .amgdprcookie-btn:hover {
    background: #eb5a1b
}
.amgdprcookie-main-wrapper .amgdprcookie-btn:focus, .amgdprcookie-main-wrapper .amgdprcookie-btn:active {
    box-shadow: 0 0 3px 1px #00699d
}
body .amgdprcookie-cookie-container .amgdprcookie-link {
    color: #389dfe;
    cursor: pointer;
    float: right;
    font-size: 12px;
    letter-spacing: .3px;
    text-transform: capitalize
}
.amgdprjs-bar-template {
    display: block !important;
    position: fixed;
    position: sticky
}
.amgdprjs-bar-template .amgdprcookie-buttons-block .amgdprcookie-button {
    margin-right: 5px
}
.amgdprjs-bar-template.-popup {
    left: 0;
    margin: 0 auto;
    width: 100%;
    position: fixed;
    top: 0;
    height: auto;
    background: #0000004f;
}
.amgdprjs-bar-template.-popup:before {
    background-color: #0a6c9f;
    content: '';
    height: 100%;
    width: 100%;
}
.amgdprjs-bar-template.-popup .amgdprcookie-bar-container {
    padding: 50px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: fixed;
}
.amgdprjs-bar-template.-popup .amgdprcookie-bar-block {
    width: 100%
}
.amgdprjs-bar-template .amgdprcookie-buttons-block {
    display: flex;
    justify-content: center
}
.amgdprcookie-groups-modal .modal-inner-wrap {
    border-radius: 4px;
    box-shadow: 0 -2px 18px rgba(0, 0, 0, .06);
    max-width: 740px
}
.amgdprcookie-groups-modal.-table .modal-inner-wrap {
    max-width: 1290px;
    min-width: 700px;
    overflow-x: auto
}
.amgdprcookie-groups-modal .modal-title {
    border-bottom: none;
    font-size: 20px;
    font-weight: 700
}
.amgdprcookie-groups-modal .action-close:focus {
    box-shadow: 0 0 3px 1px #00699d
}
.amgdprcookie-groups-modal .amgdprcookie-form {
    padding: 0 10px
}
.amgdprcookie-groups-modal .amgdprcookie-done {
    background-color: #5384ec;
    border-radius: 3px;
    box-shadow: none;
    color: #fff;
    font-weight: 700;
    letter-spacing: 1.35px;
    text-transform: uppercase;
    transition: background-color .3s ease-out;
    width: 100%
}
.amgdprcookie-groups-modal .amgdprcookie-done:hover {
    background-color: #1357e6
}
.amgdprcookie-groups-modal .amgdprcookie-done:focus {
    background-color: #1357e6;
    box-shadow: 0 0 3px 1px #00699d
}
.amgdprcookie-groups-modal:not(.-table) .modal-footer {
    padding-top: 0
}
.amgdprcookie-groups-modal .amgdprcookie-form-container, .amgdprcookie-groups-modal .amgdprcookie-tbody {
    overflow: auto;
    scrollbar-color: #e0e0e0 #fff;
    scrollbar-width: thin;
    scrollbar-face-color: #e0e0e0;
    scrollbar-highlight-color: #e0e0e0;
    scrollbar-shadow-color: #e0e0e0;
    scrollbar-track-color: #fff;
    display: block;
    max-height: 500px;
    overflow-y: auto
}
.amgdprcookie-groups-modal .amgdprcookie-form-container::-webkit-scrollbar, .amgdprcookie-groups-modal .amgdprcookie-tbody::-webkit-scrollbar {
    width: 6px
}
.amgdprcookie-groups-modal .amgdprcookie-form-container::-webkit-scrollbar-track, .amgdprcookie-groups-modal .amgdprcookie-tbody::-webkit-scrollbar-track {
    background-color: #fff;
    box-shadow: none
}
.amgdprcookie-groups-modal .amgdprcookie-form-container::-webkit-scrollbar-thumb, .amgdprcookie-groups-modal .amgdprcookie-tbody::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #e0e0e0
}
.amgdprcookie-bar-container {
    background: #bbebd5;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 30px 10px;
    z-index: 10;
}
.amgdprcookie-bar-container.modal-popup {
    bottom: unset;
    left: unset;
    overflow-y: unset;
    pointer-events: auto;
    position: unset;
    right: unset;
    top: unset;
    transition: unset;
}
.amgdprcookie-bar-container .amgdprcookie-buttons-block {
    display: flex;
    flex-direction: column
}
.amgdprcookie-bar-container .amgdprcookie-policy {
    margin-bottom: 20px;
    max-height: 30vh;
    max-width: 640px;
    overflow-y: auto
}
.amgdprcookie-bar-container .amgdprcookie-button:first-child {
    margin-bottom: 10px
}
.amgdprcookie-bar-container .amgdprcookie-buttons-block .amgdprcookie-button {
    margin-bottom: 0;
    padding: 10px 30px
}
.amgdprcookie-bar-container .action-close {
    line-height: 14px;
    right: 10px;
    top: 10px;
    display: none;
}
.amgdprcookie-bar-container .action-close:focus, .amgdprcookie-bar-container .action-close:active {
    box-shadow: 0 0 3px 1px #00699d;
    opacity: .9
}
.amgdprcookie-buttons-block {
    display: flex;
    flex-direction: column
}
.amgdprcookie-buttons-block.-settings {
    padding-bottom: 30px
}
.amgdprcookie-buttons-block .amgdprcookie-button {
    background: #fff;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    color: #000000;
    font-weight: 700;
    letter-spacing: 1.35px;
    margin-top: 15px;
    padding: 10px 15px;
    text-transform: uppercase;
    transition: opacity .3s ease, color .3s ease-out, background-color .3s ease-out, border .3s ease-out
}
.amgdprcookie-buttons-block .amgdprcookie-button:hover {
    opacity: .9
}
.amgdprcookie-buttons-block .amgdprcookie-button:active, .amgdprcookie-buttons-block .amgdprcookie-button:focus {
    box-shadow: 0 0 3px 1px #00699d;
    opacity: .9
}
.amgdprcookie-buttons-block .amgdprcookie-button.-allow, .amgdprcookie-buttons-block .amgdprcookie-button.-save {
    background: #000000;
    color: #fff;
    flex-basis: 33%
}
.amgdprcookie-modal-template {
    background: #fff;
    margin-left: 5px;
    overflow-y: auto
}
.amgdprcookie-modal-template .action-close {
    background: #fff;
    line-height: 14px;
    margin: 0;
    padding: 8px;
    right: 0;
    top: 0;
    z-index: 1
}
.amgdprcookie-modal-template .action-close:focus, .amgdprcookie-modal-template .action-close:active {
    background: #fff;
    box-shadow: 0 0 3px 1px #00699d;
    opacity: .9
}
.amgdprcookie-modal-template .action-close:hover {
    background: #fff
}
.amgdprcookie-modal-template .amgdprcookie-button.-allow:not(.-save) {
    background: inherit;
    color: #5384ec;
    margin-bottom: 0
}
.amgdprcookie-modal-container .modal-content {
    padding: 0
}
.amgdprcookie-groups-container {
    box-shadow: 0 -2px 18px rgba(0, 0, 0, .06);
    direction: rtl;
    height: auto;
    overflow-y: scroll
}
.amgdprcookie-policy-container {
    bottom: 0;
    box-shadow: 0 -2px 18px rgba(0, 0, 0, .06);
    padding: 24px;
    position: absolute
}
.amgdprcookie-text-container .amgdprcookie-policy {
    display: inline
}
.amgdprcookie-modal-container.modal-popup.modal-slide {
    left: 0;
    pointer-events: none
}
.amgdprcookie-modal-container .modal-inner-wrap {
    height: 100%;
    margin: 0;
    position: relative
}
.amgdprcookie-modal-container .modal-inner-wrap .modal-header {
    position: absolute;
    right: 0
}
.amgdprcookie-modal-container .modal-inner-wrap .action-close {
    margin: 5px;
    padding: 0
}
.amgdprcookie-modal-container .modal-inner-wrap .action-close:before {
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iOC41IiBjeT0iOC41IiByPSI4IiBzdHJva2U9IiNDQUNBQ0EiLz48cGF0aCBkPSJNNS4yNSA1LjI1bDYuNSA2LjVtMC02LjVsLTYuNSA2LjUiIHN0cm9rZT0iI0NBQ0FDQSIvPjwvc3ZnPg==') no-repeat center;
    content: '';
    height: 25px;
    width: 25px
}
.amgdprcookie-modal-container .modal-inner-wrap .action-close:focus {
    box-shadow: 0 0 3px 1px #00699d
}
.amgdprcookie-modal-container .modal-inner-wrap .amgdprcookie-groups-container {
    overflow: auto;
    scrollbar-color: #e8e8e8 #fff;
    scrollbar-width: thin;
    scrollbar-face-color: #e8e8e8;
    scrollbar-highlight-color: #e8e8e8;
    scrollbar-shadow-color: #e8e8e8;
    scrollbar-track-color: #fff
}
.amgdprcookie-modal-container .modal-inner-wrap .amgdprcookie-groups-container::-webkit-scrollbar {
    width: 4px
}
.amgdprcookie-modal-container .modal-inner-wrap .amgdprcookie-groups-container::-webkit-scrollbar-track {
    background-color: #fff;
    box-shadow: none
}
.amgdprcookie-modal-container .modal-inner-wrap .amgdprcookie-groups-container::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #e8e8e8
}
.amgdprcookie-settings-form .amgdprcookie-toggle-cookie .amgdprcookie-input.disabled + .amgdprcookie-label {
    background: #e8e8e8
}
.amgdprcookie-groups-modal .amgdprcookie-table .amgdprcookie-row, .amgdprcookie-groups-modal .amgdprcookie-table thead tr, .amgdprcookie-groups-modal .amgdprcookie-table thead {
    display: table;
    table-layout: fixed;
    width: 100%
}
.amgdprcookie-groups-modal .amgdprcookie-cell.-name {
    word-wrap: break-word
}
.amgdprcookie-modal-container .amgdprcookie-modal-template {
    height: 100%;
    margin-left: 0;
    min-width: 400px;
    pointer-events: auto;
    position: relative;
    width: 20%;
    z-index: 900
}
body.cookie-dialog-closed .-popup {
    display: none !important;
}
