.dpd {
    display: none;

    @include media-query(min, $desk-start) {
        display: block;
        padding: ($double-spacing + $base-spacing) 0;
        margin-top: ($double-spacing + $base-and-half-spacing);
        background: $mercury;
    }
}

.dpd__container {
    display: flex;
    align-items: flex-start;
}

.dpd__image {
    padding: ($double-spacing + $half-spacing) $double-spacing;
    text-align: center;
    background-color: $white;
    width: 280px;
    flex-shrink: 0;
}

.dpd__image-logo {
    display: block;
    width: 100%;
    max-width: 160px;
    margin: 0 auto;
}

.dpd__image-title {
    @include font-size($h4-font-size);
}

.dpd__image-desc {
    margin-bottom: 0;
    @include font-size(16px);
}

.dpd__info {
    flex-grow: 1;
    padding: 0 ($double-spacing + 15px);

    li {
        position: relative;
        list-style: none;
        margin-left: $base-spacing;
        margin-bottom: $base-spacing;
        @include font-size(16px);
        font-weight: 300;
        color: rgba($charcoal, 0.7);

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            content: '';
            position: absolute;
            left: -($base-and-half-spacing);
            top: 5px;
            width: ($half-spacing + 5px);
            height: ($half-spacing + 5px);
            background: url(../img/icon_tick_red.svg) center no-repeat;
        }
    }
}

.dpd__contact {
    flex-grow: 1;
    padding-left: $double-spacing;
    border-left: 1px solid $silver-lt;

    .h4 {
        margin-bottom: $half-spacing;
        padding: 0;
    }

    a {
        color: $charcoal;
        @include font-size($h2-font-size);
        font-weight: 700;
        margin-bottom: $half-spacing;
    }

    p {
        @include font-size(16px);
        font-weight: 300;
        color: rgba($charcoal, 0.7);
    }
    ul {
    }
    li {
        @include font-size(16px);
        font-weight: 300;
        color: rgba($charcoal, 0.7);
    }
}
