$b3: #b3b3b3;
$color-pagebuilder-darkest-gray: #1c1918;

//
//  Slick slider styles
//  _____________________________________________

.slick-slider {
    box-sizing: border-box;
    display: block;
    position: relative;
    touch-action: pan-y;
    user-select: none;
}
.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    [dir='rtl'] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        border: 1px solid transparent;
        display: block;
        height: auto;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev {
    left: 2rem;
    &:before {
        content: $icon-pagebuilder-caret-prev__content;
        padding-right: 5px;
    }
}

.slick-next {
    right: 2rem;
    &:before {
        content: $icon-pagebuilder-caret-next__content;
        padding-left: 5px;
    }
}

.slick-prev,
.slick-next {
    background: rgba(252, 252, 252, 0.6);
    border: none;
    border-radius: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 60px;
    line-height: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 60px;
    z-index: 101;

    &.slick-disabled {
        opacity: 0.2;
    }

    &:hover {
        background: rgba(252, 252, 252, 1);
        border: none;
        &.slick-disabled {
            opacity: 0.2;
        }
        &:before {
            color: $color-gray-darken4;
        }
    }

    &:before,
    &:after {
        @extend .pagebuilder-icon;
        color: $color-gray-darken3;
        font-size: 38px;
        opacity: 1;
    }

    &:active,
    &:focus,
    &:not(.primary) {
        background: rgba(252, 252, 252, 0.7);
        border: none;
        box-shadow: none;

        &:active {
            box-shadow: none;
        }
    }
}

.slick-dots {
    @include lib-inline-block-space-item();
    display: flex;
    list-style: none;
    margin: 0;
    padding: 15px 0;
    text-align: center;
    width: 100%;
    li {
        cursor: pointer;
        display: inline-block;
        height: 14px;
        margin: 0 5px;
        padding: 0;
        position: relative;
        vertical-align: middle;
        width: 14px;
        button {
            background: $color-white;
            border: 1px solid $color-gray80;
            border-radius: 10px;
            box-shadow: none;
            cursor: pointer;
            display: block;
            height: 14px;
            padding: 0;
            text-indent: -99999px;
            transition: 0.3s;
            width: 14px;

            &:hover {
                border: 1px solid $b3;
            }

            &:active,
            &:focus,
            &:not(.primary) {
                box-shadow: none;
            }
        }

        &.slick-active {
            button {
                background: $color-pagebuilder-darkest-gray;
            }
        }
    }
}
