.cms-page-view {
    .breadcrumbs {
        @include container;

        @include media-query(min, $lap-start) {
            padding-top: $base-spacing;
        }
    }

    #maincontent {
        h1 {
            @include font-size(24px);
            font-weight: 400;
        }

        h2 {
            @include font-size(24px);
            font-weight: 400;
        }

        ul {
            margin: 0;
            list-style-type: square;
            margin-bottom: $base-spacing;

            &:first-of-type {
                columns: 200px 2;
            }

            li {
                margin-left: $base-spacing;
                @include font-size(16px);

                a {
                    text-decoration: none;
                    @include hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        p {
            @include font-size(16px);
            font-weight: 300;
        }
    }
}
