.offer-heading-one {
    color: $red;
    font-weight: 300;

    p {
        margin-bottom: $half-spacing;
    }
}

.offer-heading-two,
.offer-heading-three {
    color: $charcoal;
    font-weight: 500;

    p {
        margin-bottom: 0;
    }
}

.product-offer-heading {
    color: $red;
    font-weight: 700;
    @include font-size($small-font-size);

    p {
        margin-bottom: $half-spacing;
    }
}
