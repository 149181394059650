.product-sticker {
    background-color: $failure;
    margin-bottom: $half-spacing/2;
    padding: 4px $half-spacing 2px;
    color: $white;
    @include font-size(9px);
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 700;

    &--blank {
        display: none;
    }

    &--offer,
    &--multibuy {
        background-color: rgb(255, 107, 107);
    }

    &--vegan {
        background-color: rgb(97, 194, 80);
    }

    &--bestseller {
        background-color: rgb(99, 177, 229);
    }

    &--clearance {
        background-color: rgb(205, 32, 44);
    }
}

.product-sticker-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    @include media-query(min, $lap-start) {
        .product.media & {
            left: $double-spacing;
        }
    }
}
