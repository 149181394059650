.site-top {
    position: relative;
    z-index: 2;
    background-color: $charcoal;

    @include media-query(max, $lap-start) {
        position: fixed;
        width: 100%;
    }
}

.site-top__container {
    @include media-query(min, $lap-start) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.site-top__cvp {
    position: relative;
    padding: ($half-spacing / 2) 0;

    @include media-query(max, 400px) {
        height: 32px;
    }

    @include media-query(min, 950px) {
        display: flex;
        align-items: center;
    }
}
@keyframes fadeCvp {
    0% {
        opacity: 0;
        z-index: 0;
    }
    5% {
        opacity: 1;
        z-index: 1;
    }
    50% {
        opacity: 1;
        z-index: 1;
    }
    55% {
        opacity: 0;
        z-index: 0;
    }
    100% {
        opacity: 0;
    }
}
@keyframes fadeCvpMob {
    0% {
        opacity: 0;
    }
    3% {
        opacity: 1;
        z-index: 1;
    }
    33% {
        opacity: 1;
        z-index: 1;
    }
    37% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.site-top__cvp-item {
    display: block;
    color: $white;
    text-align: center;
    font-size: $small-font-size;

    @include media-query(min, 400px) {
        display: inline-block;
        text-align: left;
    }

    &:first-child {
        color: $accent;
        font-weight: bold;

        @include media-query(max, 400px) {
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: $half-spacing;
            right: $half-spacing;
            text-align: center;
        }
    }

    &:nth-child(2),
    &:nth-child(3) {
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: $half-spacing;
        right: $half-spacing;
        text-align: center;

        @include media-query(min, 400px) {
            width: 280px;
            right: 0;
            left: auto;
            text-align: right;
        }

        @include media-query-bracket($lap-start, 950px) {
            right: -$base-spacing;
            transform: translateY(-50%) translateX(100%);
            text-align: left;
        }

        @include media-query(min, 950px) {
            position: static;
            text-align: left;
            opacity: 1;
            transform: none;
            width: auto;
        }
    }

    //animations
    @include media-query(max, 400px) {
        &:first-child {
            animation: fadeCvpMob 9s infinite;
        }
        &:nth-child(2) {
            animation: fadeCvpMob 9s infinite;
            animation-delay: 3s;
        }

        &:last-child {
            animation: fadeCvpMob 9s infinite;
            animation-delay: 6s;
        }
    }

    @include media-query-bracket(400px, 950px) {
        &:nth-child(2) {
            animation: fadeCvp 9s infinite;
        }

        &:last-child {
            animation: fadeCvp 9s infinite;
            animation-delay: 4s;
        }
    }

    @include media-query(min, 950px) {
        padding: 0 $base-spacing;
        border-right: 1px solid $white;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
            border: none;
        }
    }
}

.site-top__links {
    display: none;

    @include media-query(min, $lap-start) {
        display: flex;
        list-style: none;
        margin: 0;
    }

    .contact-popup {
        order: -1;
    }

    .authorization-link {
        order: 99999;
    }

    > li {
        display: flex;

        > a {
            display: flex;
            align-items: center;
            padding: $half-spacing;
            border-right: 1px solid $grey;
            font-weight: 500;
            line-height: 1.1;
            text-decoration: none;
            color: $white;
            font-size: $micro-font-size;
            text-align: center;

            @include media-query(min, 1050px) {
                font-size: $small-font-size;
                padding: $half-spacing $base-spacing;
            }

            .svg-icon {
                display: block;
                margin-right: $half-spacing;
                width: 1.2em;
                height: 1.2em;
                color: $white;
            }
        }
    }
}

@include media-query(max, $lap-start) {
    .site-top {
        display: none;
    }
}
