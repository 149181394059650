.footer-newsletter {
    text-align: left;
    padding-top: $base-spacing;
    margin-bottom: $base-spacing;
    [data-content-type="html"] {
        display: flex;
        flex: 1 1 12%;
    }
    .social-links {
        margin-right: 60px;
    }
    .action.subscribe {
        color: $footer-font-color;
        white-space: nowrap;
        border-left: none;
        max-height: 43px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13.5px;
    }
    input {
        height: 43px;
    }

    @include media-query(min, $wide-start) {
        display: flex;
        align-items: baseline;
        padding-top: $double-spacing;
        margin-bottom: $double-spacing;
        border-bottom: 1px solid $footer-font-color;
    }
}
.footer-newsletter input,
.footer-newsletter__button {
    background-color: transparent;
    border-color: $footer-font-color;
}

.footer-newsletter__form {
    @include media-query(min, 620px) {
        flex: 1 1 55%;
        margin-right: 0;
    }
}

.footer-newsletter__field {
    @include media-query(min, 620px) {
        display: flex;
        margin-bottom: $base-spacing;
        max-width: 100%;
    }

    @include media-query(min, $lap-start) {
        margin-bottom: $double-spacing;
    }
}

.footer-newsletter__label {
    font-size: 16px;
    font-weight: 200;
    @include sentence-case();
    margin-bottom: $base-spacing;

    @include media-query(min, 620px) {
        text-align: left;
        margin-bottom: 0;
    }
    margin-right: $half-spacing;
    @include media-query(min, $lap-start) {
        margin-right: $double-spacing;
    }
}

.footer-newsletter__button {
    flex-grow: 1;
    max-height: 42px;
}

.footer-newsletter__control {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;

    @include media-query(min, 620px) {
        flex-direction: row;
        position: relative;
        top: 4px;
    }

    .mage-error {
        margin: 0 $half-spacing;
        @include media-query(min, $lap-start) {
            align-self: flex-start;
        }
    }

    input {
        text-align: center;
        flex-grow: 2;
        font-size: $small-font-size;

        &::placeholder {
            color: $footer-font-color;
            font-weight: 300;
            font-size: 16px;
        }

        @include media-query(min, 620px) {
            text-align: left;
        }
    }
}

.footer-newsletter__social {
    @include media-query(min, 620px) {
        position: relative;
        top: 10px;
        margin-left: 0;
    }

    @include media-query(min, $wide-start) {
        margin-left: ($base-spacing * 3);
    }
}
