// bold
.tile__title,
.category-header__title,
.product-item-link,
.product-info-main .page-title,
.checkout-cart-index .page-title,
.checkout-index-index .page-title,
.account .page-title,
.customer-account-create .page-title,
.ordercatalogue-index-index .page-title,
.block-title,
:is(h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6,button,.button),
.product-add-form .product-options-bottom .field.qty .label,
.minicart-wrapper .product-item-name > *,
li.level1 > a,
.data.item.title .data.switch,
.filter-options-title,
.discover-products .pagebuilder-button-primary,
.usp__highlight {
    font-family: $hn-font-family;
    font-weight: $font-weight__bold; // 700
}
// Medium
.site-nav .level0 > a.level-top,
.sub-nav__heading,
.usp__tag,
.product-info-stock-sku,
li.level2 > a,
input#search,
.site-top__links > li > a,
.filter-options-content .item a label,
.filter-options-content .item a {
    font-family: $hn-font-family;
    font-weight: $font-weight__heavier; // 500
}
[id="product.info.details.delivery"] h4 {
    font-size: 14px;
}
.data.item.title .data.switch,
.product-add-form .product-options-bottom .field.qty .label,
.discover-products .pagebuilder-button-primary {
    text-transform: uppercase;
}
.cms-index-index .pagebuilder-transparent .pagebuilder-button-primary:after {
    vertical-align: bottom;
}
