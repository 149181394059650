.additional-product-grids {
    @include container;
    clear: both;
}
.block-emarsys,
.block.upsell,
.block.crosssell,
.block.related,
.block-viewed-products-grid {
    margin-bottom: $base-spacing;
    @include media-query(min, $palm-start) {
        border: 1px solid $silver-lt;
        margin-bottom: ($base-spacing * 2);
    }
    .block-title {
        padding: $base-spacing 0;
        text-align: center;
        strong {
            font-weight: 700;
        }
    }
    .block-content {
        overflow: hidden;
        @include media-query(min, $palm-start) {
            padding: 0 $base-spacing;
        }
        @include media-query(max, $palm-start) {
            padding: 0
        }
    }
    .products-grid {
        padding: 0;
    }
    .product-item {
        text-align: center;
        margin-bottom: $base-spacing;
    }
}
.block-viewed-products-grid {
    .product-item-inner {
        display: none;
    }
}
//inside popup
.added-to-basket {
    .block-emarsys {
        border-left: none;
        border-right: none;
        border-bottom: none;
        margin: 0;
    }
    .block-content {
        padding: 0;
    }
    .product-item {
        @include media-query(min, $desk-start) {
            width: 25%;
        }
    }
}
.products-grid.wishlist {
    .product-item-photo {
        margin-bottom: $half-spacing;
    }
}
.checkout-cart-index {
    .block.crosssell {
        .product-item {
            @include media-query(min, $desk-start) {
                width: 100%;
            }
        }
    }
}
.products-crosssell {
    .product-item {
        width: 100%;
    }
    .product-item-inner-info {
        margin-bottom: ($half-spacing - 3px);
    }
}
