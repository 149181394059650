/*========================================
Paths
========================================*/
$font-path: '../fonts';
$image-path: '../img/';
$svg-path: '../svg/';

/*========================================
Colour
========================================*/
//Shades
$black: #0c0c0c;
$charcoal: #333333;
$grey: #6a6a6a;
$lightgrey: lightgrey;
$grey-lt: #999999;
$silver: #c5c5c5;
$silver-lt: #e6e6e6;
$mercury: #f4f4f4;
$smoke: #fafafa;
$red: #db2a43;
$beige: #ccab82;
$light-grey: #ece9e6;
$light-yellow: #F9FFDB;
$gold: #CCAB82;
$light-gold: #F9F6F1;

//#C0AF91
$cream: #fefbf6;
$white: #ffffff;

//Brand
$primary: #c7b1e6;
$secondary: #333333;
$accent: #F4F4F4;
$tertiary: $accent;
$secondary-accent: $accent;
$light: #FFF5F1;
$blue: #07ABF7;

//Special
$link: $secondary;
$link-hover: darken($secondary, 15%);
$alert: #e26703;
$failure: #df280a;
$success: #11b400;
$dark-cream: #F9F6F2;
$gold: #CCAB82;

// other
$tropical-violet: #C7B1E6;

/*========================================
Typography
========================================*/

// Font stacks
$sans: termina, sans-serif;
$serif: outfit, serif;

// Base
$base-font-size: 17px;
$base-line-height: 1.6;
$line-height-ratio: (round(($base-line-height / $base-font-size) * 100)) / 100;
$base-font-family: $serif;
$base-color: $grey;
$uppercase-spacing: 0.05em;

// Headings
$hn-font-weight: 700;
$hn-line-height: 1.3;
$hn-font-family: $sans;
$hn-color: $charcoal;

$h1-font-size: 43px;
$h2-font-size: 36px;
$h3-font-size: 29px;
$h4-font-size: 22px;
$h5-font-size: 21px;

// Special
$large-size: 50px;
$lede-font-size: 18px;
$small-font-size: 14px;
$micro-font-size: 12px;

/*========================================
Layout
========================================*/

// Dimensions
$border-box: true;
$base-spacing: 20px;
$double-spacing: ceil($base-spacing * 2);
$half-spacing: ceil($base-spacing / 2);
$base-and-half-spacing: ceil($base-spacing + $half-spacing);
$base-radius: 5px;
//$half-spacing-and-base-radius: ceil($half-spacing + 5px);
//$base-and-base-radius: ceil($base-spacing + 5px);
$half-radius: ceil($base-radius / 2);
$input-height: 47px;

// Grids
$page-width: 1220px;
$palm-start: 600px;
$lap-start: 765px;
$desk-start: 1015px;
$wide-start: 1200px;

$modal-max-width: 540px;

$breakpoints: (
    'palm' 0 $lap-start 4% (3, 4),
    'lap' $lap-start $desk-start 4% (3, 4, 6, 8),
    'desk' $desk-start $wide-start 2% (6, 8, 10, 12),
    'wide' $wide-start 0 2% (6, 8, 10, 12)
);

/*========================================
Element Heights
========================================*/
$control-height: 70px;
$topbar-height: 28px;
$button-height: 44px;

$carousel-button-size: 40px;
$carousel-dot-size: 12px;

/*========================================
USP
========================================*/
$usp-background-color: $tropical-violet;

/*========================================
Footer
========================================*/
$border: 1px solid fade-out($white, 0.75);
$border-color: fade-out($white, 0.75);
$footer-background-color: $tropical-violet;
$footer-font-color: $black;
$trade-font-color: $black;

/*========================================
Header
========================================*/
$border-color: $silver-lt;
$header-font-color: $black;
$header-background-color: $white;

// Buttons
$button-primary__background: $primary;
$button-primary__hover__background: darken($button-primary__background, 10%);

$usp--font-size--mobile: 10px;
$usp--font-size--desktop: 12px;
