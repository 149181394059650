.mobile-navigation {
    margin-bottom: $half-spacing;
    @include media-query(min, $lap-start) {
        display: none;
    }
}

.mobile-navigation__item {
    display: block;
    margin-bottom: $half-spacing;
}

.mobile-navigation__img {
    width: 100%;
}

.mobile-navigation-view-only {
    display: block;
    @include media-query(min, $lap-start) {
        display: none;
    }
}
