.checkout-back-to-basket {
    .button {
        margin-bottom: $base-spacing;
        width: 100%;
        @include media-query(min, $desk-start) {
            width: 33%;
        }
    }
}

.checkout-shipping-address {
    @include media-query(min, $desk-start) {
        margin-bottom: 0;
        padding-bottom: $double-spacing;
        border-bottom: none;
    }

    .field.addresses {
        margin-bottom: 0;
    }

    .shipping-address-items {
        display: flex;
        flex-direction: column;

        &.active {
            .not-selected-item {
                display: block;
            }
        }
    }

    .shipping-address-toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-align: left;
        padding-left: $base-spacing;
        padding-right: $base-spacing;


        &.active {
            .svg-icon {
                transform: scale(-1);
            }
        }
    }

    .shipping-address-item {
        overflow: hidden;
    }

    .selected-item {
        order: -1;
        margin-bottom: $base-spacing;

        .shipping-address-item-address {
            @include font-size($small-font-size);
            &:before {
                display: block;
                content: 'Selected Address:';
                font-weight: bold;
                color: $black;
            }
        }
    }

    .not-selected-item {
        position: relative;
        display: none;
        padding: $half-spacing;
        border: 1px solid $silver-lt;

        &:hover {
            background-color: $mercury;
        }

        .shipping-address-item-address {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include font-size($small-font-size);
        }
    }

    .shipping-address-item-actions {
        button {
            padding: 5px 10px;
            @include font-size($small-font-size);
        }
    }

    .action-select-shipping-item {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
        background: none;
        border: none;
    }

    .edit-address-link {
        position: relative;
        margin-top: 5px;
        z-index: 2;
    }

    .action-show-popup {
        width: 100%;
        background-color: $silver-lt;
        border-color: $silver-lt;
        margin-bottom: $base-spacing;
        margin-top: $base-spacing;

        @include hover {
            background-color: $silver-lt;
            border-color: $silver-lt;
        }
    }

    .checkout-shipping-address {
        .billing-address-same-as-shipping-block {
            margin-bottom: $base-spacing;
        }
    }

    .extra-order-info-container {
        padding-top: $base-spacing;
        margin-bottom: $base-spacing;
        border-top: 1px solid $silver-lt;

        .field {
            &:last-child {
                margin: 0;
            }
        }
    }
    .street.admin__control-fields.required {
        legend {
            &:after {
                content: ' *' !important;
            }
        }
        .label {
            &:after {
                content: ' ';
            }
        }
    }

    .button:disabled {
        background-color: $white;
        border: 1px solid $black;
        cursor: not-allowed;
        margin-bottom: 0;
    }
}

