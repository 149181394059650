.page-layout-klarnalanding {
    .page-title-wrapper {
        display: none;
    }
}

.kl__hero {
    padding: ($base-spacing * 3) $base-spacing;
    margin-bottom: ($base-spacing * 2);
    background-color: #ffb3c7;
    margin-top: $base-spacing;
    
    @include media-query(min, $lap-start) {
        padding: ($base-spacing * 7) $base-spacing;
        margin-bottom: ($base-spacing * 4);
    }
}

.kl__hero-content {
    text-align: center;
}

.kl__hero-img {
    display: block;
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
    
    @include media-query(min, $lap-start) {
        max-width: 300px;
    }
}

.kl__hero-title {
    color: $black;
    font-size: 1.5em !important;
    font-weight: 700 !important;
    color: $black;
    margin-bottom: $half-spacing;
    padding-top: $base-and-half-spacing;
    
    @include media-query(min, $lap-start) {
        font-size: 1.7em !important;
    }
}

.kl__hero-sub-title {
    color: $black;
    font-size: 1.5em !important;
    margin-bottom: 0;
    
    @include media-query(min, $lap-start) {
        font-size: 1.7em !important;
    }
}


.kl__content {
    margin-bottom: ($base-spacing * 2);
    
    @include media-query(min, $lap-start) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: ($base-spacing * 4);
    }
    
}

.kl__content--reverse {
    flex-direction: row-reverse;
}

.kl__content-info {
    text-align: center;
    margin-bottom: $base-spacing;
    
    @include media-query(min, $lap-start) {
        width: calc(50% - #{$half-spacing});
        margin-bottom: 0;
    }
}

.kl__content-title {
    padding-top: 0;
    margin-bottom: 0;
    color: $black;
    font-size: 2em !important;
    font-weight: 700 !important;
    
    @include media-query (min, $lap-start) {
        font-size: 2.5em !important;
    }
}

.kl__content-sub-title {
    font-size: 1.2em !important;
    
    @include media-query (min, $lap-start) {
        font-size: 1.5em !important;
    }
}

.kl__content-steps {
    h3 {
        margin-bottom: $base-spacing;
        padding-top: 0;
        color: $black;
        font-size: 1.2em !important;
    }
    
    p {
        color: $charcoal;
        margin-bottom: $half-spacing;

        b.pink {
            color: #ed839f;
        }
    }
}

.kl__content-image {
    @include media-query (min, $lap-start) {
        padding: 0 $base-spacing;
        width: calc(50% - #{$half-spacing});
    }
    
    @include media-query (min, $desk-start) {
        padding: 0 ($double-spacing * 1.5);
    }
    
    img {
        display: block;
        width: 100%;
    }
}


.kl__cta {
    @include media-query (min, $lap-start) {
        display: flex;
        justify-content: space-between;
    }
}

.kl__cta-block {
    padding: $base-spacing;
    margin-bottom: ($base-spacing * 2);
    border: 2px solid #ffb3c7;
    text-align: center;
    
    @include media-query (min, $lap-start) {
        margin-bottom: ($base-spacing * 3);
        width: calc(50% - #{$half-spacing});
    }
    
    h3 {
        color: $black;
        padding-top: 0;
    }
    
    p {
        color: $black;
        
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.button--klarna {
    background-color: #ffb3c7;
    border-color: #ffb3c7;
    width: 100%;
    color: $black;
    
    &:hover {
        background-color: darken(#ffb3c7, 5%);
        border-color: darken(#ffb3c7, 5%);
        color: $black;
    }
    
    @include media-query (min, $lap-start) {
        width: auto;
    }
}

.kl__cta-block--left {

}

.kl__cta-block--right {
    background-color: #ffb3c7;
}
