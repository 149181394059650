/*========================================
Forms
========================================*/
form {
    margin-bottom: $base-spacing;
    
    &:last-of-type {
        margin-bottom: 0;
    }
}

label,
.label {
    display: block;
    font-weight: 500;
    @include font-size($small-font-size);
    margin-bottom: ($half-spacing / 2);
    cursor: pointer;
    color: $charcoal;
}

select,
textarea,
input,
.custom-select-trigger {
    appearance: none;
    display: block;
    padding: ($half-spacing + 2) $base-spacing;
    width: 100%;
    line-height: 1.1;
    border: 1px solid $silver-lt;
    font-weight: 100;
    border-radius: 0;
    transition: border ease 0.3s;
    
    @include hover {
        outline: none;
        border-color: $charcoal;
    }
    
    &.mage-error {
        border-color: $failure;
        margin-top: 0;
    }
    
    &[disabled] {
        opacity: 0.7;
        cursor: not-allowed;
    }
    
    &::placeholder {
        color: $grey;
        @include font-size($small-font-size);
    }
}

.mage-error[generated],
.field-error,
.mage-error {
    margin-top: $half-spacing;
    color: $failure;
    @include font-size(13px);
}

input[type='radio'],
input[type='checkbox'] {
    display: inline-block;
    height: $base-spacing;
    width: $base-spacing;
    margin-right: $half-spacing;
    margin-bottom: 0;
    cursor: pointer;
    background-image: linear-gradient(to top, $smoke, $silver-lt);
    border-color: $grey-lt;
}

input[type='radio']:checked,
input[type='checkbox']:checked {
    position: relative;
    background: url('../svg/icon_tick.svg') center no-repeat $primary;
    background-size: 70%;
    border-color: $primary;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

select,
.custom-select-trigger {
    border-radius: 0;
    background: url('#{$svg-path}icon_down.svg') no-repeat right $base-spacing center $white;
    background-size: 14px;
    padding-right: $double-spacing + $half-spacing;
    line-height: 1.3;
}

select::-ms-expand {
    display: none;
}

fieldset {
    border: none;
    margin-bottom: $base-spacing;
    
    &:last-of-type {
        margin-bottom: 0;
    }
}

.fieldset--grouped {
    @include media-query(min, $lap-start) {
        @include clearfix;
        
        .field {
            width: calc(50% - #{$half-spacing});
            float: left;
            
            &:first-child {
                margin-right: $base-spacing;
            }
        }
    }
}

legend {
    display: block;
    width: 100%;
    padding-bottom: $half-spacing;
    margin-bottom: $base-spacing;
    @include font-size($h4-font-size);
    font-weight: 700;
    color: $charcoal;
}

.field.street.admin__control-fields {
    legend {
        display: none;
    }
}

.field {
    max-width: 35em;
    margin-bottom: $base-spacing;
}

.field--error {
    input {
        border-color: $failure;
    }
}

.choice,
.field--type-checkbox {
    display: flex;
    align-items: flex-start;
    margin-bottom: $half-spacing;
    
    input {
        margin-top: 2px;
        flex-shrink: 0;
    }
    
    label,
    .label {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        
        img {
            margin-right: $half-spacing;
        }
    }
}

.input-text {
    &::placeholder {
        color: $silver;
    }
}

.input-text--centered {
    text-align: center;
}

.control {
    position: relative;
    
    .nested {
        padding-top: $base-spacing;
    }
    
    &._with-tooltip {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        
        .input-text {
            width: auto;
            flex-grow: 1;
            flex-shrink: 1;
        }
        
        .field-tooltip {
            position: static;
            margin-left: $half-spacing;
            flex-grow: 0;
            flex-shrink: 0;
            width: 20px;
        }
        
        .field-error {
            width: 100%;
        }
    }
}

.is-email-available {
    position: relative;
    
    &:after {
        @include overlay-spinner;
        content: ' ';
    }
}

.is-email-available--loading {
    > * {
        opacity: 0.3;
    }
    
    &:after {
        opacity: 1;
        visibility: visible;
    }
}

.is-email-available__password {
}

.is-email-available__password--hidden {
    display: none;
}

.control--combine {
    display: flex;
    
    input {
        flex-grow: 1;
    }
    
    button,
    .button {
        flex-grow: 1;
    }
}

.field.required,
.field._required {
    > label {
        &:after {
            content: ' *';
        }
    }
}

//password
.password {
    .control {
        display: flex;
        flex-direction: column;
        
        input {
            order: 1;
        }
        
        #password-strength-meter-container {
            order: 2;
        }
        
        .mage-error[generated] {
            order: 3;
        }
    }
}

.password-strength-meter {
    @include font-size($small-font-size);
    background-color: $silver-lt;
    line-height: 32px;
    height: 32px;
    padding: 0 9px;
    position: relative;
    z-index: 1;
    color: $charcoal;
    font-weight: 700;
    
    &::before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }
}

.password-none {
}

.password-weak .password-strength-meter:before {
    background-color: #f893af;
    width: 25%;
}

.password-medium .password-strength-meter:before {
    background-color: #ffd6b3;
    width: 50%;
}

.password-strong .password-strength-meter:before {
    background-color: lighten($success, 15%);
    width: 75%;
}

.password-very-strong .password-strength-meter:before {
    background-color: $success;
    width: 100%;
}

//DOB

.customer-dob {
    button {
        position: absolute;
        top: 0;
        right: 0;
        width: $input-height;
        height: $input-height;
        border: none;
        //background: url("../img/calendar.svg") no-repeat center;
        background-size: $base-and-half-spacing;
        
        span {
            display: none;
        }
    }
    
    input {
        padding-right: $input-height;
    }
}

.ui-datepicker {
    background-color: $white;
    padding: $half-spacing;
}

.ui-datepicker-header {
    background-color: $white;
}

.ui-datepicker-prev {
    margin-right: $base-spacing;
}

.ui-datepicker-prev,
.ui-datepicker-next {
    display: inline-block;
    margin-bottom: $half-spacing;
}

.ui-datepicker-title {
    display: flex;
}

.ui-datepicker-month {
    width: calc(50% - #{$half-spacing});
    margin-right: $base-spacing;
    margin-bottom: $base-spacing;
}

.ui-datepicker-year {
    width: calc(50% - #{$half-spacing});
    margin-bottom: $base-spacing;
}

.ui-datepicker-calendar {
    margin-bottom: 0;
    border-left: 1px solid $silver-lt;
    border-right: 1px solid $silver-lt;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}
