.site-top__container {
    li {
        list-style: none;
    }
    .contact-popup {
        position: relative;
        a {
            border: none;
            margin-right: 10px;
            display: flex;
            align-items: center;
            padding: $half-spacing;
            font-weight: 500;
            line-height: 1.1;
            text-decoration: none;
            color: $white;
            font-size: $small-font-size;
        }
        svg {
            margin-right: 10px;
        }
    }

    .contact-popup__header {
        position: relative;
        z-index: 3;

        &:after {
            visibility: hidden;
            opacity: 0;
            content: '';
            position: absolute;
            top: 100%;
            left: 9px;
            width: 0;
            height: 0;
            border-left: $half-spacing solid transparent;
            border-right: $half-spacing solid transparent;
            border-top: $half-spacing solid $charcoal;
        }

        &.active {
            &:after {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .contact-popup__content-wrapper {
        display: none;
        position: absolute;
        background-color: $white;
        width: 500px;
        top: 100%;
        right: -98px;
        z-index: 2;
        padding: 0 $base-spacing $base-spacing $base-spacing;
        border: 1px solid $silver-lt;
        border-top: none;
        grid-template-columns: 1fr 1.5fr;
        -ms-grid-columns: 180px $double-spacing 1.5fr;
        grid-column-gap: $double-spacing;

        &.active {
            display: grid;
        }
    }

    .contact-popup__block {
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
    }

    .contact-popup__block--contact {
        position: relative;
        -ms-grid-column: 4;
        -ms-grid-column-span: 2;

        .button {
            border-left: none;
            color: $charcoal;
            width: auto;
            display: block;
            text-align: center;
        }

        &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            background-color: $silver-lt;
            left: -20px;
        }

        p {
            font-size: $small-font-size;
            margin-bottom: $half-spacing;
        }
    }

    .contact-popup__info-content {
    }

    .contact-popup__info-content-number {
        border-left: none;
        color: $charcoal;
        padding-left: 0;
        font-size: 18px;
    }

    .contact-popup__info-content--contact {
        p, ul {
            font-size: $small-font-size;
        }
        ul {
            list-style: none;
        }
    }

    .contact-popup__title {
    }

    .contact-popup__wrapper {
        ul {
            list-style: none;
        }
    }

    .contact-popup__link {
        display: block;
        border-left: none;
        color: $charcoal;
        padding: $half-spacing;
        padding-left: 0;
        font-weight: 500;
        line-height: 1;
        text-decoration: none;
        font-size: $small-font-size;
    }
}
