//
//  Mobile
//  _____________________________________________
.pagebuilder-column-group,
.pagebuilder-column-line {
    gap: 10px;
}

@include max-screen($screen__m - 1) {
    .pagebuilder-column-group,
    .pagebuilder-column-line {
        flex-wrap: wrap;
        gap: 15px;
    }
}
