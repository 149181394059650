//
//  Default appearance styles
//  _____________________________________________

[data-content-type='button-item'] {
    width: 100%;
    max-width: 100%;

    @include media-query(max, $lap-start) {
        max-width: 120px;
    }

    [data-element='link'],
    [data-element='empty_link'] {
        max-width: 100%;
        word-wrap: break-word;
    }
    [data-element='empty_link'] {
        cursor: default;
    }

}

a,
button,
div {
    &.pagebuilder-button-link {
        @include lib-button-s();
        @include lib-button-as-link(
                $_margin: 0 10px 10px 0,
                $_padding: $button__padding__l
        );
        @include lib-css(font-weight, $font-weight__semibold);
        box-shadow: none;
        box-sizing: border-box;
        display: inline-block;
    }

    &.pagebuilder-button-primary {
        @include lib-link-as-button();
        @include lib-button-primary($_button-margin: 10px 10px 10px 0);
        @include lib-button-s();
        box-shadow: none;
        border: none;
        cursor: pointer !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 1em;
        &:hover {
            border: none;
        }
    }

    &.pagebuilder-button-secondary {
        @include lib-link-as-button();
        @include lib-button($_button-margin: 10px 10px 10px 0);
        @include lib-button-s();
        box-shadow: none;
        border: none;
        cursor: pointer !important;;
        font-weight: 400;
        font-size: 12px;
        line-height: 1em;
        &:hover {
            border: none;
        }
    }
}
