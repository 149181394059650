.captcha.required {
    .control.captcha {
        display: flex;
        flex-flow: row wrap;
    }

    .captcha-img {
        margin-bottom: $half-spacing;
    }
}
