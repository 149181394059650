.custom-swatches {
    margin-left: -($half-spacing * 1.5);
    @include clearfix;
    margin-bottom: 0;
}

.custom-swatches__swatch {
    display: block;
    float: left;
    padding-left: ($half-spacing * 1.5);
    margin-bottom: $base-spacing;

    &[data-in-stock="false"] {
        opacity: 0.6;
    }
}

.custom-swatches__button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    background: none;
    border: none;
    font-weight: 100;

    @include hover {
        cursor: pointer;
        background: none;
        border: none;

        &[data-disabled="true"] {
            .custom-swatches__hex {
                border-color: $silver-lt;
            }
        }

        .custom-swatches__hex {
            border-color: $black;
        }
    }

    &.selected {
        font-weight: bold;
    }
}

.custom-swatches__hex {
    display: block;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid $silver-lt;
    box-shadow: inset 0px 0px 0px 3px $white;
    transition: all ease .3s;
    background-size: contain;
    background-position: center;

    .out-of-stock &,
    [data-disabled="true"] & {
        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            background: $white;
        }

        &:before {
            transform: translateY(-50%) rotate(45deg);
        }

        &:after {
            transform: translateY(-50%) rotate(-45deg);
        }
    }

    .selected & {
        border-color: $black;
    }
}

.custom-swatches__label {
    display: none;
    margin-left: $half-spacing;

    .form-group__item &,
    .custom-select-trigger & {
        display: block;
    }
}

.custom-swatches-more,
.custom-swatches-less {
    background: none;
    padding: $half-spacing;
    border: none;
    text-decoration: underline;

    @include hover {
        background: none;
        border: none;
        cursor: pointer;
    }

    @include media-query (min, $desk-start) {
        order: 9999;
        margin-bottom: $base-spacing;
    }
}

.custom-select {
    position: relative;
    &.active {
        .custom-select-content {
            display: block;
            width:100%;
        }
    }
}

.custom-select-trigger {
    display: flex;
    align-items: center;
    font-size: inherit;
    text-align: left;
    height: $input-height;
    @include font-size(14px);
    padding: ($half-spacing + 2) $base-spacing ($half-spacing + 2) $half-spacing;

    &.active {
        border-color: $black;
    }

    @include hover {
        background: url('#{$svg-path}icon_down.svg') no-repeat right $base-spacing center $white;
    }

    .custom-swatches__hex {
        width: 30px;
        height: 30px;
        box-shadow: inset 0px 0px 0px 2px $white;
    }
}

.custom-select-content {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    max-height: 200px;
    width: 50%;
    border: 1px solid black;
    border-top: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background: $white;
    display: none;

    &::-webkit-scrollbar {
        width: 6px
    }

    &::-webkit-scrollbar-track {
        background: $white;
    }

    &::-webkit-scrollbar-thumb {
        background: $silver;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
        background: #919191;
    }

    .custom-swatches {
        margin: 0;
        padding: 0;
    }

    .custom-swatches__swatch {
        width: 100%;
        padding: 0;
        margin: 0;

        @include hover{
            background: $mercury;
        }
    }

    .custom-swatches__button {
        width: 100%;
        padding: 8px $half-spacing;
    }

    .custom-swatches__hex {
        width: 30px;
        height: 30px;
        box-shadow: inset 0px 0px 0px 2px $white;
    }

    .custom-swatches__label {
        display: block;
        text-align: left;
        line-height: 1.2;
    }

}

//Inside the Add multiple popup
.form-group__item {
    .custom-swatches__hex {
        width: 27px;
        height: 27px;
        box-shadow: inset 0px 0px 0px 2px $white;
        margin-right: $half-spacing;
    }
}
.catalog-product-view ul#custom-swatches:not(:empty) {
    min-height: fit-content !important;
}
