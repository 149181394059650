/*================================================================================
LINKS
================================================================================*/

a {
    color: $link;
    text-decoration: none;

    @include hover() {
        outline: none;
        color: $link-hover;
        text-decoration: underline;
        cursor: pointer;
    }
}
