.minicart-button {
    position: relative;
}

.minicart-button__text {
    @include media-query(min, $lap-start) {
        display: none;
    }
    @include media-query(min, $desk-start) {
        display: block;
    }
}

.minicart-button__counter {
    @include media-query(max, $lap-start) {
        &:before {
            content: '(';
        }
        &:after {
            content: ')';
        }
    }
    @include media-query(min, $lap-start) {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        min-width: 17px;
        height: 17px;
        background-color: $charcoal;
        @include font-size($micro-font-size);
        color: $primary;
        text-align: center;
        line-height: 17px;
        padding: 0 1px;
    }

    &.empty {
        display: none;
    }

    &._block-content-loading {
        .loading-mask {
            display: none;
        }
    }
}

.minicart-button__number {
}
