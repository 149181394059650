.product-add {
    display: flex;
    position: relative;
    width: 100%;
}

.product-add__button {
    display: flex;
    padding: 0;
    min-width: $button-height;
    background: transparent;
    border: 1px solid $silver-lt;

    -webkit-appearance: none;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;

    @include hover {
        cursor: pointer;
        background: lighten($mercury, 2%);
    }

    .svg-icon {
        margin: auto;
        color: $charcoal;
        width: 0.7em;
        height: 0.7em;
    }
}

.product-add__input {
    height: $button-height;
    padding: 0;
    flex-grow: 1;
    text-align: center;
    border-left: none;
    border-right: none;

    @include media-query(min, $desk-start) {
        width: 60px;
    }

    @include hover {
        border-color: $silver-lt;
    }
}

.product-add__input--multi {
    @include hover {
        border-color: $silver-lt;
    }
}

.product-add__button--minus {
    border-right: none;
}

.product-add__button--plus {
    border-left: none;
}

.product-add--inline {
    .product-add__input {
        width: 35px;
        @include media-query(min, 370px) {
            width: 45px;
        }
    }

    .product-add__button {
        min-width: 25px;
    }

    .product-add__button,
    .product-add__input {
        height: 36px;
    }
}
body.restricted #product-addtocart-button,
[data-role="howtobuy"].visible + .product-item-inner,
[data-role="howtobuy"].visible + .box-tocart {
    display: none;
}
body:not(.ccf-rendered) .product-items .product-item-actions {
    pointer-events: none;
}
