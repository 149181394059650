.brand-listing {
    padding-bottom: $base-spacing;
    border-bottom: 1px solid $silver-lt;
}

.brand-listing__header {
    @include font-size($h1-font-size);
    font-weight: 100;
}

.brand-listing__lists {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: $base-spacing;
    @include media-query(min, $palm-start) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media-query(min, $lap-start) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include media-query(min, $desk-start) {
        grid-template-columns: repeat(6, 1fr);
    }
}

.brand-listing__item {
}

.brand-listing__link {
    display: block;
    @include font-size($small-font-size);
    color: $charcoal;
    text-decoration: none;

    @include hover {
        color: $charcoal;
        text-decoration: underline;
    }
}
