.message.global.cookie {
    background: rgba(51, 51, 51, 0.75);
    font-size: $small-font-size;
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    margin: 0;

    .content {
        color: $white;
        margin-right: 3em;

        h3 {
            color: $white;
            font-size: $base-font-size;
            margin-bottom: 0;
        }
    }

    a {
        color: $primary;
    }
}

.cookie-status-message {
    display: none;
}
