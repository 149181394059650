.membership-heading {
    color: $gold;
    font-weight: $font-weight__semibold;
    font-size: 14px;
    &,
    div.value {
        display: inline-block;
    }
    &:before {
        content: '';
        display: inline-block;
        margin-right: 5px;
        height: 11px;
        width: 14px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11' fill='none'%3E%3Cpath fill='%23CCAB82' d='m0 .407 4.965 5.704L6.875 0l1.91 6.111L13.75.407V11H0V.407Z'/%3E%3C/svg%3E");
    }
}
