.usps-container {
    background-color: $usp-background-color;
    .usps {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: $page-width;
        margin: 0 auto;
        padding: 0 20px;
    }
    .usp {
        padding: 10px 0;
        font-weight: bold;
        display: flex;
        align-items: center;
        text-decoration: none;
    }
    .usp__icon:before {
        font-family: $icomoon-font-family;
        margin-right: 10px;
        font-size: 25px;
    }
    .usp.trustpilot-widget {
        margin-bottom: -5px;
    }
    .usp__text p {
        margin: 0;
    }
}
body:not(.cms-index-index) .usps-container {
    margin-bottom: 10px;
}
@include media-query(min, $desk-start) {
    div.usps-container .usp__text {
        font-size: $usp--font-size--desktop;
    }
}
@include media-query(max, $desk-start) {
    .usps-container .usp__text {
        font-size: $usp--font-size--mobile;
    }
}
@include media-query(max, $lap-start) {
    .usps-container {
        .usp__text {
            font-size: 14px;
            text-align: center;
        }
        .usps > .usp {
            display: none;
        }
        .slick-slide .usp {
            display: flex !important;
            justify-content: center;
            align-items: center;
        }
    }
}
