.tile {
    padding: ($base-spacing * 1.5) 0 $half-spacing;
    border-bottom: 1px solid $silver-lt;

    @include media-query (min, $lap-start) {
        padding: ($base-spacing * 3) 0 $double-spacing;
    }
}

.tile__item {
    margin-bottom: $base-and-half-spacing;
    display: flex;
    flex-direction: column;
}

.tile__content {
    @include media-query (min, $palm-start) {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: space-between;
    }
}

.tile__image-link {
    position: relative;
    display: block;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba($black, 0.2);
        opacity: 0;
        transition: all ease 0.6s;
    }
}

.tile__image {
    display: block;
    width: 100%;
}

.tile__info {
    padding-right: $base-spacing;
}

.tile__title {
    @include font-size(16px);
}

.tile__desc {
    @include font-size(14px);

    @include media-query (min, $palm-start) {
        margin-bottom: 0;
    }
}

.tile__link {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: $base-spacing;
    width: 100%;

    @include media-query (max, $palm-start) {
        width: 100%;
    }
}

.tile--square {
    @include media-query(min, $lap-start) {
        display: flex;
        flex-wrap: wrap;
    }

    .tile__item {
        @include media-query-bracket($lap-start, $desk-start) {
            width: calc(50% - (#{$double-spacing / 2}));
            margin-right: $double-spacing;

            &:nth-child(2n + 2) {
                margin-right: 0;
            }
        }
        @include media-query(min, $desk-start) {
            width: calc(33.3333% - (#{($double-spacing * 2) / 3}));
            margin-right: $double-spacing;
            display: flex;
            flex-direction: column;

            &:nth-child(3n + 3) {
                margin-right: 0;
            }
        }
    }
}

.tile--rectangle {
    @include media-query(min, $lap-start) {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $base-spacing;

        .tile__item {
            @include media-query(min, $lap-start) {
                width: calc(33.3333% - #{$base-and-half-spacing - 3px});
                margin-right: $double-spacing;
                display: flex;
                flex-direction: column;

                &:nth-child(3n + 3) {
                    margin-right: 0;
                }
            }
        }
    }
}

.tile--full {
    display: block;
    @include media-query(min, $lap-start) {
        margin-bottom: $double-spacing * 2;
    }

    .tile__content {
        @include font-size($small-font-size);
        padding: $base-and-half-spacing $base-and-half-spacing 40%;

        @include media-query(min, $lap-start) {
            width: 50%;
            padding: ($double-spacing * 1.5);
            padding-right: 0;
            @include font-size($base-font-size);
        }

        p {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .tile__link {
        padding-top: 0;
        text-decoration: none;
        @include font-size($h4-font-size);
        text-align: left;
        margin-bottom: $base-spacing;

        @include hover {
            text-decoration: underline;
            color: $charcoal;
        }
    }
}
