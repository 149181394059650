.page.messages {
    width: 100%;
    max-width: $page-width;
    margin: 0 auto;
    padding: 0 $half-spacing;

    @include media-query(min, $lap-start) {
        padding: 0 $base-spacing;
    }
}

.messages {
    width: 100%;
    max-width: $page-width;
    margin: 0 auto;
    padding: 0 $base-spacing;
}

.message {
    position: relative;
    margin: $base-spacing 0;
    font-weight: 700;
    padding: 0 0 0 32px;
    @include font-size($small-font-size);

    span, div {
        display: inline-block;
    }

    a {
        color: $charcoal;
        text-decoration: underline;
    }

    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: $half-spacing;
        transform: translateY(-50%);
        margin-right: $half-spacing;
        width: 13px;
        height: 13px;
    }
}

.message.error,
.message-error {
    color: $failure;

    &:before {
        background: url('../img/icon_cross_red.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}

.message.success,
.message-success {
    color: $success;

    &:before {
        background: url('../img/icon_tick_green.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}

.message.notice,
.message-notice {
    color: $alert;

    &:before {
        background: url('../img/desktop_info.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}

.customer-account-logoutsuccess {
    .page-title-wrapper {
        width: 100%;
        max-width: $page-width;
        margin: 0 auto;
        padding: 0 $base-spacing;
    }
}

.atb-success {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;
    padding: $half-spacing 0;
    text-align: center;
    background: $primary;
    @include font-size($small-font-size);
    transform: translateY(-100%);
    transition: all ease .3s;

    a {
        text-decoration: underline;
    }

    &.active {
        transform: translateY(0);
    }

    @include media-query (min, $lap-start) {
        display: none;
        visibility: hidden;
        opacity: 0;
    }
}
