$icomoon-font-family: "icomoon";
$icomoon-font-path: '../fonts/#{$icomoon-font-family}';

$icon-crown: "\e90a";
$icon-klarna7: "\e909";
$icon-plus: "\e907";
$icon-minus: "\e908";
$icon-arrow-right: "\e905";
$icon-arrow-left: "\e906";
$icon-search: "\e900";
$icon-price-match: "\e901";
$icon-minicart: "\e902";
$icon-delivery: "\e903";
$icon-account: "\e904";
