.quickorder-index-index {
    .page-title-wrapper {
        @include container;
    }

    .box-items {
        margin-bottom: $base-spacing;
    }

    .field.upload.skus {
        margin-top: $base-spacing;
    }
}
