//Account/personal information
.customer-account-edit {
    //hiding unnecessary fields
    .field-business_name,
    .field-how_find_us,
    .field-marketing_email,
    .field-marketing_post,
    .field-marketing_phone,
    .field-territory,
    .field-customer_title,
    .field-mobile_number {
        display: none;
    }
}
