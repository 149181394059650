.proposition-banner {
    margin-bottom: $half-spacing;
    @include media-query(min, $lap-start) {
        display: block;
        padding-top: $base-spacing;
        margin-bottom: $base-spacing;
    }
}

.proposition-banner__container {
    @include media-query(max, $lap-start) {
        position: relative;
        padding: 0 0 14% 0;
        overflow: hidden;
    }
    @include media-query(min, $lap-start) {
        display: flex;
        justify-content: space-between;
    }
}

.proposition-banner__item {
    @include media-query(max, $lap-start) {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding: 0 $half-spacing;
        opacity: 0;

        &:first-child {
            animation: fadeTransition 12s infinite;
        }

        &:nth-child(2) {
            animation: fadeTransition 12s infinite;
            animation-delay: 4s;
        }

        &:last-child {
            animation: fadeTransition 12s infinite;
            animation-delay: 8s;
        }
    }

    @include media-query(min, $lap-start) {
        width: calc(33.33% - (#{$base-spacing * 2} / 3));
    }
}

@keyframes fadeTransition {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 1;
        z-index: 1;
    }
    33% {
        opacity: 1;
        z-index: 1;
    }
    37% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes moveUp {
    0% {
        transform: translateY(0);
    }
    33.2% {
        transform: translateY(0);
    }
    33.3% {
        transform: translateY(-100%);
    }
    66.5% {
        transform: translateY(-100%);
    }
    66.6% {
        transform: translateY(-200%);
    }
    99.9% {
        transform: translateY(-200%);
    }
    100% {
        transform: translateY(-300%);
    }
}

.proposition-banner__link {
    img {
        width: 100%;
    }
}
