.opc-progress-bar {
    width: 100%;
    clear: bottom;
    list-style: none;
    margin-left: 0;
    margin-bottom: $base-and-half-spacing;
    display: none;

    @include media-query (min, $desk-start) {
        display: flex;
        margin-bottom: $double-spacing;
        padding: 0 $base-spacing;
    }
}

.opc-progress-bar-item {
    @include font-size($micro-font-size);
    color: $silver;
    font-weight: bold;
    width: 33.3333%;
    line-height: 1.2;
    flex-grow: 1;
    text-align: center;

    @include media-query (min, $desk-start) {
        @include font-size($small-font-size);
        text-align: left;
    }

    &:before {
        content: '';
        display: block;
        height: 5px;
        width: 100%;
        background-color: $silver-lt;
        margin-bottom: $half-spacing;
    }

    span {
        display: block;
        padding: 0 5px;

        @include media-query (min, $desk-start) {
            display: flex;
            align-items: center;
            padding: 0;
        }

        &:before {
            content: '';
            display: block;
            height: 20px;
            width: 20px;
            border-radius: 100%;
            margin: 0 auto 5px;
            background: $silver-lt;

            @include media-query (min, $desk-start) {
                margin: 0 $half-spacing 0 0;
            }
        }
    }

    &:last-child {
        margin: 0;
    }

    &._active {
        color: $grey;

        &:before {
            background-image: linear-gradient(to right, $accent 0%, $silver-lt 15%);
        }

        span {
            &:before {
                background: url("#{$image-path}dots.svg") no-repeat center $grey;
                background-size: contain;
            }
        }
    }

    &._complete {
        color: $secondary;

        &:before {
            background: $accent;
        }

        span {
            &:before {
                background: url("#{$image-path}icon_tick_white.svg") no-repeat center $secondary;
                background-size: 10px;
            }

            &:after {
                display: block;
                content: 'Edit';
                text-decoration: underline;
                cursor: pointer;
                margin-top: 5px;

                @include media-query (min, $desk-start) {
                    margin-left: $base-spacing;
                    margin-top: 0;
                }
            }
        }
    }
}
