.site-footer__info .site-footer__info-content {
    @include media-query(max, $lap-start) {
        border-top: 1px solid $black;
        border-bottom: unset;
        &--contact {
            border-bottom: 1px solid $black;
        }
    }
}
.site-legal__copyright {
    @include media-query(min, $lap-start) {
        border-top: unset;
        span {
            border-top: 1px solid $black;
        }
    }
}
a.site-footer__info-content-number {
    @include media-query(min, $lap-start) {
        font-size: 25px;
    }
}
