/*================================================================================
MAIN
================================================================================*/

*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    position: relative;
    height: 100%;
    max-width: 100%;
    box-sizing: border-box;
    font: #{($base-font-size/16px) * 100%}/#{$base-line-height} $base-font-family;
    font-weight: 300;
}

body {
    position: relative;
    height: 100%;
    max-width: 100%;
    background: white;
    color: $base-color;
    text-rendering: optimizeLegibility;

    /* Conditional CSS http://adactio.com/journal/5429/ */
    &:after {
        content: 'palm';
        display: none;

        @include media-query(lap) {
            content: 'lap';
        }
        @include media-query(desk) {
            content: 'desk';
        }
        @include media-query(wide) {
            content: 'wide';
        }
    }

    @include media-query(max, $lap-start) {
        &.body-fixed {
            position: fixed;
        }
    }
}

.scroll-locked {
    overflow: hidden;
    height: 100%;
}

.full-width {
    width: 100%;
    background-color: $secondary;

    text-align: center;
    @include media-query(min, $desk-start) {
        margin-bottom: $base-and-half-spacing;
    }
}
