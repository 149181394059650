.site-controls {
    display: flex;
    border-top: 1px solid $silver-lt;
    background-color: $white;

    @include media-query(min, $lap-start) {
        position: relative;
        align-items: center;
        border: none;
        margin-left: 20px;
    }
}

.site-controls__button {
    display: block;
    width: 25%;
    border-left: 1px solid $silver-lt;

    &:first-child {
        border-left: none;
    }

    @include media-query(min, $lap-start) {
        width: unset;
        border: 1px solid $silver-lt;
    }
}

.site-controls__button--menu {
    @include media-query(min, $lap-start) {
        display: none;
    }
}

.site-controls__button--search {
    @include media-query(min, $lap-start) {
        display: none;
    }
}

.site-controls__button--account {
    @include media-query(min, $lap-start) {
        display: none;
    }
}

.site-controls__button--minicart {
    @include media-query(min, $lap-start) {
        @include hover {
            border-color: $charcoal;
        }
    }
}

.site-controls__trigger {
    display: block;
    width: 100%;
    padding: ($half-spacing + 5px) 0 $half-spacing;
    background-color: unset;
    border: none;
    @include font-size($micro-font-size);
    text-align: center;
    font-weight: 500;
    text-decoration: none;
    color: $charcoal;
    white-space: nowrap;

    .svg-icon {
        display: block;
        margin: 0 auto 5px;
        width: 1.8em;
        height: 1.8em;
        color: $charcoal;

        @include media-query(min, $lap-start) {
            margin: 0;
            width: 1.5em;
            height: 1.5em;
        }
        @include media-query(min, $desk-start) {
            margin: 0 $half-spacing 0 0;
        }
    }

    @include hover {
        background-color: unset;
        color: $charcoal;
        text-decoration: none;
    }

    @include media-query(min, $lap-start) {
        padding: $half-spacing;
        @include font-size($small-font-size);
        font-weight: 700;
    }

    @include media-query(min, $desk-start) {
        display: flex;
        align-items: center;
        padding: $half-spacing $base-spacing;
    }
}
