.product.info.detailed {
    margin-bottom: $base-spacing;
    @include media-query (min, $lap-start) {
        margin-bottom: $double-spacing;
    }
}

.product.data.items {
}

.data.item.title {
    border-bottom: 1px solid $silver-lt;
    cursor: pointer;
    outline: none;

    &:first-of-type {
        border-top: 1px solid $silver-lt;
    }

    &.active {
        border-bottom: none;
        .data.switch:before {
            background: url(../svg/icon_minus.svg) center no-repeat;
        }
    }

    .data.switch {
        @include font-size(16px);
        font-weight: 700;
        color: $charcoal;
        text-decoration: none;
        display: block;
        margin: 0;
        padding: $half-spacing 0;
        @include toggle($base-spacing);
        outline: none;

        @include media-query(min, $desk-start) {
            padding: $base-spacing 0;
        }
    }
}

.data.item.content {
    display: none;
    border-bottom: 1px solid $silver-lt;

    &.active {
        display: block;
    }
}

.product.attribute.description {
    @include font-size(13px);

    @include media-query(min, $lap-start) {
        @include font-size(16px);
    }
    .value {
    }

    p {
    }

    li {
        margin-left: $base-spacing;
    }
}

.additional-attributes-wrapper {
    .table-caption {
    }
}

.data.table.additional-attributes {
    .col.label {
        @include font-size(13px);
        border-bottom: none;
        padding: 5px 10px;
    }
    .col.data {
        @include font-size(13px);
        border-bottom: none;
        padding: 5px 10px;
    }
}

.mobile-short-description {
    @include font-size(13px);

    @include media-query(min, $lap-start) {
        display: none;
    }
}

.desk-details-wrapper {
    display: none;
    @include media-query(min, $lap-start) {
        display: block;
    }
}

.desk-details-wrapper--active {
    display: block;
}

.desk-details-wrapper__read-more {
    @include media-query(min, $lap-start) {
        display: none;
    }
}
