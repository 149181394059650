.hp-scrollable {
    .slick-slide {
        margin: 0 10px;
    }
    .slick-list {
        margin: 0 -10px;
    }
    .slick-prev, .slick-next {
        position: absolute;
        top: -30px;
    }
    .slick-prev {
        right: 60px;
        left: unset;
    }
    .slick-next {
        right: 0;
    }
    img {
        border-radius: 20%;
    }
}
@include media-query(max, $lap-start) {
    .hp-scrollable {
        .pagebuilder-column-line .slick-arrow {
            display: none !important; // override JS inline style
        }
        .slick-slide {
            margin: 0 5px;
        }
        [data-content-type="heading"] {
            font-size: 12px;
            word-break: normal;
        }
    }
}
@include media-query(min, $lap-start) {
    .our-brands .pagebuilder-column-line {
        margin: 0;
    }
    .cms-index-index .our-brands {
        .all-brands {
            padding-left: 10px;
        }
        figure {
            padding: 0 10px;
        }
        .slick-list {
            margin-left: -20px
        }
    }
}
