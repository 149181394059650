.category-header {
    margin-bottom: $base-spacing;
    width: 100%;
}

.category-header__title {
    padding-top: 0;
    @include font-size(14px);
    color: $charcoal;
}

.category-header__content {
    position: relative;
    background-color: $white;
    width: 100%;
    margin-bottom: $base-spacing;

    p {
        @include font-size(13px);
        color: $charcoal;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    h2 {
        @include font-size(18px);
        color: $charcoal;
    }

    * {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.category-header__target {
    overflow: hidden;

    p {
        margin-bottom: 0;
        display: none;
        margin-top: $base-spacing;

        &:first-of-type {
            display: block;
            margin-top: 0;
        }
    }

    &.active {
        p {
            display: block;
        }
    }
}

.category-header__toggle {
    background: none;
    padding: $base-spacing 0 0 0;
    outline: none;
    border: none;

    @include hover {
        background: none;
    }
}

.category-header__image {
    display: block;
    width: 100%;

    img {
        display: block;
        width: 100%;
    }
}

// Styling for 1 column Category Landing Page
.page-layout-1column .category-header {
    width: 100%;
    .category-header__title {
        display: none;
    }
    figure,
    .category-header__content {
        padding: 0;
        p {
            font-size: 1rem;
            line-height: 1.4;
            padding: 10px 0 10px 0;
        }
    }
}
