.price-container {
    display: block;
    @include font-size(20px);
    font-weight: 700;
    color: $charcoal;

    .product-info-main & {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        @include font-size(24px);

        @include media-query(min, $lap-start) {
            @include font-size(30px);
        }

        .price-excluding-tax {
            order: 1;
            margin-right: $half-spacing;
        }

        .price-including-tax {
            order: 2;
            @include font-size(13px);
        }
    }
}

.price-label {
    display: none !important;
}

.price-wrapper {
    display: block;

    &:after {
        content: ' ' attr(data-label);
        @include font-size(13px);
        font-weight: 300;
        color: $grey-lt;
        position: relative;
    }

    &.price-including-tax {
        .product-items & {
            display: none;
        }

        .product-info-main & {
            &:before {
                font-weight: 100;
                content: '(';
            }

            &:after {
                content: ' ' attr(data-label) ')';
            }
        }
    }
}

// Custom configurable special
.page-product-configurable {
    .price-box {
        &.unselected {
            .price-wrapper[data-price-type='basePrice'] {
                &:before {
                    content: 'From ';
                    @include font-size(13px);
                    font-weight: 300;
                    color: $grey-lt;
                }
            }
        }

        &.special {
            .normal-price {
                .price-wrapper[data-price-type='basePrice'] {
                    &.price-excluding-tax {
                        color: $red;
                    }

                    &:before {
                        content: 'Now ';
                        @include font-size(13px);
                        font-weight: 300;
                        color: $grey-lt;
                    }
                }
            }

            .old-price {
                display: block !important;
            }
        }
    }
}

.price-box {
    .type-configurable {
        .price-wrapper[data-price-type='basePrice'] {
            &:before {
                content: 'From ';
                @include font-size(13px);
                font-weight: 300;
                color: $grey-lt;
            }
        }
    }
}

.special-price {
    display: block;

    .price-wrapper {
        &.price-including-tax {
            .product-info-main & {
                &:before {
                    content: '(';
                }
            }
        }

        &.price-excluding-tax {
            color: $red;
        }

        &:before {
            content: 'Now ';
            @include font-size(13px);
            font-weight: 300;
            color: $grey-lt;
        }
    }
}

.old-price {
    display: block;

    .price-container {
        @include font-size(14px);
        font-weight: 700;
        color: $charcoal;

        .product-info-main & {
            @include font-size(18px);
        }
    }

    .price-wrapper {
        &:before {
            content: 'Was ';
            @include font-size(13px);
            font-weight: 300;
            color: $grey-lt;
        }
    }

    .price-including-tax {
        display: none;
    }
}

.price-as-configured {
    margin-bottom: 0;
}

.price-box.price-tier_price {
    order: 3;
}
.prices-tier {
    background: $mercury;
    padding: $half-spacing;
    list-style: none;
    margin: $half-spacing 0 $base-spacing;
    order: 3;
    @include font-size(14px);

    @include media-query (min, $lap-start) {
        margin: $half-spacing 0 0;
        padding: $base-spacing;
    }

    .item {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $silver-lt;
        font-weight: 700;
        color: $charcoal;

        @include media-query (max, $lap-start) {
            @include font-size(12px);
        }

        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }

        * {
            font-size: inherit !important;
            display: inline;
        }

        .price-container {
            margin: 0;
        }

        .price-including-tax {

            &:before {
                color: #999;
                content: '(';
            }

            &:after {
                color: #999;
                content: ' inc. VAT)';
            }
        }
        .price-excluding-tax {
            margin-right: 0;
        }
    }
}
