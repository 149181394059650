.catalogsearch-result-index,.catalogsearch-advanced-result {
    .breadcrumbs {
        .items {
            @include container;
        }
    }
    .page-main {
        .page-title-wrapper {
            @include container;
        }
    }

    .page-title {
        @include font-size(14px);
    }
    .columns {
        padding: 0;

        .column.main {
            padding: 0 $base-spacing;
        }
    }

    .message.notice {
        @include container;

        .item {
            a {
                color: $charcoal;
            }
        }
    }

    .search.results {
        .item {
            a {
                color: $charcoal;
            }
        }
    }
}

.search-autocomplete {
    ul {
        list-style: none;
        border: 1px solid $silver;
        border-top: 1px solid $silver;
        color: $charcoal;
        position: relative;
        z-index: 6;
    }
    li {
        text-align: left;
        padding: $half-spacing $base-spacing;
        background-color: $white;
        color: $charcoal;

        @include hover {
            background-color: $smoke;
        }
    }

    .qs-option-name {
        padding-right: $half-spacing;
    }
    .amount {
        display: none;
    }
}

.cms-no-route {
    .disc {
        list-style: none;
    }
}
