.marketing-preferences {
    background-color: $white;

    position: relative;
    width: $modal-max-width;
    max-width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding: $base_spacing $half-spacing;

    @include media-query(min, $lap-start) {
        padding: $base_spacing;
    }

    form {
        text-align: center;

        span {
            display: inline-block;
        }

        em {
            color: $tertiary;
            font-weight: bold;
            text-transform: uppercase;
            font-style: normal;
        }

        .marketing-preferences-form-title {
            font-weight: bold;
        }

        .marketing-preferences-amend-notice {
            margin-bottom: $half-spacing;
        }
    }

    .fieldset {
        display: inline-block;
        text-align: center;
        margin-top: $base-spacing;
        clear: both;

        .field {
            margin-left: $base-spacing;
            span {
                margin-right: 5px;
            }
            span.marketing_preference_email {
                &:before {
                    content: '';
                    background: url(../img/at.svg) no-repeat;
                    background-size: 14px;
                    padding-right: 15px;
                }
            }
            span.marketing_preference_phone {
                &:before {
                    content: '';
                    background: url(../img/smartphone-screen.svg) no-repeat;
                    background-size: 14px;
                    padding-right: 15px;
                }
            }
            span.marketing_preference_post {
                &:before {
                    content: '';
                    background: url(../img/mail-black-envelope-symbol.svg)
                        no-repeat;
                    background-size: 14px;
                    padding-right: 15px;
                }
            }
        }
        .field.field-marketing_preference_email {
            float: left;
        }

        .field.field-marketing_preference_phone {
            float: left;
        }

        .field.field-marketing_preference_post {
            float: left;
        }
    }

    .fieldset.marketing {
        .field-marketing_preference_email {
            label {
                display: flex;
                input {
                    order: 2;
                }
                span {
                    order: 1;
                }
            }
        }

        .field-marketing_preference_phone {
            label {
                display: flex;

                input {
                    order: 2;
                }
                span {
                    order: 1;
                }
            }
        }

        .field-marketing_preference_post {
            label {
                display: flex;

                input {
                    order: 2;
                }
                span {
                    order: 1;
                }
            }
        }
    }
}
