.breadcrumbs {
    .items {
        list-style: none;
        display: flex;
        margin: 0;
    }
    .item {
        position: relative;
        margin-right: $half-spacing;
        @include font-size(12px);

        @include media-query(min, $palm-start) {
            @include font-size(14px);
        }

        a {
            display: inline-block;
            padding: $half-spacing 0;
            @include font-size(12px);
            text-decoration: none;
            color: $charcoal;

            @include media-query(min, $palm-start) {
                @include font-size(14px);
            }

            @include media-query(min, $lap-start) {
                padding: $base-spacing 0;
            }

            @include hover {
                text-decoration: none;
                color: $charcoal;
            }
        }

        strong {
            display: inline-block;
            padding: $half-spacing 0;
            @include font-size(12px);
            font-weight: 700;
            color: $charcoal;

            @include media-query(min, $palm-start) {
                @include font-size(14px);
            }

            @include media-query(min, $lap-start) {
                padding: $base-spacing 0;
            }
        }

        &:before {
            content: '/';
            display: inline-block;
            margin-right: $half-spacing;
            //position: absolute;
            //top: 50%;
            //transform: translateY(-50%);
        }
    }
    .home {
        &:before {
            display: none;
        }
    }
}
