.site-header {
    border-color: $border-color;
    padding: ($base-spacing / 1.5) 0 0;
    text-align: center;
    z-index: 4;
    position: relative;
    .site-search {
        @include media-query (min, $lap-start) {
            .control--combine {
                position: relative;
                width: 90%;
                max-width: 500px;
            }
            .input-text {
                border: none;
                border-bottom: 1px solid $header-font-color;
                color: $header-font-color;
            }
            .action.search {
                position: absolute;
                right: 0;
                border: none;
                padding-right: 10px;
                span {
                    display: none;
                }
            }
            .action.search:before {
                content: $icon-search;
                font-size: 20px;
                line-height: 22px;
                color: $header-font-color;
                display: block;
                font-family: $icomoon-font-family;
            }
        }
        .control--combine,
        input,
        .action.search {
            background-color: transparent;
        }
    }
    .site-top__links a:before,
    .site-controls__button--account a:before,
    .action.minicart-button:before {
        font-size: 24px;
        line-height: 22px;
        display: block;
        font-family: $icomoon-font-family;
        font-weight: normal;
        color: $header-font-color;
    }
    .site-controls__button--minicart {
        border: none;
        order: 1;
    }
    .minicart-button {
        padding: 0;
        &:before {
            content: $icon-minicart;
        }
        .minicart-button__text,
        .svg-icon {
            display: none;
        }
        span {
            color: black;
            font-weight: 500;
        }
        .counter.qty {
            right: -7px;
            top: -5px;
            background-color: transparent;
        }
    }
    .site-top__links,
    .site-controls__button--account {
        a:before {
            content: $icon-account;
        }
        .svg-icon {
            display: none;
        }
    }
    .site-top__links {
        order: 1;
        margin-right: 20px;
        a {
            padding: 0;
            border: none;
            margin-right: 10px;
            color: $header-font-color;
        }
        .customer-name a:before {
            display: none;
        }
    }
}
.site-header,
div.site-header__controls {
    background-color: $header-background-color;
}
.site-header__logo {
    @include media-query (min, $lap-start) {
        margin-right: 13%;
        margin-left: -10px;
    }

    .svg-icon {
        width: 180px;
        height: 60px;

        @include media-query(min, $desk-start) {
            height: 31px;
            width: 230px;
        }
    }

    img {
        width: 100%;
        @include media-query (max, $lap-start) {
            max-width: 160px;
        }
        @include media-query (min, $lap-start) {
            max-width: 170px;
        }
    }
}

.site-search__field button.search {
    background-color: $header-font-color;
    color: $charcoal;
    border-color: $silver-lt;
    @include hover() {
        border-color: $secondary;
        outline: none;
    }
}

div.pro-club a {
    &.visible {
        display: flex;
        align-items: center;
    }
    &:not(.visible) {
        display: none;
    }
    grid-gap: 5px;
}

@include media-query (min, $lap-start) {
    .site-header {
        padding: $base-and-half-spacing 0;
        border-bottom: 1px solid $border-color;
    }
    .site-header__container {
        display: flex;
        align-items: center;
    }
    .site-header__controls {
        flex-grow: 1;
    }
    .site-nav {
        background-color: $header-background-color;
        .site-nav__container {
            padding: 0;
        }
        .site-nav__product-az-link,
        .level0 > .level-top {
            color: $header-font-color;
            font-weight: bold;
        }
        .pro-club-mobile {
            display: none;
        }
    }
    .pro-club-desktop {
        order: 1;
        margin-right: 20px;
        a {
            font-size: 14px;
            color: $black;
            font-weight: 500;
        }
        a:not(.non-pro) {
            margin-top: 12px;
            div {
                line-height: 16px;
            }
            img {
                margin-bottom: 10px;
            }
            p {
                margin-bottom: 0;
                font-size: 10px;
                color: $silver;
            }
        }
    }
}

@include media-query (max, $lap-start) {
    .site-header {
        padding-top: 0;
    }
    .site-header__container {
        display: flex;
        justify-content: space-between;
        padding: 0;
        .site-controls__button--menu {
            margin-left: 17px;
            margin-right: 50px;
        }
        .site-controls__button--search {
            position: absolute;
            left: 60px;
            border-left: none;
        }
        .site-header__logo,
        .site-controls__button--menu {
            z-index: 2;
        }
        .site-header__logo img {
            max-width: 120px;
            margin-top: 14px;
        }
        .site-controls__button,
        .site-header__controls {
            width: unset;
        }
        .site-header__controls {
            justify-content: end;
            border-top: none;
            z-index: 2;
        }
        .site-controls__button--account {
            margin-left: 10px;
            min-width: 22px;
            margin-top: 1px;
        }
        .site-controls__button--minicart {
            margin: 0 20px;
        }
        .action.minicart-button {
            margin-top: 16px;
        }
        .site-controls__trigger .svg-icon {
            color: $header-font-color;
        }
        .site-controls__trigger > span:not(.counter.qty) {
            display: none;
        }
        .site-search,
        .minicart-wrapper {
            top: 52px;
        }
        .minicart-button__counter {
            position: absolute;
            &:before, &:after {
                display: none;
            }
        }
        .pro-club-desktop {
            display: none;
        }
    }
    nav.site-nav {
        top: unset;
    }
    nav.site-nav,
    .site-header .minicart-wrapper,
    .site-search {
        height: calc(100% - 52px);
    }
    div.site-header__controls {
        position: unset;
    }
    .usps-container {
        min-height: 39px;
        a.usp {
            padding: 6px 0;
        }
    }
    .sticky {
        .site-header, .usps-container {
            position: fixed;
            width: 100%;
        }
        .site-header {
            top: 0;
        }
        .usps-container {
            z-index: 3;
        }
        .usps-container,
        nav.site-nav {
            top: 52px;
        }
    }
    .site-nav__list {
        display: flex;
        flex-direction: column;
        .custom_url-item {
            order: 1;
        }
        .pro-club-mobile a {
            align-items: baseline;
            &:before {
                display: block;
                content: '';
                margin-right: 5px;
                height: 11px;
                width: 14px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11' fill='none'%3E%3Cpath fill='%23CCAB82' d='m0 .407 4.965 5.704L6.875 0l1.91 6.111L13.75.407V11H0V.407Z'/%3E%3C/svg%3E");
            }
        }
    }
}
@include media-query (min, $lap-start) {
    .usps-container {
        min-height: 46px;
    }
}
