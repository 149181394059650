.braintree-card-control {
    position: relative;

    .braintree-credit-card-selected {
        position: absolute;
        top: 0;
        right: 10px;
        width: 100%;
        max-width: 45px;
    }
}

.credit-card-types {
    display: none;
    //display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding-top: $half-spacing;

    .item {
        width: 40px;
        margin-bottom: $half-spacing;
        margin-right: $half-spacing;

        &:first-child {
            display: none;
        }
    }
}

.hosted-control {
    display: block;
    padding: 0 $base-spacing;
    width: 100%;
    line-height: 1;
    background-color: $white;
    border: 1px solid $silver-lt;
    font-weight: 100;
    color: $base-color;
    @include font-size($small-font-size);
    height: $button-height;
    border-radius: $base-radius;

    &:focus {
        outline: none;
        border-color: $primary;
    }
}

.control._with-tooltip {
    .field-tooltip{
        display: none;
        & _active {
            display: none;
        }
    }
}

form#co-transparent-form-braintree legend.legend {
    display: none;
}

form#co-transparent-form-braintree {
    br {
        display: none;
    }

    .field-tooltip.toggle {
        padding-top: 4px;
        padding-left: 4px;
    }

    .action-vault {
        width: 17px;
        height: 15px;
        line-height: 16px;
        background-color: #e3fc46;
        color: #333;
    }
}

@include media-query('max', $lap-start) {
    form#co-transparent-form-braintree .field-tooltip {
        position: unset;
    }

    form#co-transparent-form-braintree .field {
        position: relative;
    }

    form#co-transparent-form-braintree .field-tooltip-content {
        left: 0;
    }
}

.braintree-hosted-fields-focused {
    outline: none;
    border-color: $primary;
}

.hosted-error {
    display: none;
    margin-top: $half-spacing;
    color: #e02b27;
    @include font-size(16px);
}

.braintree-hosted-fields-invalid + .hosted-error {
    display: block;
}

.hosted-date-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .hosted-control {
        width: calc(50% - #{$half-spacing});
    }

    .hosted-error {
        width: 100%;
    }
}

#braintree-three-d-modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100vw;
    z-index: 100000;
    visibility: visible;
}

#braintree-three-d-modal .bt-modal-frame {
    height: 420px;
    width: 440px;
    margin: auto;
    background-color: #ffffff;
    z-index: 2;
    border-radius: 6px;
}

#braintree-three-d-modal .bt-modal-body {
    height: 380px;
    margin: 20px;
    background-color: white;
    border: 1px solid #d3d3d3;
}

#braintree-three-d-modal .bt-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.75;
}

@media (max-width: 650px) {
    #braintree-three-d-modal .bt-modal-frame {
        width: 100%;
    }
}

// GOOGLE PAY BUTTON

button.braintree-googlepay-button {
    background-origin: content-box;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 0;
    border-radius: 4px;
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 1px 0, rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
    cursor: pointer;
    height: 40px;
    min-height: 40px;
    padding: 11px 24px;
    display: block;
    margin: 0 auto;
}

button.braintree-googlepay-button.black {
    background-color: #000;
    box-shadow: none;
    padding: 12px 24px 10px;
}

button.braintree-googlepay-button.white {
    background-color: #FFFFFF;
}

button.braintree-googlepay-button.short {
    min-width: 90px;
    width: 100%;
}

button.braintree-googlepay-button.black.short {
    background-image: url(https://www.gstatic.com/instantbuy/svg/dark_gpay.svg);
}

button.braintree-googlepay-button.white.short {
    background-image: url(https://www.gstatic.com/instantbuy/svg/light_gpay.svg);
}

button.braintree-googlepay-button.black:active {
    background-color: #5f6368;
}

button.braintree-googlepay-button.black:hover {
    background-color: #3c4043;
}

button.braintree-googlepay-button.white:active {
    background-color: #FFFFFF;
}

button.braintree-googlepay-button.white:focus {
    box-shadow: #e8e8e8 0 1px 1px 0, #e8e8e8 0 1px 3px;
}

button.braintree-googlepay-button.white:hover {
    background-color: #f8f8f8;
}

button.braintree-googlepay-button.long {
    min-width: 152px;
    width:  100%;
}

button.braintree-googlepay-button.white.long {
    background-image: url(https://www.gstatic.com/instantbuy/svg/light/en.svg);
}

button.braintree-googlepay-button.black.long {
    background-image: url(https://www.gstatic.com/instantbuy/svg/dark/en.svg);
}

// Custom classes to disable the buttons.
button.googlepay-button-disabled,
div.applepay-button-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.googlepay-minicart-logo {
    margin-top: 15px;
    text-align: center;
}


// APPLE PAY BUTTON
.braintree-applepay-container-product {
    display: inline-block;

    .braintree-apple-pay-button {
        padding: 10px 0;
        margin: 0;
        margin-bottom: 15px;
    }
}

.braintree-apple-pay-button {
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: plain;
    display: block;
    width: 100%;
    min-height: 45px;
    border: 1px solid black;
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-size: 100% - 'calc(60% + 2px)';
    background-repeat: no-repeat;
    background-color: black;
    background-position: 50% 50%;
    border-radius: 5px;
    padding: 18px;
    margin: 20px 0;
    transition: background-color .15s;
    cursor: pointer;
}


// Temporarily hide buttons from minicart & basket page
.minicart-wrapper, .checkout-cart-index .cart-summary {
    .braintree-applepay-minicart, .googlepay-minicart-logo {
        display: none !important;
    }
}
