.minicart-wrapper {
    position: fixed;
    display: flex;
    background-color: $white;
    z-index: 4;

    > div {
        width: 100%;
    }

    @include media-query(max, $lap-start) {
        top: $topbar-height;
        left: 0;
        width: 100%;
        height: calc(100% - #{$control-height + $topbar-height});
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        transition: opacity ease 0.3s;
        padding-bottom: $base-spacing;

        &.active {
            visibility: visible;
            opacity: 1;
        }
    }
    @include media-query(min, $lap-start) {
        top: 0;
        right: 0;
        height: 100%;
        width: 340px;
        transform: translateX(100%);
        z-index: 1043;
        overflow: auto;
        transition: transform ease 0.3s;

        &.active {
            transform: translateX(0);
        }
    }

    .block-minicart {
        position: relative;
    }

    .block-title {
        color: $charcoal;
        padding: ($base-spacing - 5px) 0;
        border-bottom: 1px solid $silver-lt;
    }

    .block-content {
        display: flex;
        flex-direction: column;
    }

    .action.close {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background: unset;
        border: none;
        width: 58px;
        height: 58px;
        padding: 0;
        background: url('../img/icon_cross_black.svg') no-repeat center;
        background-size: 12px;
        opacity: 0.8;

        @include hover {
            opacity: 1;
        }

        span {
            display: none;
        }
    }

    .items-total {
        display: none;
    }

    .subtotal {
        display: flex;
        align-items: baseline;
        justify-content: center;
        order: 2;
        margin-bottom: ($base-spacing - 5px);

        .label {
            @include font-size(15px);
            font-weight: 100;
            margin: 0;
            color: $grey;
        }
        .amount {
            margin-left: $half-spacing;
            @include font-size(20px);
            font-weight: 700;
            color: $charcoal;
        }
    }

    .actions {
        padding: 0 $base-spacing;
        order: 3;

        .action.checkout {
            width: 100%;
            margin-bottom: $base-spacing;
        }
    }

    .minicart-error {
        order: 2;
    }

    .subtitle {
        padding: $base-spacing $base-spacing 0;
    }

    .minicart-items-wrapper {
        order: 1;
        height: auto !important;
    }

    .minicart-items {
        display: block;
        margin: 0;
    }

    .product-item {
        position: relative;
        padding: ($base-spacing - 5px) $double-spacing ($base-spacing - 5px)
            $base-spacing;
        border-bottom: 1px solid $silver-lt;

        &:last-child {
            margin-bottom: ($base-spacing - 5px);
        }
    }

    .product {
        display: flex;
        align-items: flex-start;
        width: 100%;
    }

    .product-item-photo {
        display: block;
        flex-shrink: 0;
        background-color: $lightgrey;
        @include font-size(8px);
        max-width: 78px;
    }

    .product-item-details {
        padding-left: $base-spacing;
        flex-grow: 1;
        text-align: left;
    }

    .product-item-name {
        > * {
            display: block;
            margin-bottom: ($half-spacing / 2);
            line-height: 1.2;
            text-decoration: none;
            @include font-size(13px);
            font-weight: 700;
            color: $charcoal;
            padding-right: $base-spacing;
        }
    }

    .product.options {
        display: block;
        @include font-size($micro-font-size);
        font-weight: 700;
        color: $charcoal;
        margin-bottom: $half-spacing;

        .toggle {
            display: block;
            width: 100%;
            cursor: pointer;

            &:after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                background: url('#{$image-path}chevron-down.svg') no-repeat
                    center;
                background-size: contain;
                margin-left: $half-spacing;
            }
        }

        .content {
            .subtitle {
                display: none;
            }
            .product.options.list {
                .label {
                    display: inline-block;
                    @include font-size($micro-font-size);
                    &:after {
                        content: ':';
                    }
                }
                .values {
                    display: inline-block;
                    font-weight: 100;
                    margin-right: $half-spacing;
                    @include font-size($micro-font-size);
                }
            }
        }
    }

    .product-messages {
        p {
            @include font-size($micro-font-size);
            padding: 0;
            margin: 0;
            color: $red;
        }
    }

    .product-item-pricing {
        display: flex;
        align-items: flex-start;

        .price-container {
            flex-grow: 1;
            @include font-size(13px);
            margin-right: $base-spacing;
            padding-right: $base-spacing;
        }
    }

    .details-qty {
        position: relative;
        flex-grow: 0;
        flex-shrink: 1;
        display: grid;
        grid-template-columns: 25px 60px;
        grid-gap: 5px;
        align-items: flex-start;

        &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: 21px;
            background-color: $silver-lt;
            top: 3px;
            left: -30px;
        }

        .label {
            position: relative;
            top: 4px;
            font-weight: 100;
            margin-bottom: 0;
            @include font-size(13px);
        }

        .cart-item-qty {
            position: relative;
            width: 100%;
            height: 27px;
            padding: 0;
            @include font-size(13px);
            font-weight: 700;
            color: $charcoal;
            text-align: center;
        }

        .update-cart-item {
            grid-column-start: 1;
            grid-column-end: 3;
            padding: ($half-spacing / 2);
            @include font-size($micro-font-size);
        }
    }

    .product.actions {
        .action.edit {
            display: none;
        }

        .action.delete {
            display: block;
            position: absolute;
            top: 13px;
            right: $base-spacing;
            width: $base-spacing - 2px;
            height: $base-spacing - 2px;
            border-radius: 100%;
            background: url('../img/icon_cross_white.svg') no-repeat center
                $charcoal;
            background-size: 7px;

            span {
                display: none;
            }
        }
    }

    .paypal {
        input {
            border: none;
            width: auto;
            display: inline-block;
        }
    }
}
.minicart-content-wrapper {
    margin-bottom: $base_spacing + 5px;

    .block-title {
        .qty {
            display: none;
        }
    }

    .action:disabled {
        background-color: $white;
        border: 1px solid $black;
        cursor: not-allowed;
        margin-bottom: 0;
    }
}
.minicart-wrapper__bottom {
    ul {
        list-style: none;
        padding: 0 ($base-spacing + 5px) 0 ($double-spacing + 25px);
    }
    li {
        @include font-size(13px);
        font-weight: 300;
        text-align: left;
        position: relative;
        margin-bottom: $base_spacing + 5px;

        &:before {
            content: '';
            position: absolute;
            left: -30px;
            top: 5px;
            width: 15px;
            height: 15px;
            background: url(../img/icon_tick_red.svg) center no-repeat;
        }
    }
}

.minicart-wrapper__bottom-image {
    margin-bottom: $base_spacing + 5px;

    img {
        display: block;
        margin: 0 auto;
    }
}

.minicart-wrapper .delivery-timer {
    margin-bottom: $base-spacing;
    padding: 0 0 $base-spacing 0;
    background-color: unset;
    border-bottom: 1px solid $silver-lt;

    .delivery-timer__heading {
        display: block;

        .svg-icon {
            width: 1.2em;
            height: 1.2em;
        }
    }
    .delivery-timer__text {
        text-align: center;
        @include font-size(12px);
        line-height: 1.8;
        @include font-size($base-font-size);
    }
}
