.filter-current {
    margin-left: -$base-spacing;
    margin-right: -$base-spacing;
    border-top: solid 1px $silver-lt;

    @include media-query(min, $lap-start) {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: $base-spacing;
        border-top: none;
        position: relative;
    }

    .items {
        display: none;
        margin-bottom: 0;

        @include media-query(min, $lap-start) {
            display: block;
        }
    }

    &.active {
        .items {
            display: block;
        }

        .filter-actions {
            display: block;
        }

        .filter-current-subtitle:before {
            background: url('#{$svg-path}icon_up.svg') no-repeat center;
            background-size: ($base-spacing - 5px);

            @include media-query(min, $lap-start) {
                display: none;
            }
        }
    }

    .item {
        position: relative;
        display: block;
        list-style: none;
        border: solid 1px $silver-lt;
        padding: ($half-spacing / 2) $base-spacing;
        margin: 0 $base-spacing;

        &:nth-child(even) {
            border-top: none;
        }

        @include media-query(min, $lap-start) {
            width: 100%;
            margin: 0;
        }
    }

    .filter-label {
        position: relative;
        @include font-size(14px);
        font-weight: 700;
        color: $charcoal;
    }

    .filter-value {
        @include font-size(14px);
        position: relative;
        padding-left: 2px;

        &:before {
            content: ':';
            position: absolute;
            left: -5px;
            top: -3px;
        }
    }

    .action.remove {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: $base-spacing;
        z-index: 1;

        &:before {
            content: '';
            display: block;
            width: $half-spacing;
            height: $half-spacing;
            background: url('#{$svg-path}icon_cross.svg') no-repeat center;
            background-size: contain;
        }

        span {
            @include visuallyhidden;
        }
    }
}

.filter-current-subtitle {
    display: block;
    position: relative;
    width: 100%;
    padding: $half-spacing $base-spacing;
    @include font-size(14px);
    font-weight: 700;
    color: $charcoal;

    @include media-query(max, $lap-start) {
        cursor: pointer;
    }

    @include hover {
        outline: none;
    }

    &:after {
        content: ' (' attr(data-count) ')';
    }

    &:before {
        content: '';
        position: absolute;
        right: $base-spacing;
        top: 50%;
        transform: translateY(-50%);
        background: url('#{$svg-path}icon_down.svg') no-repeat center;
        background-size: ($base-spacing - 5px);
        width: ($base-spacing - 5px);
        height: ($base-spacing - 5px);

        @include media-query(min, $lap-start) {
            display: none;
        }
    }

    @include media-query(min, $lap-start) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }
}

.filter-actions {
    display: none;
    width: 100%;
    padding: $half-spacing $base-spacing;
    cursor: pointer;

    @include media-query(min, $lap-start) {
        display: block;
        padding: 0;
    }
}

.filter-clear {
    @include font-size(14px);
    color: $secondary-accent;
    text-decoration: none;

    @include hover {
        text-decoration: underline;
        color: $secondary-accent;
    }

    span {
        //background: url("#{$svg-path}icon_cross.svg") no-repeat right $half-spacing center $white;
        //background-size: $half-spacing;
        //padding-right: $double-spacing + $half-spacing;
    }

    @include media-query(min, $lap-start) {
        position: absolute;
        top: 0;
        right: 0;
    }
}
