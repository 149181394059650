.brands {
    padding: $half-spacing 0;
    border-bottom: 1px solid $silver-lt;
    
    @include media-query (min, $lap-start) {
        padding: ($base-spacing * 1.5) 0;
    }
}

.brands__container {
}

.brands__list {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    
    @include media-query (min, $lap-start) {
        justify-content: space-between;
    }
}

.brands__item {
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: $base-spacing;
    
    @include media-query (min, $lap-start) {
        flex-shrink: 1;
        flex-grow: 0;
    }
    
    @include media-query (min, $desk-start) {
        margin-right: 0;
    }
    
    &:last-child {
        margin-right: 0;
    }
}

.brands__img {
    display: block;
    width: 100%;
    max-width: 50px;
    
    @include media-query (min, $lap-start) {
        max-width: 90px;
    }
    
    @include media-query (min, $desk-start) {
        width: 90px;
    }
}
