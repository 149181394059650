.embellishments-block {
    display: block;
    width: 100%;
    padding: $half-spacing;
    border: 1px solid $silver-lt;
}

.embellishments-block span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
