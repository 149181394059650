.validator {
    height: auto !important;
    width: auto !important;
    flex-grow: 1;
}

.validator.validator-phone .feedback,
.validator.validator-email .feedback {
    height: ($base-spacing + $half-spacing) !important;
    width: ($base-spacing + $half-spacing) !important;
    background-size: 50% !important;
    background-position: center !important;
    top: 23px !important;
    right: $half-spacing !important;
    transform: translateY(-50%) !important;
    z-index: 1;
}

.validator.loading-fallback .feedback {
    height: ($base-spacing + $half-spacing) !important;
    width: ($base-spacing + $half-spacing) !important;
    background-size: $base-spacing !important;
    background-position: center !important;
    top: 23px !important;
    right: $half-spacing !important;
    transform: translateY(-50%) !important;
    z-index: 1;
}

.validator.loading .feedback {
    top: 9px !important;
    height: ($base-spacing + $half-spacing) !important;
    width: ($base-spacing + $half-spacing) !important;
    right: $base-spacing !important;
    z-index: 1;
}
