//Account dashboard
.account {
    .page-title-wrapper {
        @include container;
        position: relative;
        text-align: center;
        padding: $base-spacing 0 0;

        @include media-query(min, $lap-start) {
            padding: ($base-spacing * 2) 0 $base-spacing;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .order-date {
            * {
                display: inline;
                @include font-size($base-font-size);
            }
        }

        .actions-toolbar {
            .action + .action {
                margin-left: $half-spacing;
            }
        }
    }

    .page-title {
        @include font-size(22px);
        padding-top: 0;

        @include media-query(min, $lap-start) {
            @include font-size(30px);
        }
    }

    .columns {
        @include media-query(max, $desk-start) {
            display: flex;
            flex-direction: column;

            .sidebar {
                order: -1;
            }
        }

        .column.main {
            grid-area: content;
            grid-row-start: unset;
            grid-row-end: unset;
            @include media-query(min, $desk-start) {
                padding-top: $base-spacing * 2;
                padding-left: $base-spacing;
            }
            @include media-query(max, $desk-start) {
                width: 100%;
            }
        }

        .block {
            margin-bottom: ($base-spacing * 2);

            .block-title {
                display: flex;
                align-items: baseline;
                margin-bottom: ($base-spacing + $half-spacing);
                border-bottom: solid 1px $silver-lt;

                strong {
                    display: block;
                    color: $charcoal;
                    @include font-size(20px);
                    padding-bottom: $base-spacing/2;
                }

                a {
                    @include font-size(14px);
                    color: $grey;
                    margin-left: auto;
                }
            }

            .block-content {
                @include media-query(min, $lap-start) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: ($base-spacing * 2);
                }
            }

            .box {
                position: relative;
                margin-bottom: $base-spacing;

                @include media-query(min, $lap-start) {
                    margin-bottom: 0;
                }

                .box-title {
                    display: block;
                    margin-bottom: $half-spacing;
                    border-bottom: solid 1px $silver-lt;
                    @include font-size(16px);

                    span {
                        display: block;
                        padding-bottom: $half-spacing;
                    }
                }

                .box-content {
                    max-width: 70%;
                    @include media-query(min, $lap-start) {
                        max-width: 75%;
                    }
                }

                .box-actions {
                    .change-password {
                        @include button;
                        line-height: 1;
                        padding: $half-spacing;
                        background-color: $silver-lt;
                        border-color: $silver-lt;
                        color: $charcoal;

                        @include hover {
                            color: $charcoal;
                            border-color: darken($silver-lt, 5%);
                            background-color: darken($silver-lt, 5%);
                        }
                    }

                    .action.edit {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        @include font-size(14px);
                        color: $grey;
                    }
                }
            }

            &.block-dashboard-orders,
            &.block-addresses-list {
                .block-content {
                    display: block;
                }
            }
        }

        > form {
            legend,
            .legend {
                display: flex;
                align-items: baseline;
                margin-bottom: ($base-spacing + $half-spacing);
                padding: 0;
                border-bottom: solid 1px $silver-lt;

                span {
                    display: block;
                    color: $charcoal;
                    @include font-size(20px);
                    padding-bottom: $base-spacing/2;
                }

                a {
                    @include font-size(14px);
                    color: $grey;
                    margin-left: auto;
                }
            }

            fieldset {
                margin-bottom: ($base-spacing * 2);
            }

            br {
                display: none;
            }
        }

        .actions-toolbar {
            margin-top: $half-spacing;

            > .primary {
                @include media-query(min, $lap-start) {
                    display: inline-block;
                }
            }

            > .secondary {
                @include media-query(min, $lap-start) {
                    display: inline-block;
                    margin-left: $half-spacing;
                }
            }

            .action {
                margin-bottom: $base-spacing;
                @include media-query(min, $lap-start) {
                    display: inline-block;
                }
            }

            .action + .action {
                @include media-query(min, $lap-start) {
                    margin-left: $half-spacing;
                }
            }
        }
    }
}
