.product-items {
    list-style: none;
    margin-bottom: 0;
    &:not(.slick-slider):not(.flickity-enabled) {
        display: grid;
        @include media-query(min, $wide-start) {
            grid-gap: 40px;
            .column.main & {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
            .additional-product-grids & {
                grid-template-columns: repeat(5, minmax(0, 1fr));
            }
            .injectable-banner {
                grid-column: span 4
            }
        }
        @include media-query(max, $wide-start) {
            .additional-product-grids & .product-item:nth-child(n+5) {
                display: none;
            }
            @media (min-width: $palm-start) {
                grid-gap: 40px 20px;
                .column.main & {
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                }
                .additional-product-grids & {
                    grid-template-columns: repeat(4, minmax(0, 1fr));
                }
                .injectable-banner {
                    grid-column: span 3
                }
            }
        }
        @include media-query(max, $palm-start) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            .injectable-banner {
                grid-column: span 2;
            }
        }
    }
    &.slick-slider,
    &.flickity-enabled {
        margin-left: -20px;
        @include media-query(min, $desk-start) {
            margin-left: -40px;
        }
        .product-item {
            padding-left: $base-spacing;
            @include media-query(min, $desk-start) {
                padding-left: ($base-spacing * 2);
            }
        }
    }
    .injectable-banner {
        grid-row: 3
    }
    .product-item {
        text-align: center;
        display: flex;
        .offer-heading h3 {
            font-size: inherit;
            font-weight: inherit;
            display: inline;
            color: inherit;
        }
    }
    .product-item-info {
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    .product-item-photo {
        display: block;
        position: relative;
        width: 100%;
        background-color: $white;
        flex-grow: 0;
        &:after {
            display: block;
            content: '';
            padding-bottom: 125%;
        }
    }
    .product-image-photo,
    .photo.image {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
    }
    .product-item-details {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-top: $half-spacing;
        text-align: center;
        .price-box {
            flex-grow: 1;
        }
    }
    .product-offer-heading {
        flex-grow: 0;
        display: block;
        min-height: 22px;
        p {
            margin-bottom: ($half-spacing / 2);
        }
    }
    .product-item-name {
        padding-top: 0;
        display: block;
        margin-bottom: ($half-spacing - 3px);
        line-height: 1.3;
        color: $charcoal;
        font-weight: 700;
        height: 38px;
        overflow: hidden;
        @include font-size(15px);
        flex-grow: 0;
    }
    .product-item-name a,
    .product-item-link {
        display: block;
        color: $charcoal;
        font-weight: 700;
        text-decoration: none;
        height: 38px;
        overflow: hidden;
        @include font-size(15px);
        @include hover {
            text-decoration: none;
        }
    }
    .product-item-inner-info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        margin-bottom: ($half-spacing / 5);
        .stock {
            flex-grow: 0;
            flex-shrink: 0;
            > span {
                @include font-size(11px);
                &:before {
                    width: $half-spacing;
                    height: $half-spacing;
                    margin-right: ($half-spacing/2);
                    top: 2px;
                }
            }
        }
    }
    .product-item-inner {
        padding-top: ($half-spacing - 3px);
        position: relative;
        margin-top: auto;
        #qty-error {
            position: absolute;
            top: 30px;
            white-space: nowrap;
        }
    }
    .product-item-actions {
        width: 100%;
        @include font-size($micro-font-size);
        color: $charcoal;
        font-weight: 700;
        text-transform: capitalize;
        form {
            display: flex;
            justify-content: center;
            .field.qty {
                margin: 0;
            }
            button {
                line-height: 1.4;
                padding: $half-spacing;
                @include font-size(10px);
                @include media-query(min, $palm-start) {
                    line-height: 1;
                    @include font-size($small-font-size);
                }
            }
            .tocart {
                background-color: transparent;
                border-color: $light-grey;
                border-left: none;
            }
            .tocart:hover, .tocart:focus {
                background-color: $button-primary__hover__background;
            }
        }
    }
    .product-item-description {
        display: none;
    }
}
.category-cms .product-items {
    max-width: unset !important;
    justify-content: center;
    grid-gap: 20px;
    @include media-query(min, $lap-start) {
        margin: 0 20px !important;
    }
    @include media-query(max, $lap-start) {
        margin: 0 0 !important;
    }
    .product-item {
        min-width: 0;
        flex: 1 0 0;
        padding: 0;
        margin-bottom: 30px;
        @include media-query(min, $lap-start) {
            max-width: 92px;
        }
        .photo.image {
            position: unset !important;
            border: 1px solid $border-color;
            border-radius: 100%;
        }
        .product.photo {
            padding-bottom: 0 !important;
            span {
                display: block;
                margin-top: 10px;
                font-family: $base-font-family;
                font-size: $small-font-size !important;
                font-weight: 600 !important;
                line-height: 20px;
                position: unset !important;
                transform: unset !important;
            }
        }
    }
    @include media-query(max, $lap-start) {
        .slick-slide {
            padding: 0 10px;
        }
    }
    .slick-dots {
        display: flex;
        padding: 0;
        li {
            flex: 1 0 auto;
            margin: 0;
            button {
                width: 100%;
                height: 6px;
                border: none;
                border-radius: 4px;
            }
            &.slick-active button {
                background: $silver;
            }
        }
    }
}
.products-grid {
    padding-bottom: $base-spacing;
    @include media-query(min, $lap-start) {
        padding-bottom: $double-spacing;
    }
}
.products-list {
    padding-bottom: $base-spacing;
    @include media-query(min, $lap-start) {
        padding-bottom: $double-spacing;
        .product-items {
            grid-template-columns: 1fr;
            grid-row-gap: $double-spacing;
        }
        .product-item {
            width: 100%;
            margin-right: 0;
            display: block;
        }
        .product-item-info {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: row;
        }
        .product-item-photo {
            width: 35%;
        }
        .product-item-details {
            width: 65%;
            text-align: left;
            padding-left: $double-spacing;
            padding-top: 0;
            .price-box {
                display: flex;
                align-items: baseline;
                flex-wrap: wrap;
                .special-price {
                    margin-right: $half-spacing;
                    margin-bottom: $half-spacing;
                }
                .old-price {
                    margin-bottom: $half-spacing;
                }
            }
        }
        .product-offer-heading {
            min-height: auto;
        }
        .product-item-name {
            height: auto;
            margin-bottom: $base-spacing;
        }
        .product-item-name a,
        .product-item-link {
            height: auto;
        }
        .product-item-inner-info {
            align-items: flex-start;
            justify-content: flex-start;
        }
        .product-item-inner {
        }
        .product-item-actions {
            display: flex;
            align-items: center;
            .stock.available {
                margin-bottom: 0;
                margin-right: $base-spacing;
            }
        }
        .product-item-actions {
            margin-left: 0;
        }
        .product-item-description {
            display: block;
            padding-top: $base-spacing;
            > * {
                @include font-size(16px);
            }
            p {
                margin-bottom: $half-spacing;
            }
            ul {
            }
            .action.more {
                @include font-size($small-font-size);
            }
        }
    }
}
.product-carousel {
    display: block;
    margin-left: 0;
    &:not(.flickity-enabled) {
        overflow: hidden;
        white-space: nowrap;
    }
    .product-item {
        display: inline-block;
        padding-left: $base-spacing;
        padding-right: $base-spacing;
        margin-bottom: $base-spacing;
        @include media-query(min, $desk-start) {
            padding-left: $double-spacing;
            padding-right: $double-spacing;
        }
    }
}
.block-new-products,
.block-products-list {
    position: relative;
    padding-bottom: ($base-spacing * 3.5);
    border-bottom: 1px solid $silver-lt;
    @include media-query(min, $lap-start) {
        padding-bottom: ($base-spacing * 5);
    }
    .block-title {
        text-align: center;
        padding: ($base-spacing * 1.5) 0 $base-spacing;
        @include media-query(min, $lap-start) {
            padding: ($base-spacing * 3) 0 ($base-spacing * 2);
            @include font-size($h4-font-size);
        }
    }
    .block-content {
        .products-grid {
            padding: 0;
        }
    }
}
