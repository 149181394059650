.checkout-shipping-method {
    .table-checkout-shipping-method {
        display: block;
        margin-bottom: $base-spacing;
        
        thead {
            display: none;
        }
        
        tbody,
        td, {
            display: block;
        }
        
        tr {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: ($half-spacing + 4px);
            padding-bottom: $half-spacing;
            border-bottom: 1px solid $silver-lt;
            
            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }

        td {
            @include font-size(14px);
            color: $charcoal;
            padding: 0;
            line-height: 1.5;
            
            &:first-child {
                margin-right: $half-spacing;
                @include media-query (min, $desk-start) {
                    margin-right: $base-spacing;
                }
            }
            
            &:nth-child(3) {
                margin-right: auto;
            }
        }


        .col-method {
            input {
                margin-right: 0;
            }
        }
    
        .col.col-price {
            margin-right: $half-spacing;
            font-weight: 700;
            @include media-query (min, $desk-start) {
                margin-right: $base-spacing;
            }
        }
        
        .col.col-carrier {
            padding-left: ($base-spacing * 1.5);
            @include media-query (min, $desk-start) {
                padding-left: ($base-spacing * 2);
            }
        }

        .col-description {
            padding-top: $half-spacing;
            width: 100%;
            text-align: left;
        }
        .shq-method-carrier-logo {
            display: none;
        }
    }
}
