.loading-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    background-color: rgba($white, 0.5);

    .loader {
        margin: auto;
        p {
            display: none;
        }
    }
}

._block-content-loading {
    position: relative;
}