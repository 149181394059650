.catalog-product-view {
    .action-skip-wrapper {
    }

    .action.skip.gallery-next-area {
        display: none;
    }

    .action.skip.gallery-prev-area {
        display: none;
    }

    .gallery-placeholder {
        &._block-content-loading {
            border: 1px solid $silver-lt;
        }
    }

    .fotorama {
    }

    .fotorama-item {
    }

    .fotorama__wrap {
    }

    .fotorama__stage {
        border: 1px solid $silver-lt;
        margin-bottom: 25px;
    }

    .fotorama__nav--thumbs {
    }

    .fotorama__fullscreen-icon {
    }

    .fotorama__arr {
    }

    .fotorama__arr--disabled {
    }

    .fotorama__thumb__arr.fotorama__thumb__arr--left.fotorama__arr--disabled {
        .fotorama__thumb--icon {
        }
    }

    .fotorama__thumb__arr.fotorama__thumb__arr--right.fotorama__arr--disabled {
        .fotorama__thumb--icon {
        }
    }

    .fotorama__caption {
        display: none;
    }

    .fotorama__nav__shaft {
        text-align: center;

        .fotorama__thumb-border {
            border: none;
        }
    }

    .fotorama__nav-wrap {
    }

    .fotorama__thumb {
    }

    .fotorama__thumb-border {
        background-image: none;
    }

    .fotorama__nav--thumbs .fotorama__nav__frame {
        padding: 0 0 0 10px !important;
        width: 65px !important;
        height: 65px !important;

        @include media-query(min, $lap-start) {
            padding: 0 0 0 40px !important;
            width: 90px !important;
            height: 90px !important;
        }
    }

    .fotorama__thumb-border {
        width: 65px !important;
        height: 65px !important;
        border-width: 2px !important;
        margin-top: 10px !important;

        @include media-query(min, $lap-start) {
            width: 90px !important;
            height: 90px !important;
            border-width: 2px !important;
            margin-top: 20px !important;
        }
    }

    .fotorama__nav__frame.fotorama__nav__frame--thumb {
        .fotorama__thumb {
            border: 1px solid $silver;
        }
    }

    .fotorama__nav__frame.fotorama__nav__frame--thumb.fotorama__active {
        .fotorama__thumb {
            border: 1px solid $charcoal;
        }
    }

    .fotorama__nav-wrap {
    }

    .fotorama__nav-wrap--horizontal {
    }

    .fotorama__nav--thumbs {
    }

    .fotorama__nav-wrap--horizontal
        .fotorama__thumb__arr--left
        .fotorama__thumb--icon {
        top: 90%;

        @include media-query(min, $lap-start) {
            top: 50%;
        }
    }

    .fotorama__nav-wrap--horizontal
        .fotorama__thumb__arr--right
        .fotorama__thumb--icon {
        top: 90%;

        @include media-query(min, $lap-start) {
            top: 50%;
        }
    }

    .fotorama-video-container:after {
        background: url('../Magento_ProductVideo/img/gallery-sprite.png') bottom right;
        bottom: 0;
        content: '';
        height: 100px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 12px;
        width: 100px
    }

    .fotorama-video-container .magnify-lens {
        display: none !important
    }

    .fotorama-video-container.video-unplayed:hover img {
        opacity: .6
    }

    .fotorama-video-container.video-unplayed:hover:after {
        transform: scale(1.25)
    }

    .video-thumb-icon:after {
        background: url('../Magento_ProductVideo/img/gallery-sprite.png') bottom left;
        bottom: 0;
        content: '';
        height: 40px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 10px;
        width: 49px
    }

    .product-video {
        bottom: 0;
        height: 75%;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%
    }

    .product-video iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 9999
    }

    .fotorama__stage__shaft:focus .fotorama__stage__frame.fotorama__active:after {
        bottom: 0;
        content: '';
        height: 100px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 12px;
        width: 100px
    }

    .fotorama__product-video--loading:after {
        visibility: hidden
    }

    @media only screen and (min-device-width: 320px) and (max-device-width:780px) and (orientation:landscape) {
        .product-video {
            height:100%;
            width: 81%
        }
    }
}
