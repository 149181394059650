.account-create__manual {
    @include hover {
        color: $black;
    }
}

.company {
    display: none;
    visibility: hidden;
}
