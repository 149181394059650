.search.results dl.block {
    display: none;
}
.product-items .product-item-photo {
    border: none;
}
.product-item-details {
    .tocart {
        background-color: transparent;
        border-color: $light-grey;
        border-left: none;
    }
    .tocart:hover, .tocart:focus {
        background-color: $button-primary__hover__background;
    }
}
