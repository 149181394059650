$mappedWeightList: (
  "light": $font-weight__light,
  "regular": $font-weight__regular,
  "heavier": $font-weight__heavier,
  "semibold": $font-weight__semibold,
  "bold": $font-weight__bold
);
@mixin fontface($name, $weight, $style: normal) {
  @font-face {
    font-family: $name;
    src: local($name),
    url("#{$font-path}/#{$name}/#{$name}-#{$weight}.woff2") format("woff2");
    font-weight: map-get($mappedWeightList, $weight);
    font-style: $style;
    font-display: swap;
  }
}
@include fontface(termina, heavier);
@include fontface(termina, bold);
@include fontface(outfit, light);
@include fontface(outfit, regular);
@include fontface(outfit, heavier);
@include fontface(outfit, semibold);
@include fontface(outfit, bold);
